import React from 'react'
import { FolderORM } from 'src/types/types'
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'
import { DNABox, DNACard, DNAText, Iffy, useTheme } from '@alucio/lux-ui'
import Folder from 'src/components/DNA/Folder/DNAFolder'
import PinButton from 'src/components/DNA/Folder/DNAFolderPinButton'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { StyleSheet } from 'react-native'

export interface DNAFolderMeetingRowProps {
  onPin?: () => void,
}

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    marginVertical: 4,
  },
})

export const DNAFolderMeetingRow: DNARowsComponent<FolderORM, DNAFolderMeetingRowProps> = (props) => {
  const {
    ORM: folderORM,
    onPin,
    onPress,
  } = props

  const folderActions = useDNAFolderActions()
  const theme = useTheme()

  const isPinned = folderORM.model.isPinned && !folderORM.relations.parentFolderORM;

  const allStyle = {
    paddingHorizontal: theme['row-padding-horizontal'],
    paddingVertical: theme['row-padding-vertical'],
  }

  return (
    <DNACard
      appearance="flat"
      style={[
        props.style,
        allStyle,
        styles.containerStyle,
      ]}
      interactive="pressable"
      onPress={() => {
        onPress?.(folderORM);
      }}
    >
      <DNABox
        testID="folder-row"
        alignY="center"
      >
        <DNABox
          fill
          alignY="center"
          style={{ marginLeft: 7 }}
        >
          <Folder folder={folderORM} />
          <DNAText
            testID="folder-item-title"
            b1
            style={{ marginLeft: 9 }}
            numberOfLines={2}
            status="basic"
          >
            {folderORM.model.name}
          </DNAText>
        </DNABox>
        <Iffy is={!folderORM.meta.isSharedWithTheUser}>
          <DNABox alignY="center" alignX="end">
            <PinButton
              onPin={() => {
                folderActions.pin(folderORM)();
                onPin?.();
              }}
              isHidden={!isPinned}
              variant="meetings"
              interactive={false}
            />
          </DNABox>
        </Iffy>
      </DNABox>
    </DNACard>
  );
}

export default DNAFolderMeetingRow
