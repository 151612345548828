import { current } from 'immer';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataStore } from '@aws-amplify/datastore';
import { EntityViewedEvent, UserSettings } from '@alucio/aws-beacon-amplify/src/models';
import { ANALYTIC_CATEGORY, ANALYTIC_CATEGORY_ENUM } from 'src/state/machines/analytics/analytics.types';
import { commonReducers, initialState } from './common';
import ActiveUser from '../../global/ActiveUser';
import * as logger from 'src/utils/logger';

const sliceName = 'userSettings';
const { reducers, extraReducers, asyncActions } = commonReducers<UserSettings>(sliceName)

enum AnalyticsFeedTypeEnum {
  documentsViewed = 'documentsViewed',
  foldersViewed = 'foldersViewed',
}

export type AnalyticsFeedType = keyof typeof AnalyticsFeedTypeEnum;

const ANALYTIC_FEED_TYPE: Record<ANALYTIC_CATEGORY, AnalyticsFeedType> = {
  [ANALYTIC_CATEGORY_ENUM.DOCUMENT]: 'documentsViewed',
  [ANALYTIC_CATEGORY_ENUM.FOLDER]: 'foldersViewed',
}

export interface UpdateAnalyticsFeedPayload {
  category: ANALYTIC_CATEGORY,
  events: EntityViewedEvent[],
}

const userSettingsSlice = createSlice({
  name: sliceName,
  initialState: initialState<UserSettings>(),
  reducers: {
    ...reducers,
    updateAnalyticsFeed: {
      prepare: (
        category: ANALYTIC_CATEGORY,
        events: EntityViewedEvent[],
      ) => {
        return {
          payload: {
            category,
            events,
          },
        }
      },
      reducer: (
        state,
        action: PayloadAction<UpdateAnalyticsFeedPayload>,
      ): void => {
        const { category, events } = action.payload;
        const user = ActiveUser.user;
        const now = new Date().toISOString();
        const analyticsFeedType = ANALYTIC_FEED_TYPE[category];

        if (!user) {
          const errorText = 'Something went wrong, cannot find current user';
          logger.redux.slice.userSettings.error(errorText);
          throw new Error(errorText);
        }

        const userSettings = current(state.records.find(record => record.userId === user.id));

        if (!userSettings || !userSettings.analyticsFeed || !userSettings.analyticsFeed[analyticsFeedType]) {
          const errorText = `Bad userSettings record for id: ${userSettings?.id}. Missing analyticsFeed field.`;
          logger.redux.slice.userSettings.error(errorText);
          throw new Error(errorText);
        }

        const targetEntityViewed = userSettings.analyticsFeed[analyticsFeedType]!;
        const maxCount = targetEntityViewed.maxCount ?? 0;
        const uniqueEventsMap = new Map<string, EntityViewedEvent>();
        let count = 0;

        // Merge existing events and new events, sort by timestamp, then remove duplicates.
        const sortedMergedEvents =  [...targetEntityViewed.events, ...events]
          .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

        for (const event of sortedMergedEvents) {
          if (!uniqueEventsMap.has(event.entityId)) {
            uniqueEventsMap.set(event.entityId, event);
            count++;
            if (count >= maxCount) break;
          }
        }

        const updatedEvents = Array.from(uniqueEventsMap.values());

        DataStore.save(
          UserSettings.copyOf(userSettings, draft => {
            if (draft.analyticsFeed) {
              draft.updatedAt = now;
              draft.updatedBy = user.id;
              draft.analyticsFeed = {
                ...draft.analyticsFeed,
                [analyticsFeedType]: {
                  ...draft.analyticsFeed[analyticsFeedType],
                  events: updatedEvents,
                },
              }
            }
          }),
        )
      },
    },
  },
  extraReducers,
});

export default userSettingsSlice;
export const userSettingsActions = {
  ...userSettingsSlice.actions,
  ...asyncActions,
}
