import { combineReducers } from '@reduxjs/toolkit';

import authTokenSlice from 'src/state/redux/slice/authToken';
import attachedFileSlice from 'src/state/redux/slice/attachedFile';
import cacheSlice from 'src/state/redux/slice/Cache/cache';
import contentPreviewModalSlice from 'src/state/redux/slice/contentPreviewModal';
import contentShareSlice from 'src/state/redux/slice/contentShare';
import customDeckSlice from 'src/state/redux/slice/customDeck';
import customFormRecordSlice from 'src/state/redux/slice/customFormRecord';
import documentSlice from 'src/state/redux/slice/document';
import documentVersionSlice from 'src/state/redux/slice/documentVersion';
import DNAModalSlice from 'src/state/redux/slice/DNAModal/DNAModal';
import drawerSlice from 'src/state/redux/slice/drawer';
import emailTemplateSlice from 'src/state/redux/slice/emailTemplate';
import folderPermissionSlice from 'src/state/redux/slice/folderPermission';
import folderSlice from 'src/state/redux/slice/folder';
import hubSlice from 'src/state/redux/slice/hub';
import infoPanelSlice from 'src/state/redux/slice/infoPanel';
import integrationSettingsSlice from 'src/state/redux/slice/integrationSettings';
import meetingSlice from 'src/state/redux/slice/meeting';
import presentationBuilderSlice from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder';
import presentationControlSlice from 'src/state/redux/slice/PresentationControl/presentationControl';
import sharedFolderSlice from 'src/state/redux/slice/sharedFolder';
import slideSelectorModalSlice from 'src/state/redux/slice/SlideSelectorModal/SlideSelectorModal';
import tenantSlice from 'src/state/redux/slice/tenant';
import userSlice from 'src/state/redux/slice/user';
import userNotationsSlice from 'src/state/redux/slice/userNotations';
import userSettingsSlice from 'src/state/redux/slice/userSetting';
import pageMappingSlice from 'src/state/redux/slice/pageMapping'

export const rootReducer = combineReducers({
  authToken: authTokenSlice.reducer,
  attachedFile: attachedFileSlice.reducer,
  cache: cacheSlice.reducer,
  contentPreviewModal: contentPreviewModalSlice.reducer,
  contentShare: contentShareSlice.reducer,
  customDeck: customDeckSlice.reducer,
  customFormRecord: customFormRecordSlice.reducer,
  document: documentSlice.reducer,
  documentVersion: documentVersionSlice.reducer,
  DNAModal: DNAModalSlice.reducer,
  drawer: drawerSlice.reducer,
  emailTemplate: emailTemplateSlice.reducer,
  folderPermission: folderPermissionSlice.reducer,
  folder: folderSlice.reducer,
  hub: hubSlice.reducer,
  infoPanel: infoPanelSlice.reducer,
  integrationSettings: integrationSettingsSlice.reducer,
  meeting: meetingSlice.reducer,
  PresentationBuilder: presentationBuilderSlice.reducer,
  PresentationControl: presentationControlSlice.reducer,
  sharedFolder: sharedFolderSlice.reducer,
  SlideSelectorModal: slideSelectorModalSlice.reducer,
  tenant: tenantSlice.reducer,
  user: userSlice.reducer,
  userNotations: userNotationsSlice.reducer,
  userSettings: userSettingsSlice.reducer,
  pageMapping: pageMappingSlice.reducer,
});
