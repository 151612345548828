import { createMachine, send, spawn } from 'xstate';
import { assign } from '@xstate/immer';
import { API, graphqlOperation, GraphQLResult, GraphQLQuery } from '@aws-amplify/api';
import { Storage } from '@aws-amplify/storage';
import pick from 'lodash/pick';

/** BEACON TYPES */
import {
  DocumentVersion,
  Document,
  DocumentVersionChangeType,
  AssociatedFileType,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  CreateDocumentVersionFromS3UploadInput,
  CreateDocumentVersionFromS3UploadMutation,
  CreateDocumentVersionFromExistingMutation,
  PublishDocumentMutation,
  UpdateDocumentThumbnailInput,
  UpdateDocumentThumbnailMutation,
} from '@alucio/aws-beacon-amplify/src/API';
import {
  createDocumentVersionFromExisting,
  createDocumentVersionFromS3Upload,
  publishDocument,
  updateDocumentThumbnail,
  updateDocumentPageData,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { VIDEO_TYPES_ENUM } from 'src/types/types';

/** VERSIONING TYPES */
import { INFO_MESSAGES } from './constants';
import { UPLOAD_STATUS } from 'src/components/DNA/FileUpload/FileUpload';

/** BEACON UTIL */
import { store } from 'src/state/redux';
import { observable } from 'src/state/machines/publisherVersioning/observableVersion';
import { documentActions } from 'src/state/redux/slice/document';
import { documentVersionActions, createAssociatedFile } from 'src/state/redux/slice/documentVersion';
import versioningUtil from 'src/state/machines/publisherVersioning/util';
import { multiSliceActions } from 'src/state/redux/slice/multiSlice';
import slideSettingsMachine from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.machine';
import matchSlidesMachine from 'src/state/machines/publisherVersioning/MatchSlides/matchSlides.machine';
import { formatDocumentFileNameHeader, getExtension } from 'src/utils/documentHelpers';
import * as PV from './publisherVersioning.types';
import * as logger from 'src/utils/logger';

export enum StateTags {
  DOCUMENT_INFO_TAB = 'DOCUMENT_INFO_TAB',
  DOCUMENT_MATCH_SLIDES_TAB = 'DOCUMENT_MATCH_SLIDES_TAB',
  DOCUMENT_SETTINGS_TAB = 'DOCUMENT_SETTINGS_TAB',
  DOCUMENT_ASSOCIATED_FILES = 'DOCUMENT_ASSOCIATED_FILES',
  DOCUMENT_PUBLISH_TAB = 'DOCUMENT_PUBLISH_TAB',
  DISABLE_EXIT = 'DISABLE_EXIT',
  DISABLE_NAV = 'DISABLE_NAV',
  DISABLE_MODIFY = 'DISABLE_MODIFY',
  DISABLE_DRAFT_DELETE = 'DISABLE_DRAFT_DELETE',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
}

export const publisherVersioningSM = createMachine(
  {
    id: 'PublisherVersioningSM',
    tsTypes: {} as import('./publisherVersioning.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as PV.SMContext,
      events: {} as PV.SMEvents,
      services: {} as PV.SMServices,
    },
    context: {
      enableMatchSlides: undefined!,
      availabletabs: undefined!,
      documentInfoIsDirty: false,
      documentSettingsIsDirty: false,
      documentPublishIsDirty: false,
      documentSlidesDataIsDirty: false,
      errors: {},
      documentVersionId: undefined!,
      cancelUpload: false,
      versionForm: undefined!,
      hasOptimizedFinishedThisSession: false,
      getDocumentORM: undefined!,
      selectedTabIndex: 0,
      versionActor: undefined,
      slideSettingsActor: undefined,
      hasNeedReviewSlide: false,
      hasEmptyMatchSlide: false,
    },
    initial: 'preparingMachine',
    states: {
      preparingMachine: {
        after: {
          0: {
            actions: ['spawnVersionActor', 'spawnSlideSettingsActor', 'spawnMatchSlidesActor'],
            target: 'determine',
          },
        },
      },
      determine: {
        description: 'Determines whether we should go to the Publish or Draft node branches',
        always: [
          { target: 'published', cond: 'isSealed' },
          { target: 'published', cond: 'isPublished' },
          { target: 'schedule', cond: 'isVersionScheduled' },
          { target: 'draft' },
        ],
      },
      published: {
        description: 'The published nodes intended for viewing and creating new versions',
        initial: 'documentInfo',
        states: {
          documentInfo: {
            tags: [PV.TabOptions.DOCUMENT_INFO_TAB],
          },
          matchSlides: {
            tags: [PV.TabOptions.DOCUMENT_MATCH_SLIDES_TAB],
          },
          documentSettings: {
            tags: [PV.TabOptions.DOCUMENT_SETTINGS_TAB],
          },
          documentAssociatedFiles: {
            tags: [PV.TabOptions.DOCUMENT_ASSOCIATED_FILES],
          },
          documentReleaseNotes: {
            tags: [PV.TabOptions.DOCUMENT_PUBLISH_TAB],
          },
        },
        on: {
          CREATE_FROM_EXISTING: {
            target: 'draft.documentInfo.processing.existing',
            cond: 'canCreateNewVersion',
          },
          CREATE_FROM_UPLOAD: {
            target: 'draft.documentInfo.processing.upload',
            cond: 'canCreateNewVersion',
          },
          NAV_TO_DOCUMENT_INFO_TAB: { target: '.documentInfo', actions: 'switchTabIdx' },
          NAV_TO_DOCUMENT_MATCH_SLIDES_TAB: {
            target: '.matchSlides',
            actions: 'switchTabIdx',
            cond: 'isMatchSlideTabVisible',
          },
          NAV_TO_DOCUMENT_SETTINGS_TAB: { target: '.documentSettings', actions: 'switchTabIdx' },
          NAV_TO_DOCUMENT_ASSOCIATED_FILES: { target: '.documentAssociatedFiles', actions: 'switchTabIdx' },
          NAV_TO_DOCUMENT_PUBLISH_TAB: { target: '.documentReleaseNotes', actions: 'switchTabIdx' },
        },
      },
      schedule: {
        description: 'The scheduled nodes intended for viewing ',
        initial: 'determine',
        states: {
          determine: {
            description: 'Determines whether we should go',
            always: [
              { target: 'documentInfo', cond: 'isDocumentInfoTab' },
              { target: 'matchSlides', cond: 'isMatchSlidesTab' },
              { target: 'documentSettings', cond: 'isDocumentSettingsTab' },
              { target: 'documentAssociatedFiles', cond: 'isDocumentAssociatedFilesTab' },
              { target: 'documentReleaseNotes', cond: 'isDocumentPublishTab' },
            ],
          },
          determineCancel: {
            description: 'Determines whether we should go',
            always: [
              { target: '#PublisherVersioningSM.draft.documentInfo', cond: 'isDocumentInfoTab' },
              { target: '#PublisherVersioningSM.draft.matchSlides', cond: 'isMatchSlidesTab' },
              { target: '#PublisherVersioningSM.draft.documentSettings', cond: 'isDocumentSettingsTab' },
              { target: '#PublisherVersioningSM.draft.documentAssociatedFiles', cond: 'isDocumentAssociatedFilesTab' },
              { target: '#PublisherVersioningSM.draft.documentPublish', cond: 'isDocumentPublishTab' },
            ],
          },
          documentInfo: {
            tags: [PV.TabOptions.DOCUMENT_INFO_TAB],
          },
          matchSlides: {
            tags: [PV.TabOptions.DOCUMENT_MATCH_SLIDES_TAB],
          },
          documentSettings: {
            tags: [PV.TabOptions.DOCUMENT_SETTINGS_TAB],
          },
          documentAssociatedFiles: {
            tags: [PV.TabOptions.DOCUMENT_ASSOCIATED_FILES],
          },
          documentReleaseNotes: {
            tags: [PV.TabOptions.DOCUMENT_PUBLISH_TAB],
          },
        },
        on: {
          // [TODO] - We should really wait for the update to come back from Datastore before transitioning
          CANCEL_SCHEDULE_VERSION: {
            target: '.determineCancel',
            actions: 'cancelScheduledVersion',
            cond: 'isVersionScheduled',
          },
          VERSION_UPDATE: {
            target: 'determine',
          },
          NAV_TO_DOCUMENT_INFO_TAB: { target: '.documentInfo', actions: 'switchTabIdx' },
          NAV_TO_DOCUMENT_MATCH_SLIDES_TAB: {
            target: '.matchSlides',
            actions: 'switchTabIdx',
            cond: 'isMatchSlideTabVisible',
          },
          NAV_TO_DOCUMENT_SETTINGS_TAB: { target: '.documentSettings', actions: 'switchTabIdx' },
          NAV_TO_DOCUMENT_ASSOCIATED_FILES: { target: '.documentAssociatedFiles', actions: 'switchTabIdx' },
          NAV_TO_DOCUMENT_PUBLISH_TAB: { target: '.documentReleaseNotes', actions: 'switchTabIdx' },
        },
      },
      draft: {
        initial: 'documentInfo',
        states: {
          documentInfo: {
            description: 'Document Info tab in draft mode where most file processing happens',
            tags: [PV.TabOptions.DOCUMENT_INFO_TAB],
            initial: 'determine',
            states: {
              determine: {
                description: 'Determines current draft state node if file is queued, processing or idle',
                always: [
                  { target: 'processing.upload.queued', cond: 'isQueued' },
                  { target: 'processing.upload.optimizing', cond: 'isOptimizing' },
                  { target: 'processing.upload.error', cond: 'hasProcessingError' },
                  { target: 'idle' },
                ],
              },
              idle: {},
              processing: {
                description: 'Processing node for existing or new uploaded files',
                initial: 'idle',
                entry: [
                  'switchToInfoTab',
                  'resetErrors',
                ],
                states: {
                  idle: {},
                  existing: {
                    initial: 'optimizing',
                    tags: [StateTags.DISABLE_DRAFT_DELETE],
                    states: {
                      optimizing: {
                        description: 'Creating an existing file via Lambda function',
                        tags: [
                          StateTags.DISABLE_EXIT,
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_NAV,
                        ],
                        meta: { infoMessage: INFO_MESSAGES.QUEUED },
                        // BEAC-3244 Potential race condition where API call might not return
                        // until after API returns. In this case we still want to switch to the
                        // new version. This seems to occur for files with a certain size payload (~80KB)
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#PublisherVersioningSM.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion', 'setAvailableTabs'],
                            },
                            {
                              target: '#PublisherVersioningSM.draft.documentInfo.idle',
                              cond: 'isVersionUpdateForNewerVersion',
                              actions: [
                                'setDocumentVersionIdFromUpdate',
                                'setVersionFormFromUpdate',
                                'updateSlideSettingsDraft',
                                'setAvailableTabs',
                              ],
                            },
                          ],
                        },
                        invoke: {
                          src: 'createFromExistingOptimizing',
                          onDone: { target: 'processing' },
                          onError: {
                            target: '#PublisherVersioningSM.published',
                            actions: 'handleError',
                          },
                        },
                      },
                      processing: {
                        description: 'Waiting to receive an AppSync subscription to update the current form values',
                        tags: [
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_NAV,
                        ],
                        meta: { infoMessage: INFO_MESSAGES.OPTIMIZING },
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#PublisherVersioningSM.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion', 'setAvailableTabs'],
                            },
                            {
                              target: '#PublisherVersioningSM.draft.documentInfo.idle',
                              cond: 'isConversionChangedToProcessed',
                              actions: [
                                'setDocumentVersionIdFromUpdate',
                                'setVersionFormFromUpdate',
                                'updateSlideSettingsDraft',
                                'setAvailableTabs',
                              ],
                            },
                            {
                              target: '.', // Stay in the same state
                            },
                          ],
                        },
                      },
                    },
                  },
                  upload: {
                    description: 'A multistep process to upload a file and subsequently create records',
                    initial: 'uploading',
                    states: {
                      uploading: {
                        description: 'Step 1. Uploading the file to S3',
                        tags: [
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_NAV,
                          StateTags.DISABLE_DRAFT_DELETE,
                        ],
                        meta: {
                          infoMessage: INFO_MESSAGES.UPLOADING,
                          cancelMessage: INFO_MESSAGES.UPLOAD_CANCELLING,
                        },
                        entry: ['resetFileUploadCancel', 'switchToInfoTab'],
                        invoke: {
                          src: 'uploadFile',
                          onDone: [
                            { target: '#PublisherVersioningSM.published', cond: 'isFileUploadCancelled' },
                            { target: 'createRecord' },
                          ],
                          onError: {
                            actions: 'handleError',
                            target: '#PublisherVersioningSM.published',
                          },
                        },
                        on: {
                          CANCEL_UPLOAD: {
                            actions: 'flagFileUploadCancel',
                            // [NOTE] - This meta property is available until the next event
                            //          (which is dispatched after upload/cancel is finished)
                            meta: { infoMessage: INFO_MESSAGES.UPLOAD_CANCELLING },
                          },
                          // BEAC-3244 Potential race condition where API call might not return
                          // until after API returns. In this case we still want to switch to the
                          // new version. This seems to occur for files with a certain size payload (~80KB)
                          VERSION_UPDATE: [
                            {
                              target: '#PublisherVersioningSM.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion', 'setAvailableTabs'],
                            },
                            {
                              target: '#PublisherVersioningSM.draft.documentInfo.idle',
                              cond: 'isVersionUpdateForNewerVersion',
                              actions: [
                                'setDocumentVersionIdFromUpdate',
                                'setVersionFormFromUpdate',
                                'updateSlideSettingsDraft',
                                'setAvailableTabs',
                              ],
                            },
                          ],
                        },
                      },
                      createRecord: {
                        description: 'Step 1.5 - Create the records via Lambda function or bail if upload cancelled',
                        tags: [
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_EXIT,
                          StateTags.DISABLE_NAV,
                          StateTags.DISABLE_DRAFT_DELETE,
                        ],
                        meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                        invoke: {
                          src: 'createRecordForUpload',
                          onDone: { target: 'processing' },
                          onError: {
                            actions: 'handleError',
                            target: '#PublisherVersioningSM.published',
                          },
                        },
                        // [NOTE] - There is a race condition between the GQL call finishing
                        //          and the AppSync updates being sent out early
                        //        - If we receive an optimistic AppSync update before the GQL call finishes
                        //          Skip the next step and go straight to queued
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#PublisherVersioningSM.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion', 'setAvailableTabs'],
                            },
                            {
                              target: 'queued',
                              // [TODO-2126] - Add guards to make sure we're not receiving outside updates
                              //          not intended for this session
                              actions: 'setDocumentVersionIdFromUpdate',
                            },
                          ],
                        },
                      },
                      processing: {
                        description: 'Step 1.75 - An interim step to wait for the official queued status',
                        tags: [
                          StateTags.DISABLE_NAV,
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_DRAFT_DELETE,
                        ],
                        meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#PublisherVersioningSM.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion', 'setAvailableTabs'],
                            },
                            {
                              target: 'queued',
                              actions: 'setDocumentVersionIdFromUpdate',
                            },
                          ],
                        },
                      },
                      queued: {
                        description: 'Step 2 - In the queued status waiting for the optimized status',
                        tags: [
                          StateTags.DISABLE_NAV,
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_DRAFT_DELETE,
                        ], // [NOTE] - We only block nav because tab switching is buggy here
                        meta: { infoMessage: INFO_MESSAGES.QUEUED },
                      },
                      optimizing: {
                        description: 'Step 3 - In the optimizing status waiting for the final processed status',
                        tags: [
                          StateTags.DISABLE_NAV,
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_DRAFT_DELETE,
                        ], // [NOTE] - We only block nav because tab switching is buggy here
                        meta: { infoMessage: INFO_MESSAGES.OPTIMIZING },
                      },
                      error: {
                        description: 'A critical error state, the user must take action before resuming',
                        tags: [StateTags.PROCESSING_ERROR],
                        meta: { infoMessage: INFO_MESSAGES.PROCESSING_ERROR },
                      },
                    },
                    on: {
                      // [NOTE] - When we are in an "idle processing" state waiting for next conversion updates
                      VERSION_UPDATE: [
                        {
                          target: '#PublisherVersioningSM.published',
                          cond: 'isDraftDeleted',
                          actions: 'setAvailableTabs',
                        },
                        { target: '.queued', cond: 'isQueued' },
                        { target: '.optimizing', cond: 'isOptimizing' },
                        {
                          target: '#PublisherVersioningSM.draft.documentInfo.idle',
                          cond: 'isConversionProcessedFromUpdate',
                          actions: [
                            'flagOptimizedFinishedThisSession',
                            'setVersionFormFromUpdate',
                            'updateSlideSettingsDraft',
                            'setAvailableTabs',
                          ],
                        },
                        { target: '.error', cond: 'isConversionErrorFromUpdate' },
                      ],
                    },
                  },
                },
              },
              uploadThumbnail: {
                description: 'A multistep process to upload a file and subsequently create records',
                tags: [
                  StateTags.DISABLE_MODIFY,
                  StateTags.DISABLE_NAV,
                ],
                meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                initial: 'uploading',
                states: {
                  uploading: {
                    description: 'Step 1. Uploading the file to S3',
                    tags: [
                      StateTags.DISABLE_MODIFY,
                      StateTags.DISABLE_NAV,
                      StateTags.DISABLE_DRAFT_DELETE,
                    ],
                    meta: {
                      infoMessage: INFO_MESSAGES.UPLOADING,
                      cancelMessage: INFO_MESSAGES.UPLOAD_CANCELLING,
                    },
                    entry: ['resetFileUploadCancel', 'switchToInfoTab'],
                    invoke: {
                      src: 'uploadThumbnailImage',
                      onDone: {
                        target: 'createRecord',
                      },
                      onError: {
                        actions: 'handleError',
                        target: '#PublisherVersioningSM.published',
                      },
                    },
                    on: {
                      CANCEL_UPLOAD: {
                        actions: 'flagFileUploadCancel',
                        meta: { infoMessage: INFO_MESSAGES.UPLOAD_CANCELLING },
                      },
                    },
                  },
                  createRecord: {
                    // eslint-disable-next-line max-len
                    description: 'Step 1.5 - Create the records via Lambda function or bail if upload cancelled',
                    tags: [
                      StateTags.DISABLE_MODIFY,
                      StateTags.DISABLE_EXIT,
                      StateTags.DISABLE_NAV,
                      StateTags.DISABLE_DRAFT_DELETE,
                    ],
                    meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                    invoke: {
                      src: 'updateThumbnail',
                      onDone: {
                        target: '#PublisherVersioningSM.draft.documentInfo',
                        actions: 'setVersionFormFromUpdateThumbnail',
                      },
                      onError: {
                        actions: 'handleError',
                        target: '#PublisherVersioningSM.draft.documentInfo',
                      },
                    },
                  },
                },
              },
            },
            on: {
              // [TODO-2126] - This is pretty similar to sync forms on next/tab navigation (used for semver calculations dependencies)
              //             - We sync form data either way, but note that there are two different ways it's being handled right now
              SYNC_DISTRIBUTABLE: {
                description: 'Trigger to disable Associated Files modifications if file is no longer distributable',
                actions: [
                  'setVersionFormFromComponentSync',
                ],
              },
              NAV_TAB_NEXT: [
                { target: undefined, cond: 'isNavBlocked' },
                { target: 'matchSlides', actions: 'switchTabIdx', cond: 'isMatchSlideTabVisible' },
                { target: 'documentSettings', actions: 'switchTabIdx' },
              ],
              SYNC_VERSION_FORM: {
                actions: 'setVersionFormFromComponentSync',
              },
            },
          },
          matchSlides: {
            description: 'Match Slides tab where publisher can review and manu',
            tags: [PV.TabOptions.DOCUMENT_MATCH_SLIDES_TAB],
            on: {
              NAV_TAB_PREV: { target: 'documentInfo', actions: 'switchTabIdx' },
              NAV_TAB_NEXT: {
                target: 'documentSettings',
                actions: [
                  'switchTabIdx',
                  'setVersionFormFromComponentSync',
                ],
              },
              SYNC_VERSION_FORM: {
                description: 'Sync slide settings from component to machine',
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
            },
          },
          documentSettings: {
            description: 'Document Settings tab where Slide settings are configured',
            tags: [PV.TabOptions.DOCUMENT_SETTINGS_TAB],
            on: {
              NAV_TAB_PREV: [
                { target: 'matchSlides', actions: 'switchTabIdx', cond: 'isMatchSlideTabVisible' },
                { target: 'documentInfo', actions: 'switchTabIdx' },
              ],
              NAV_TAB_NEXT: {
                target: 'documentAssociatedFiles',
                actions: [
                  'switchTabIdx',
                  'setVersionFormFromComponentSync',
                ],
              },
              SYNC_VERSION_FORM: {
                description: 'Sync slide settings from component to machine',
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
              SLIDE_SETTINGS_SYNC: {
                actions: [
                  'syncSlideSettingsDraft',
                  'setDocumentSettingsIsDirty',
                ],
              },
              SLIDE_SETTINGS_SYNC_AND_SAVE_DRAFT: {
                actions: [
                  'syncSlideSettingsDraft',
                  'saveDraft',
                ],
              },
              OVERWRITE_SLIDE_SETTINGS: {
                actions: [
                  'syncSlideSettingsDraft',
                  'setDocumentSettingsIsDirty',
                  'setDocumentSlidesDataIsDirty',
                ],
              },
            },
          },
          documentAssociatedFiles: {
            description: 'Document Associated Files tab where Associated Files are configured',
            tags: [PV.TabOptions.DOCUMENT_ASSOCIATED_FILES],
            initial: 'idle',
            states: {
              idle: {},
              processing: {
                description: 'The state during attached file uploading',
                tags: [
                  StateTags.DISABLE_NAV,
                  StateTags.DISABLE_MODIFY,
                ],
                on: {
                  ATTACHED_FILE_UPLOADED: {
                    description: 'On attached file(s) uploaded, create temporary records who commit on save draft',
                    actions: ['createAttachedFileRecords', 'checkIfDirty'],
                  },
                },
              },
            },
            on: {
              ATTACHED_FILE_UPLOAD_STATUS_CHANGE: [
                { target: '.processing', cond: 'isAssociatedFileUploadProcessing' },
                { target: '.idle' },
              ],
              ASSOCIATED_DOCUMENT_LINK: {
                description: 'On linked documents, create temporary records who commit on save draft',
                actions: ['associateDocumentLink', 'checkIfDirty'],
              },
              ASSOCIATED_FILE_DELETE: { actions: ['deleteAssociatedFile', 'checkIfDirty'] },
              ASSOCIATED_FILE_UPDATED: { actions: ['updateAssociatedFile', 'checkIfDirty'] },
              NAV_TAB_PREV: [
                { target: 'documentSettings', actions: 'switchTabIdx' },
              ],
              NAV_TAB_NEXT: {
                target: 'documentPublish',
                actions: [
                  'switchTabIdx',
                  'setVersionFormFromComponentSync',
                ],
              },
              SYNC_VERSION_FORM: {
                description: 'Sync slide settings from component to machine',
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
            },
          },
          documentPublish: {
            description: 'Document Publish tab where notifications, semVer, and release notes are set',
            tags: [PV.TabOptions.DOCUMENT_PUBLISH_TAB],
            initial: 'idle',
            states: {
              idle: {
                description: 'On entry, recalculate the SemVer based on latest form info',
                entry: 'setSemVerChanges',
                on: {
                  PUBLISH_VERSION: {
                    target: 'publishing',
                    cond: 'canPublishDraft',
                    actions: 'storingVersionForm',
                  },
                  SCHEDULE_PUBLISH_VERSION: {
                    target: 'scheduling',
                    cond: 'canPublishDraft',
                    actions: 'storingVersionForm',
                  },
                },
              },
              publishing: {
                tags: [
                  StateTags.DISABLE_NAV,
                  StateTags.DISABLE_MODIFY,
                  StateTags.DISABLE_EXIT,
                ],
                invoke: {
                  src: 'updateContentPageData',
                  onDone: {
                    target: '.',
                    actions: 'publishVersion',
                  },
                  onError: {
                    target: 'idle',
                    actions: 'handleError',
                  },
                },
                on: {
                  // [TODO-2126] - It's possible we could get stuck here if the subscription never comes back
                  //          or the update comes back early
                  //        - Consider a timeout?
                  VERSION_UPDATE: [
                    {
                      target: '#PublisherVersioningSM.published',
                      cond: 'isDraftDeleted',
                      actions: ['switchToLatestPublishedVersion', 'setAvailableTabs'],
                    },
                    {
                      description: 'After publishing, switch over to published view',
                      target: 'setPublishStatus',
                      actions: [
                        'setDocumentVersionIdFromUpdate',
                        'resetSemVerChanges',
                        'resetFormDirty',
                        'resetDocument',
                      ],
                    },
                  ],
                },
              },
              scheduling: {
                tags: [
                  StateTags.DISABLE_NAV,
                  StateTags.DISABLE_MODIFY,
                ],
                invoke: {
                  src: 'updateContentPageData',
                  onDone: {
                    actions: 'scheduleVersion',
                    target: '.',
                  },
                  onError: {
                    target: 'idle',
                    actions: 'handleError',
                  },
                },
                on: {
                  VERSION_UPDATE: [
                    {
                      target: '#PublisherVersioningSM.schedule',
                      actions: [
                        'setDocumentVersionIdFromUpdate',
                        'resetSemVerChanges',
                        'resetFormDirty',
                        'resetDocument',
                      ],
                    },
                  ],

                },

              },
              setPublishStatus: {
                invoke: {
                  src: 'publishDocument',
                  onDone: {
                    target: '#PublisherVersioningSM.published',
                  },
                  onError: {
                    target: 'idle',
                  },
                },
                exit: 'resetFormDirty',
              },
            },
            on: {
              NAV_TAB_PREV: { target: 'documentAssociatedFiles', actions: 'switchTabIdx' },
              SYNC_VERSION_FORM: {
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
            },
          },
          // [TODO-2126] - This is probably better served as a parallel state
          //            This only works because we keep track of the last known tab via selectedTabIdx
          //            and we disable most other actions so the user cannot do anything
          //        - This might also work without switch if we configure the draft node as a history node
          draftSaveProcessing: {
            description: 'A "history" type node that handles saving draft (or publishing) from any tab',
            tags: [
              StateTags.DISABLE_NAV,
              StateTags.DISABLE_MODIFY,
            ],
            invoke: {
              src: 'updateContentPageData',
              onDone:
                [
                  {
                    target: '#PublisherVersioningSM.draft.matchSlides',
                    cond: 'isMatchSlidesTab',
                    actions: ['saveDraft', 'resetFormDirty'],
                  },
                  {
                    target: '#PublisherVersioningSM.draft.documentSettings',
                    cond: 'isDocumentSettingsTab',
                    actions: ['saveDraft', 'resetFormDirty'],
                  },
                  {
                    target: '#PublisherVersioningSM.draft.documentAssociatedFiles',
                    cond: 'isDocumentAssociatedFilesTab',
                    actions: ['saveDraft', 'resetFormDirty'],
                  },
                  {
                    target: '#PublisherVersioningSM.draft.documentPublish',
                    cond: 'isDocumentPublishTab',
                    actions: ['saveDraft', 'resetFormDirty'],
                  },
                  {
                    target: '#PublisherVersioningSM.draft.documentInfo',
                    actions: ['saveDraft', 'resetFormDirty'],
                  },
                ],
              onError: {
                target: '#PublisherVersioningSM.draft',
                actions: 'handleError',
              },
            },
            on: {
              VERSION_UPDATE: [
                // [TODO-2126] - Experimental check to avoid version updates until the appsync version is actually bumped
                //               since we receive 3 subscription updates.
                //             - We should probably apply this to all VERSION_UPDATE actions, but test here for now
                { cond: 'updateVerNotBumped' },
                {
                  target: '#PublisherVersioningSM.published',
                  cond: 'isDraftDeleted',
                  actions: 'setAvailableTabs',
                },
                {
                  target: '#PublisherVersioningSM.draft.documentInfo',
                  cond: 'isDocumentInfoTab',
                  actions: ['resetFormDirty', 'resetDocument'],
                },
                {
                  target: '#PublisherVersioningSM.draft.matchSlides',
                  cond: 'isMatchSlidesTab',
                  actions: 'resetFormDirty',
                },
                {
                  target: '#PublisherVersioningSM.draft.documentSettings',
                  cond: 'isDocumentSettingsTab',
                  actions: ['resetFormDirty', 'resetDocument'],
                },
                {
                  target: '#PublisherVersioningSM.draft.documentAssociatedFiles',
                  cond: 'isDocumentAssociatedFilesTab',
                  actions: ['resetFormDirty', 'resetDocument'],
                },
                {
                  target: '#PublisherVersioningSM.draft.documentPublish',
                  cond: 'isDocumentPublishTab',
                  actions: ['resetFormDirty', 'resetDocument'],
                },
              ],
            },
          },
        },
        on: {
          UPLOAD_THUMBNAIL: {
            target: 'draft.documentInfo.uploadThumbnail',
          },
          REMOVE_THUMBNAIL: {
            target: 'draft.documentInfo.uploadThumbnail.createRecord',
          },
          SET_IS_DIRTY: {
            description: 'Mark the various tabs as dirty from the form components',
            actions: 'setDirtyTabs',
          },
          MATCH_SLIDES_SYNC: {
            actions: 'syncUnresolvedSlideMatches',
          },
          VERSION_UPDATE: [
            {
              // [TODO-2126] - This is also repeated in other VERSION_UPDATE events -- we should figure out a way where it's more centralized
              //               This is because nested VERSION_UPDATES will take precdence but each of those also need to handle their own usecase
              //             - Try to see if there's a better global catch all level pattern we could use
              //             - Also, this still doesn't 100% absolve crashing the app, we still have to put a workaround in component side
              //               because selector updates there will return a null currentDocumentVersionORM
              //               before the machine can properly transition
              //             - This probably also doesn't work because the observed delete event will never come in as the record by that time
              //               has already been removed in Redux 🤦‍♂️
              description: 'Handles the concurrent use-case of when another publisher deletes a draft',
              target: '#PublisherVersioningSM.published',
              cond: 'isDraftDeleted',
              actions: ['switchToLatestPublishedVersion', 'setAvailableTabs'],
            },
            {
              description: 'Handles the concurrent use-case of when another publisher publishes the same version',
              target: '#PublisherVersioningSM.published',
              // [TODO-BUG] - Possible bug here if a nested `VERSION_UPDATE` fails to transition, this condition may get triggered
              //            - Cause a transition back into an idle Published when maybe no transition would have been better
              //            - The cond used here should probably also check to see if the versionNumber got bumped (to flag someone else did publish a new ver)
              cond: 'isPublished',
              // [TODO-2126] - Use an action to set the current form values to the newly published version
              //        - Or do read states not source from State machine's context?
            },
          ],
          DELETE_DRAFT: {
            target: '#PublisherVersioningSM.published',
            cond: 'isDraftDeleteEnabled',
            actions: [
              'switchToLatestPublishedVersion',
              'setAvailableTabs',
              'deleteDraft',
              'updateSlideSettingsDraft',
              'resetFormDirty',
            ],
          },
          SAVE_DRAFT: {
            target: '#PublisherVersioningSM.draft.draftSaveProcessing',
            actions: 'storingVersionForm',
          },
          NAV_TO_DOCUMENT_INFO_TAB: {
            target: '.documentInfo',
            cond: 'isNavUnblocked',
            actions: 'switchTabIdx',
          },
          NAV_TO_DOCUMENT_MATCH_SLIDES_TAB: {
            target: '.matchSlides',
            cond: 'isMatchSlideTabVisible',
            actions: 'switchTabIdx',
          },
          NAV_TO_DOCUMENT_SETTINGS_TAB: {
            target: '.documentSettings',
            cond: 'isNavUnblocked',
            actions: ['switchTabIdx'],
          },
          NAV_TO_DOCUMENT_ASSOCIATED_FILES: {
            target: '.documentAssociatedFiles',
            cond: 'isNavUnblocked',
            actions: 'switchTabIdx',
          },
          NAV_TO_DOCUMENT_PUBLISH_TAB: {
            target: '.documentPublish',
            cond: 'isNavUnblocked',
            actions: [
              'switchTabIdx',
              'setVersionFormFromComponentSync',
            ],
          },
        },
      },
    },
    on: {
      SWITCH_DOCUMENT_VERSION: {
        target: 'determine',
        cond: 'isNavUnblocked',
        actions: [
          'setDocumentVersionIdFromVersionSelect',
          'setAvailableTabs',
          'switchToInfoTab',
          'resetFormDirty',
          'resetDocument',
          'resetErrors',
        ],
      },
    },
  },
  {
    guards: {
      isMatchSlideTabVisible: (ctx, _, meta) => {
        return !meta.state.hasTag(StateTags.DISABLE_NAV) &&
        ctx.availabletabs.includes(PV.TabOptions.DOCUMENT_MATCH_SLIDES_TAB)
      },
      isPublished: (ctx, event, meta) => {
        const SKIP_STATES = ['draft.documentInfo.processing']

        const evt = event as PV.EVT_VERSION_UPDATE
        const documentORM = ctx.getDocumentORM()
        const selectedDocVerORM = documentORM
          .relations
          .documentVersions
          .find(docVerORM => docVerORM.model.id === ctx.documentVersionId)
        const isProcessing = (
          SKIP_STATES.some(skipState => meta.state.matches(skipState)) ||
          evt?.payload?.current?.conversionStatus === 'PENDING' ||
          evt?.payload?.current?.conversionStatus === 'PROCESSING'
        )

        // [NOTE] - This could be stale due to not switching DocVerId during processing
        const rv = selectedDocVerORM?.model.status === 'PUBLISHED' && !isProcessing

        if (rv) {
          logger.versioning.warn('PublisherVersioningSM', 'Concurrent switch', event)
        }

        return rv
      },
      updateVerNotBumped: (_ctx, evt) => {
        // @ts-expect-error - these are untyped, meta fields from appsync
        const skipUpdate = evt.payload.prev._version === evt.payload.current._version
        return skipUpdate
      },
      isDraftDeleteEnabled: (_, __, meta) => {
        return !meta.state.hasTag(StateTags.DISABLE_DRAFT_DELETE)
      },
      isDraftDeleted: (_ctx, evt) => {
        const isDraftDeleted = evt.payload.current.status === 'DELETED'
        return isDraftDeleted
      },
      isVersionScheduled: (ctx) => {
        return !!ctx.versionForm.scheduledPublish
      },
      isSealed: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        return ['ARCHIVED', 'REVOKED', 'DELETED'].includes(documentORM?.model.status)
      },
      /** NAVIGATION */
      isDocumentInfoTab: (ctx) => ctx.availabletabs[ctx.selectedTabIndex] === PV.TabOptions.DOCUMENT_INFO_TAB,
      isMatchSlidesTab: (ctx) => ctx.availabletabs[ctx.selectedTabIndex] === PV.TabOptions.DOCUMENT_MATCH_SLIDES_TAB,
      isDocumentSettingsTab: (ctx) => ctx.availabletabs[ctx.selectedTabIndex] === PV.TabOptions.DOCUMENT_SETTINGS_TAB,
      isDocumentAssociatedFilesTab: (ctx) => {
        return ctx.availabletabs[ctx.selectedTabIndex] === PV.TabOptions.DOCUMENT_ASSOCIATED_FILES
      },
      isDocumentPublishTab: (ctx) => ctx.availabletabs[ctx.selectedTabIndex] === PV.TabOptions.DOCUMENT_PUBLISH_TAB,
      isNavUnblocked: (_, __, meta) => !meta.state.hasTag(StateTags.DISABLE_NAV),
      isNavBlocked: (_, __, meta) => meta.state.hasTag(StateTags.DISABLE_NAV),
      isVersionUpdateForNewerVersion: (ctx, evt) => {
        const currentVersionNumber = ctx.documentVersionId
          .split('_')
          .at(1)
        const nextVersionNumber = evt.payload.current.versionNumber

        if (!currentVersionNumber) {
          throw new Error('Invalid documentVersionId stored')
        }

        const isNewVersionProcessed = (
          (Number(currentVersionNumber) < nextVersionNumber) &&
          evt.payload.current.conversionStatus === 'PROCESSED'
        )

        return isNewVersionProcessed
      },
      /** DOCUMENT SETTINGS */
      isAssociatedFileUploadProcessing: (_ctx, evt) => {
        const isProcessing = [
          UPLOAD_STATUS.IN_PROGRESS,
          UPLOAD_STATUS.CANCELLING,
        ].some(status => evt.payload.status === status)

        return isProcessing
      },

      /** NEW VERSION CREATION */
      canCreateNewVersion: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const draftInProgress = documentORM.meta.hasUnpublishedVersion
        const isPublished = documentORM.model.status === 'PUBLISHED'
        return !draftInProgress && isPublished
      },
      canPublishDraft: (ctx) => {
        const hasNoErrors = !Object.values(ctx.errors).length
        return hasNoErrors
      },
      /** NEW VERSION PROCESSING */
      hasProcessingError: (ctx) => {
        return ctx.getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM
          .model
          .conversionStatus === 'ERROR';
      },
      isOptimizing: (ctx) => {
        return ctx.getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM
          .model
          .conversionStatus === 'PROCESSING';
      },
      isQueued: (ctx) => {
        return ctx.getDocumentORM().relations.version.latestDocumentVersionORM.model.conversionStatus === 'PENDING';
      },
      isConversionProcessedFromUpdate: (_ctx, evt) => {
        return evt.payload.current.conversionStatus === 'PROCESSED'
      },
      isConversionChangedToProcessed: (_ctx, evt) => {
        const prev = evt.payload.prev
        const current = evt.payload.current
        // if the pdf is to small we can have a race condition where the previous state is already processed
        const prevValidStates = ['PROCESSING', 'PENDING', 'PROCESSED'].includes(prev.conversionStatus)
        return (prevValidStates && current.conversionStatus === 'PROCESSED')
      },
      isConversionErrorFromUpdate: (_ctx, evt) => {
        return evt.payload.current.conversionStatus === 'ERROR'
      },
      isFileUploadCancelled: (ctx) => ctx.cancelUpload,
    },
    actions: {
      /** SPAWN ACTORS */
      spawnVersionActor: assign((ctx) => {
        if (!ctx.versionActor) {
          logger.versioning.debug('Spawning Version Actor')
          const [documentId] = ctx.documentVersionId.split('_');
          ctx.versionActor = spawn(
            observable({ filter: { model: { id: documentId } } }),
            'VersionActor',
          )
        }
      }),
      spawnSlideSettingsActor: assign((ctx) => {
        if (!ctx.slideSettingsActor) {
          logger.versioning.debug('Spawning Slide Settings Actor')
          const [documentId] = ctx.documentVersionId.split('_');
          const docVersionORM = versioningUtil.getDocumentORMFactory(documentId)()
            .relations.documentVersions.find((version) => ctx.documentVersionId === version.model.id)
          if (docVersionORM) {
            ctx.slideSettingsActor = spawn(
              slideSettingsMachine.withContext({
                documentVersionId: docVersionORM.model.id,
                versionDraft: {
                  pages: docVersionORM.meta.allPages,
                  pageGroups: docVersionORM.model.pageGroups,
                  selectedThumbnail: docVersionORM.model.selectedThumbnail,
                },
                associatedSlides: {
                  child: {},
                  parent: {},
                },
                groupings: {
                  selectedPages: {},
                  groups: {},
                },
                selectedRemoveAssociatedSlides: {},
                selectedRequiredSlides: {},
                selectedCoverThumbnail: undefined,
                stepOneIsDirty: false,
                stepTwoIsDirty: false,
                // [TODO] - Use a equal comparison instead of setting ctx flags
                isRequiredSlidesDirty: false,
                errors: [],
              }),
              'SlideSettingsActor',
            )
          } else {
            console.error(`Unable to locate DocumentVersionORM for document ${ctx.documentVersionId}`)
          }
        }
      }),
      spawnMatchSlidesActor: assign((ctx) => {
        logger.versioning.debug('Spawning Match Slides Actor')
        if (!ctx.matchSlidesActor) {
          ctx.matchSlidesActor = spawn(
            matchSlidesMachine.withContext({
              slidesGroupPoolVisible: false,
              hasNeedReviewSlide: false,
              hasEmptyMatchSlide: false,
              slideCount: { noMatch: 0, needsReview: 0, total: 0 },
            }),
            'MatchSlidesActor',
          )
        }
      }),
      /** NAVIGATION */
      switchToInfoTab: assign((ctx) => { ctx.selectedTabIndex = 0 }),
      switchTabIdx: assign((ctx, evt) => {
        logger.versioning.debug('Switching Tabs', evt)

        // [NOTE] - We noop tab switches that would result in the same value to (possibly) avoid re-renders
        const actions = ['NAV_TAB_NEXT', 'NAV_TAB_PREV']
        const prefix = 'NAV_TO_';
        const evtTargetTabName = evt.type.startsWith(prefix)
          ? evt.type.slice(prefix.length) as PV.TabOptions : undefined;
        const targetTabIndex = evtTargetTabName ? ctx.availabletabs.indexOf(evtTargetTabName) : -1;
        if (targetTabIndex !== -1 && ctx.selectedTabIndex !== targetTabIndex) {
          ctx.selectedTabIndex = targetTabIndex;
        } else if (evt.type === actions[0] && ctx.selectedTabIndex < ctx.availabletabs.length - 1) {
          ctx.selectedTabIndex++;
        } else if (evt.type === actions[1] && ctx.selectedTabIndex > 0) {
          ctx.selectedTabIndex--;
        }
      }),
      setAvailableTabs: assign((ctx) => {
        const isFirstVersion = ctx.getDocumentORM().relations.documentVersions
          .find(docVer => docVer.model.id === ctx.documentVersionId)
          ?.model.versionNumber === 1
        ctx.availabletabs = versioningUtil.getAvailabletabs(ctx.enableMatchSlides && !isFirstVersion)
        // [NOTE]: Resetting selectedTabIndex here does not actually reset to the first tab in the UI.
        //         This is a workaround so the tab component UI does not get stuck when the width changed.
        ctx.selectedTabIndex = 0;
      }),
      /** DOCUMENT/VERSION META */
      setDocumentVersionIdFromUpdate: assign((ctx, evt) => {
        ctx.documentVersionId = evt.payload.current.id
      }),
      setDocumentVersionIdFromVersionSelect: assign((ctx, evt) => {
        ctx.documentVersionId = evt.payload.documentVersionId
      }),
      switchToLatestPublishedVersion: assign((ctx) => {
        const latestPublishedVersion = ctx
          .getDocumentORM()
          .relations
          .version
          .latestPublishedDocumentVersionORM

        if (latestPublishedVersion) {
          ctx.documentVersionId = latestPublishedVersion.model.id
        }
      }),
      /** DRAFT - DIRTY */
      setDirtyTabs: assign((ctx, evt) => {
        switch (evt.payload.type) {
          case 'info': {
            ctx.documentInfoIsDirty = evt.payload.isDirty
            return;
          }
          case 'settings': {
            ctx.documentSettingsIsDirty = evt.payload.isDirty
            return;
          }
          case 'slidesData': {
            ctx.documentSlidesDataIsDirty = evt.payload.isDirty
            return;
          }
          case 'publish': {
            ctx.documentPublishIsDirty = evt.payload.isDirty
          }
        }
      }),
      setDocumentSettingsIsDirty: assign((ctx) => {
        ctx.documentSettingsIsDirty = true;
      }),
      setDocumentSlidesDataIsDirty: assign((ctx) => {
        ctx.documentSlidesDataIsDirty = true;
      }),
      checkIfDirty: assign((ctx) => {
        // [NOTE] - Deep equality checking (lodash, fast-deep-equal) isn't working
        //          after initial form touching, not sure what values are the offenders,
        //          string comparison works though and should be good enough (hopefully)
        const currentDocVer = ctx.getDocumentORM().relations.version.latestDocumentVersionORM
        const one = JSON.stringify(versioningUtil.omitInternalFields(currentDocVer.model))
        const two = JSON.stringify(ctx.versionForm)

        ctx.documentSettingsIsDirty = one !== two
      }),
      resetFormDirty: assign((ctx) => {
        ctx.documentInfoIsDirty = false
        ctx.documentPublishIsDirty = false
        ctx.documentSettingsIsDirty = false
        ctx.documentSlidesDataIsDirty = false
      }),
      syncUnresolvedSlideMatches: assign((ctx, evt) => {
        ctx.hasNeedReviewSlide = evt.payload.hasNeedReviewSlide
        ctx.hasEmptyMatchSlide = evt.payload.hasEmptyMatchSlide
      }),
      /** DRAFT - SETTERS */
      syncSlideSettingsDraft: assign((ctx, evt) => {
        ctx.versionForm = {
          ...ctx.versionForm,
          ...evt.payload.versionForm,
        }
      }),
      setVersionFormFromUpdate: assign((ctx, evt) => {
        // [NOTE] - We override the entire draft form, not merge
        ctx.versionForm = versioningUtil.omitInternalFields(evt.payload.current)
      }),
      setVersionFormFromComponentSync: assign((ctx, evt) => {
        ctx.versionForm = { ...ctx.versionForm, ...(evt.payload?.versionForm ?? {}) }
      }),
      setVersionFormFromUpdateThumbnail: assign((ctx, evt) => {
        ctx.versionForm.hasCustomThumbnail = evt.data?.hasCustomThumbnail
        ctx.versionForm.selectedThumbnail = evt.data?.selectedThumbnail
      }),
      associateDocumentLink: assign((ctx, evt) => {
        if (!ctx.versionForm.associatedFiles) { ctx.versionForm.associatedFiles = [] }

        const newAssociatedFile = createAssociatedFile({
          attachmentId: evt.payload.documentId,
          type: AssociatedFileType.DOCUMENT,
          status: 'ACTIVE',
        })

        ctx.versionForm.associatedFiles.push(newAssociatedFile)
      }),
      deleteAssociatedFile: assign((ctx, evt) => {
        ctx.versionForm.associatedFiles = ctx
          .versionForm
          .associatedFiles
          ?.filter(file => file.attachmentId !== evt.payload.attachmentId)
      }),
      updateAssociatedFile: assign((ctx, evt) => {
        if (!ctx.versionForm.associatedFiles) { ctx.versionForm.associatedFiles = [] }

        const targetFileIdx = ctx
          .versionForm
          .associatedFiles
          .findIndex(file => file.attachmentId === evt.payload.associatedFile.attachmentId)

        ctx.versionForm.associatedFiles[targetFileIdx] = evt.payload.associatedFile
      }),
      setSemVerChanges: (ctx) => {
        const hasUnresolvedSlideMatches = ctx.hasNeedReviewSlide || ctx.hasEmptyMatchSlide
        const documentORM = ctx.getDocumentORM()
        const versionChanges = versioningUtil.determineSemVerChange(
          ctx.versionForm,
          documentORM.relations.version.latestPublishedDocumentVersionORM,
        )

        ctx.changesCheckResult = {
          disableMinor: hasUnresolvedSlideMatches || versionChanges.isMajorVersionRequired,
          detectedChangeType: (hasUnresolvedSlideMatches || versionChanges.isMajorVersionProposed)
            ? DocumentVersionChangeType.MAJOR
            : DocumentVersionChangeType.MINOR,
        }
      },
      createAttachedFileRecords: assign((ctx, evt) => {
        const { attachedFile } = evt.payload

        if (!ctx.versionForm.associatedFiles) { ctx.versionForm.associatedFiles = [] }

        // Create temporary attached file record
        const newAssociatedFile = createAssociatedFile({
          attachmentId: attachedFile.id,
          type: AssociatedFileType.ATTACHED_FILE,
          status: 'ACTIVE',
        });

        ctx.versionForm.associatedFiles.push(newAssociatedFile)
      }),
      resetDocument: assign((ctx) => {
        const currentReduxVersion = ctx
          .getDocumentORM()
          .relations
          .documentVersions
          .find(ver => ver.model.id === ctx.documentVersionId)?.model ??
          {}

        ctx.versionForm = currentReduxVersion
      }),
      resetSemVerChanges: assign((ctx) => { ctx.changesCheckResult = undefined }),
      resetErrors: assign((ctx) => { ctx.errors = {} }),
      /** NEW VERSION PROCESSING */
      flagOptimizedFinishedThisSession: assign((ctx) => { ctx.hasOptimizedFinishedThisSession = true }),
      resetFileUploadCancel: assign((ctx) => { ctx.cancelUpload = false }),
      flagFileUploadCancel: assign((ctx) => { ctx.cancelUpload = true }),

      /** DRAFT - SAVE & PUBLISH */
      // [TODO-2126] - Figure out an elegant way to glue together multiple actions from different slices
      //          i.e. Document (first time) and Version publish
      storingVersionForm: (ctx, evt) => {
        ctx.savingVersionForm = evt.payload.versionForm;
      },
      publishVersion: (ctx) => {
        const documentORM = ctx.getDocumentORM()

        const isFirstVersion = !documentORM.relations.version.latestPublishedDocumentVersionORM
        const { latestDocumentVersionORM } = documentORM.relations.version

        // Publish the document if this is the very first version
        if (isFirstVersion) {
          store.dispatch(documentActions.publish(documentORM));
        }
        // Otherwise bump the updatedAt timestamp and track analytics
        else {
          store.dispatch(documentActions.save({
            model: Document,
            entity: documentORM.model,
            updates: {},
          }));

          // We only track on new version for existing published doc
          analytics?.track('DOCUMENT_PUBLISH_VERSION', {
            action: 'PUBLISH_VERSION',
            category: 'DOCUMENT',
            context: 'Immediate',
            documentId: latestDocumentVersionORM.relations.documentORM.model.id,
            documentVersionId: latestDocumentVersionORM.model.id,
          });
        }

        const updates = { ...ctx.versionForm, ...ctx.savingVersionForm }
        const omittedValues = versioningUtil.omitInternalFields(updates)
        store.dispatch(documentVersionActions.prePublish(latestDocumentVersionORM, omittedValues));
        ctx.savingVersionForm = undefined;
      },
      scheduleVersion: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const { latestDocumentVersionORM } = documentORM.relations.version
        const updates = { ...ctx.versionForm, ...ctx.savingVersionForm }

        const omittedValues = versioningUtil.omitInternalFields(updates)
        store.dispatch(documentVersionActions.schedulePublish(latestDocumentVersionORM, omittedValues))

        analytics?.track('PUBLISH_SCHEDULED', {
          action: 'SCHEDULED',
          category: 'DOCUMENT',
          documentId: latestDocumentVersionORM.relations.documentORM.model.id,
          documentVersionId: latestDocumentVersionORM.model.id,
        });
        ctx.savingVersionForm = undefined;
      },
      cancelScheduledVersion: assign((ctx) => {
        const documentORM = ctx.getDocumentORM()
        const { latestDocumentVersionORM } = documentORM.relations.version
        ctx.versionForm.scheduledPublish = undefined

        store.dispatch(documentVersionActions.cancelScheduledPublish(latestDocumentVersionORM));

        analytics?.track('PUBLISH_SCHEDULED_CANCELLED', {
          action: 'SCHEDULED',
          category: 'DOCUMENT',
          documentId: latestDocumentVersionORM.relations.documentORM.model.id,
          documentVersionId: latestDocumentVersionORM.model.id,
        });
      }),
      saveDraft: (ctx, evt) => {
        // [TODO-2126] - Needs to be document version now
        analytics?.track('DOCUMENT_SAVE', {
          action: 'SAVE',
          category: 'DOCUMENT',
          documentId: ctx.documentVersionId,
        });

        // We need to grab the latest version
        // The model id is not being bumped?
        const documentVersion = ctx
          .getDocumentORM()
          .relations
          .documentVersions
          .find(docVer => docVer.model.id === ctx.documentVersionId)

        if (!documentVersion) {
          console.error('Something went wrong during saving')
          return;
        }

        // We need to update the version form after hitting Edit Properties
        let updates: Partial<DocumentVersion> = {}
        if (evt.type === 'SLIDE_SETTINGS_SYNC_AND_SAVE_DRAFT') {
          // If the event type is `SLIDE_SETTINGS_SYNC`, we are only updating values from the slide setting tab
          const slideSettingsValues = pick(ctx.versionForm, ['pageGroups', 'selectedThumbnail', 'pageSettings'])
          updates = versioningUtil.omitInternalFields({ ...slideSettingsValues })
        } else {
          const newVersionForm = ctx.savingVersionForm ?? {}
          updates = versioningUtil.omitInternalFields({ ...ctx.versionForm, ...newVersionForm })
          ctx.savingVersionForm = undefined;
        }

        store.dispatch(documentVersionActions.saveWithValidateRecord({
          model: DocumentVersion,
          entity: documentVersion.model,
          updates,
        }));
      },
      deleteDraft: assign((ctx) => {
        const currentDocumentVersionORM = ctx
          .getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM
        // [TODO-2126] - Temp workaround to allow concurrent deletion, normally we don't need to check here
        //               but in this case, we want to make sure we don't execute the action
        if (currentDocumentVersionORM.model.status === 'NOT_PUBLISHED') {
          store.dispatch(multiSliceActions.deleteDocumentDraft(currentDocumentVersionORM))
        }
      }),
      /** ERROR HANDLERS */
      handleError: assign((ctx, evt) => {
        const error = { errEvt: evt }
        console.error(error)
        logger.versioning.warn('Error occurred in publisher versioning', error)
        // @ts-expect-error
        ctx.errors[evt.type] = evt?.data?.message ?? ''
      }),
      updateSlideSettingsDraft: send((ctx) => {
        const currentDocumentVersion = ctx
          .getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM

        return {
          type: 'SIGNAL_VERSION_UPDATE',
          payload: currentDocumentVersion,
        }
      }, {
        to: 'SlideSettingsActor',
      }),
    },
    services: {
      uploadFile: async (ctx, event) => {
        logger.versioning.create.info('Starting File Upload')
        const documentORM = ctx.getDocumentORM()
        const evt = event as PV.EVT_CREATE_FROM_UPLOAD
        logger.versioning.create.debug({ fileUploadEvt: evt })
        const file = evt.payload.file

        try {
          const fileExtension = getExtension(file)
          const key = `publish_tmp/${Date.now()}.${fileExtension}`;
          const name = formatDocumentFileNameHeader(file.name)

          // UPLOAD THE FILE TO THE TEMPORARY PATH IN S3
          logger.versioning.create.debug('Storing file in s3', key)
          await Storage.put(key, file, {
            contentType: file!.type,
            contentDisposition: `attachment; filename="${name}"`,
            level: 'private',
          });

          logger.versioning.create.debug('Stored file in S3 successfully')

          const apiInputObj: CreateDocumentVersionFromS3UploadInput = {
            srcFilename: file.name,
            fileS3Key: key,
            documentId: documentORM.model.id,
            existingVersionId: documentORM.relations.version.latestPublishedDocumentVersionORM?.model.id!,
            version: documentORM.relations.documentVersions.length + 1,
          };

          if (ctx.cancelUpload) {
            logger.versioning.create.debug('File upload cancelled')
            // Since it was cancelled but can't stop the put, proceed to delete the item
            Storage.remove(key);
            logger.versioning.create.debug('Attempting to remove s3 object', key)
            return 'CANCEL';
          }

          const evtData: PV.EVT_CREATE_FILE['data'] = {
            file,
            S3Key: key,
            apiInputObj,
          }

          return evtData
        } catch (e) {
          console.error(e);
          logger.versioning.create.error('Error uploading file to S3', e)
          throw e
        }
      },
      createRecordForUpload: async (_ctx, evevt) => {
        const evt = evevt as PV.SMServices['uploadFile']
        if (evt.data === 'CANCEL') {
          const errorText = 'Error state. Upload has been canceled. Cannot create record.'
          logger.versioning.error(errorText)
          throw new Error(errorText)
        }

        // [NOTE] - This data comes from the previous step/action above (uploadFile)
        const { apiInputObj } = evt.data
        const result = await API.graphql(
          graphqlOperation(
            createDocumentVersionFromS3Upload,
            { inputVersion: apiInputObj },
          ),
        ) as GraphQLResult<CreateDocumentVersionFromS3UploadMutation>;

        analytics?.track('DOCUMENT_UPLOAD_VERSION', {
          action: 'UPLOAD_VERSION',
          category: 'DOCUMENT',
          documentId: result.data?.createDocumentVersionFromS3Upload?.documentId,
          documentVersionId: result.data?.createDocumentVersionFromS3Upload?.id,
        });

        return result
      },
      uploadThumbnailImage: async (ctx, event) => {
        const documentORM = ctx.getDocumentORM()
        const evt = event as PV.EVT_UPLOAD_THUMBNAIL
        const file = evt.payload.file
        const type = VIDEO_TYPES_ENUM[documentORM.model.type] ? 'VIDEO' : documentORM.model.type
        const isWebDoc = documentORM.model.type === FileType.WEB
        analytics?.track('UPLOAD_THUMBNAIL', {
          action: 'UPLOAD',
          category: 'DOCUMENT',
          type,
          documentId: documentORM.relations.version.latestDocumentVersionORM.model.documentId,
          documentVersionId: documentORM.relations.version.latestDocumentVersionORM.model.id,
          URL: isWebDoc ? documentORM.relations.version.latestDocumentVersionORM.model.contentURL : undefined,
        })

        try {
          const fileExtension = getExtension(file)
          const key = `publish_tmp/${Date.now()}.${fileExtension}`;
          await Storage.put(key, file, {
            contentType: file!.type,
            contentDisposition: `attachment; filename="${encodeURIComponent(file.name)}"`,
            level: 'private',
          });

          const apiInputObj: UpdateDocumentThumbnailInput = {
            srcFilename: file.name,
            fileS3Key: key,
            documentId: documentORM.model.id,
            documentVersionId: documentORM.relations.version.latestDocumentVersionORM?.model.id!,
          };

          if (ctx.cancelUpload) {
            // Since it was cancelled but can't stop the put, proceed to delete the item
            Storage.remove(key);
            return 'CANCEL';
          }

          const evtData: PV.EVT_UPDATE_THUMBNAIL['data'] = {
            file,
            S3Key: key,
            apiInputObj,
          }

          return evtData
        } catch (e) {
          console.error(e);
          throw e
        }
      },
      updateThumbnail: async (ctx, event) => {
        const evt = event as PV.EVT_UPDATE_THUMBNAIL
        const documentORM = ctx.getDocumentORM()
        if (evt.type !== 'REMOVE_THUMBNAIL' && !evt.data?.apiInputObj) return
        try {
          const apiInputObj = (evt && evt.data) ? evt.data.apiInputObj
            : {
              documentId: documentORM.model.id,
              documentVersionId: documentORM.relations.version.latestDocumentVersionORM?.model.id!,
            }
          const result = await API.graphql(
            graphqlOperation(
              updateDocumentThumbnail,
              { inputDocumentThumbnail: apiInputObj },
            ),
          ) as GraphQLResult<UpdateDocumentThumbnailMutation>;
          const evtData: PV.EVT_UPDATED_THUMBNAIL['data'] = {
            selectedThumbnail: result.data?.updateDocumentThumbnail?.selectedThumbnail!,
            hasCustomThumbnail: result.data?.updateDocumentThumbnail?.hasCustomThumbnail!,
          }
          // track when user removed customThumbnail
          if (!evtData.hasCustomThumbnail) {
            const documentType = documentORM.model.type
            const type = VIDEO_TYPES_ENUM[documentType] ? 'VIDEO' : documentType
            const isWebDoc = documentORM.model.type === FileType.WEB
            analytics?.track('REMOVE_THUMBNAIL', {
              action: 'REMOVE_THUMBNAIL',
              category: 'DOCUMENT',
              type,
              documentId: documentORM.model.id,
              documentVersionId: documentORM.relations.version.latestDocumentVersionORM?.model.id!,
              URL: isWebDoc ? documentORM.relations.version.latestDocumentVersionORM.model.contentURL : undefined,
            })
          }
          return evtData
        } catch (e) {
          console.error(e);
          throw e
        }
      },
      createFromExistingOptimizing: async (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const { latestDocumentVersionORM } = documentORM.relations.version

        try {
          const newDocVer = await API.graphql<GraphQLQuery<CreateDocumentVersionFromExistingMutation>>(
            graphqlOperation(createDocumentVersionFromExisting, {
              documentId: latestDocumentVersionORM.model.documentId,
              newVersionNumber: latestDocumentVersionORM.model.versionNumber + 1,
              existingVersionId: latestDocumentVersionORM.model.id,
            }),
          );

          return newDocVer
        } catch (e) {
          console.warn('Error when creating existing version from existing', e)
          throw e
        }
      },
      updateContentPageData: async (ctx, evt) => {
        try {
          if (!evt.payload.contentPageData) return
          const documentORM = ctx.getDocumentORM()
          const { latestDocumentVersionORM } = documentORM.relations.version
          const pagesData = evt.payload.contentPageData?.map((pageData) => {
            return {
              number: pageData.presentationPageNumber,
              title: pageData.title,
              speakerNotes: pageData.speakerNotes,
              mapping: pageData.mapping,
              enableSlideTextInsertion: pageData.enableSlideTextInsertion,
            }
          })
          await API.graphql(
            graphqlOperation(updateDocumentPageData, {
              input:{
                documentId: latestDocumentVersionORM.model.documentId,
                documentVersionId: latestDocumentVersionORM.model.id,
                pagesData : pagesData,
              },
            }),
          );
        }
        catch (e) {
          console.warn('Error when updating page data', e)
          throw e
        }
      },
      publishDocument: async (ctx) => {
        const { latestDocumentVersionORM } = ctx.getDocumentORM().relations.version

        const { data } = await API.graphql<GraphQLQuery<PublishDocumentMutation>>(
          graphqlOperation(publishDocument, {
            documentId: latestDocumentVersionORM.model.id,
          }));

        // TODO: add validation depending code backend status
        if (!data?.publishDocument) {
          const errorText = 'Error when publishing document'
          logger.versioning.warn(errorText, { documentVersionId: latestDocumentVersionORM.model.id })
          throw new Error(errorText)
        }
      },
    },
  },
)

export default publisherVersioningSM
