import { useEffect, useMemo } from 'react';
import type { InterpreterFrom, AnyStateMachine } from 'xstate';
import { Logger } from '@aws-amplify/core';
import { useAppSettings } from '../context/AppSettings';

export function getStatePath(id: string, currentHistoryValue: any): string {
  const path: string[] = [];

  function traverse(currentObj: any) {
    if (typeof currentObj === 'string') {
      path.push(currentObj); // Add the leaf node to the path
      return;
    }

    if (typeof currentObj === 'object') {
      const keys = Object.keys(currentObj);
      keys.forEach(key => {
        path.push(key); // Add the key to the path
        traverse(currentObj[key]); // Recurse into the nested object
      });
    }
  }

  traverse(currentHistoryValue); // Start traversal from `current`
  return `${id}.` + path.join('.'); // Return the dot-separated path
}

export function useStateMachineLogger(
  service: InterpreterFrom<AnyStateMachine>,
  logger: Logger,
) {
  const { debugStateMachines } = useAppSettings();
  const machineId = service.machine.id ?? 'StateMachine';
  const enableLogging = useMemo(() => debugStateMachines[machineId] ?? false, [debugStateMachines])

  useEffect(() => {
    if (enableLogging) {
      logger.debug(machineId, 'starting subscription');

      const subscription = service.subscribe(state => {
        logger.debug(machineId, {
          event: state.event.type,
          ...(state.transitions?.reduce(
            (acc, transition, idx) => {
              acc[`transition${idx}`] = {
                from: transition.source.id,
                to: transition.target?.map(target => target.id).join() ?? 'No Target',
                actions: transition.actions,
              };
              return acc;
            }, {})),
          value: getStatePath(machineId, state.historyValue?.current),
          payload: state.event,
          ctx: state.context,
          tags: state.tags,
          meta: state.meta,
        });
      });

      return () => subscription.unsubscribe();
    }
  }, [service, logger, enableLogging]);
}
