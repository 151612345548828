import React, { useCallback, useMemo } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { DNABox, util } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import {
  MATCH_SLIDE_STATUS,
  usePublisherVersioningState,
} from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import {
  SLIDE_GROUP_TARGET_CONTAINER_WIDTH_SMALL,
  useMatchSlidesState,
} from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import { POOL_CONTAINER_ID, useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';
import { DndMonitorListener, useDndMonitor } from '@dnd-kit/core';
import { DataProvider, LayoutProvider, RecyclerListView, RecyclerListViewProps } from 'recyclerlistview';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
// COMPONENT
import Header from './Header';
import RecommendedConfirmation from './RecommendedConfirmation';
import DuplicateWarning from './DuplicateWarning';
import SlideMatchingIcon from './SlideMatchingIcon';
import PreviousVersionContainer from './PreviousVersionContainer';
import NewVersionContainer from './NewVersionContainer';

const S = StyleSheet.create({
  recyclerListView: {
    flex: 1,
    paddingVertical: 24,
  },
  slideGroupTargetsContainer: {
    minWidth: SLIDE_GROUP_TARGET_CONTAINER_WIDTH_SMALL,
  },
})

interface SlideGroupTargetsRowType {
  page: Page,
  index: number
}

enum ItemType {
  DEFAULT,
  WITH_BANNER,
  NONE,
}

// Renderless component that minimizes rerenders due to chatty over event
const GroupHoverTrigger = React.memo(() => {
  const { activeContainerOrigin } = useSingleItemDnd();
  const { setOverContainer } = useMatchSlidesState();

  const listeners = useMemo<DndMonitorListener>(
    () => ({
      onDragOver(event) {
        const isFromPool = activeContainerOrigin.current === POOL_CONTAINER_ID
        const overContainer = event.over?.data.current?.containerId || null
        if (isFromPool) setOverContainer(overContainer)
      },
      onDragEnd() {
        setOverContainer(null)
      },
    }),
    [setOverContainer],
  )

  useDndMonitor(listeners)

  return null
})

const SlideGroupTargetsRow: React.FC<SlideGroupTargetsRowType> = ({ page, index }) => {
  const { matchSlideStates } = usePublisherVersioningState();

  const needsReview = matchSlideStates[page.pageId]?.status === MATCH_SLIDE_STATUS.NEEDS_REVIEW;
  return (
    <DNABox testID="slide-group-container" fill appearance="col">
      <DNABox fill appearance="col" alignX="center">
        <RecommendedConfirmation pageId={page.pageId} />
        <DuplicateWarning pageId={page.pageId} />
        <DNABox appearance="row" spacing="md" style={util.mergeStyles(undefined, [{ marginTop: 24 }, !needsReview])}>
          <PreviousVersionContainer pageId={page.pageId} index={index} newPageNumber={page.number} />
          <SlideMatchingIcon pageId={page.pageId} />
          <NewVersionContainer pageId={page.pageId} newPageNum={page.number} />
        </DNABox>
      </DNABox>
      <GroupHoverTrigger />
    </DNABox>
  )
}

const SlideGroupTargets: React.FC = () => {
  const {
    matchSlideStates,
    latestContentPageData,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const { slideGroupTargetRef } = useMatchSlidesState()
  const dimensions = useWindowDimensions()
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const dataProviderInit = useMemo(() => {
    return new DataProvider((r1: string, r2: string) => {
      return r1 !== r2;
    });
  }, []);

  const dataProvider = useMemo(() => {
    return dataProviderInit.cloneWithRows(currentDocumentVersionORM.relations.pages)
  }, [currentDocumentVersionORM]);

  const layoutProvider = useMemo(() => {
    const { width: containerWidth } = dimensions;
    const { height: thumbnailHeight } = isLargeScreen ? thumbnailSizeDimensions.lg : thumbnailSizeDimensions.md;

    const _layoutProvider = new LayoutProvider((idx) => {
      if (!latestContentPageData?.length) return ItemType.NONE;
      const pageId = latestContentPageData[idx]?.pageId;
      if (matchSlideStates[pageId]?.status === MATCH_SLIDE_STATUS.NEEDS_REVIEW) return ItemType.WITH_BANNER;
      else return ItemType.DEFAULT;
    },
    (type, dim, _idx) => {
      switch (type) {
        case ItemType.DEFAULT: {
          const extraPadding = 90;
          dim.width = containerWidth;
          dim.height = extraPadding + thumbnailHeight;
          break;
        }
        case ItemType.WITH_BANNER: {
          const extraPadding = 90;
          const bannerAndMerginHeight = 45 + 16;
          dim.width = containerWidth;
          dim.height = extraPadding + bannerAndMerginHeight + thumbnailHeight;
          break;
        }
        case ItemType.NONE: {
          dim.width = 0;
          dim.height = 0;
          break;
        }
      }
    },
    );

    // Disable refresh with anchoring
    _layoutProvider.shouldRefreshWithAnchoring = false;
    return _layoutProvider;
  }, [dimensions.width, isLargeScreen, matchSlideStates, latestContentPageData]);

  const rowRenderer = useCallback<RecyclerListViewProps['rowRenderer']>(
    (type, data, idx) => {
      const { model } = data;
      const page = model as Page
      return (
        <SlideGroupTargetsRow index={idx} page={page} key={page.pageId} />
      )
    },
    [layoutProvider, dataProvider, latestPublishedDocumentVersionORM])

  return (
    <DNABox fill appearance="col" style={S.slideGroupTargetsContainer}>
      {/* HEADER SECTION */}
      <Header />
      <RecyclerListView
        key={latestPublishedDocumentVersionORM.model.id}
        layoutProvider={layoutProvider}
        dataProvider={dataProvider}
        rowRenderer={rowRenderer}
        style={S.recyclerListView}
        canChangeSize
        ref={slideGroupTargetRef}
      />
    </DNABox>
  )
}

export default SlideGroupTargets;
