import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, Pressable, ScrollView, StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAChip,
  DNADivider,
  DNASelect,
  DNAText,
  Hoverable,
  Icon,
  Iffy,
  IndexPath,
  TextField,
} from '@alucio/lux-ui';
import { IFieldComponentProps } from './ComposableField';
import { ComposableVariant, useComposableForm } from './ComposableForm';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { OverflowMenu, Input } from '@ui-kitten/components';
import { useActor } from '@xstate/react';
import { useCRMAccountSearch, useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { useAppSettings } from 'src/state/context/AppSettings';
import {
  Attendee,
  AttendeeType,
  CrmIntegrationType,
  CrmSyncStatus,
  CustomFieldDefinition,
  MeetingAttendeeStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { v4 as uuid } from 'uuid';
import { validEmail } from 'src/utils/emailValidation';
import { orderArray } from 'src/utils/arrayHelpers';
import { CRMAccountORM, CRMAddressORM, MeetingORM } from 'src/types/orms';
import DNAPopover from '../DNA/Popover/DNAPopover';
import { useUserTenant } from 'src/state/redux/selector/user';
import { CRMSyncStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { SUPPORTED_ZVODS } from 'src/classes/CRM/Translators/VeevaMarkerHandler';

export const styles = StyleSheet.create({
  attendeeRow: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  rowsWrapper: {
    borderRadius: 3,
    borderWidth: 1,
  },
  searchIcon: {
    color: colors['color-gray-300'],
    width: 17.5,
    height: 17.5,
  },
  searchResultsBox: {
    paddingHorizontal: 8,
    paddingVertical: 14,
    backgroundColor: colors['color-gray-10'],
  },
  // since the popover is not taking
  // the full width of the screen, we need to set the width of the search input
  searchSize: {
    width: 850,
    maxHeight: 200,
  },
  searchInputResult :{
    backgroundColor: colors['color-text-basic'],
    flex: 1,
    minHeight: 52,
    borderColor: colors['color-gray-10'],
    borderWidth: 1,
  },
  hoveredRow: {
    backgroundColor: colors['color-gray-10'],
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
})

// THE IDEA OF THIS FUNCTION IS TO AVOID CRM ACCOUNT BEING DUPLICATED
export const useGetExistingrCmAttendeeIds = (): string[] => {
  const { rhForm: { watch } } = useComposableForm()

  const primaryAttendee = watch('primaryAttendee');
  const additionalAttendees = watch('additionalAttendees');

  const castedPrimaryAttendee = primaryAttendee
    ? Array.isArray(primaryAttendee)
      ? primaryAttendee
      : [primaryAttendee]
    : []

  const castedAdditionalAttendees = additionalAttendees
    ? Array.isArray(additionalAttendees)
      ? additionalAttendees
      : [additionalAttendees]
    : []

  const attendees: Attendee[] = [...castedPrimaryAttendee, ...castedAdditionalAttendees] as unknown as Attendee[];
  return attendees.reduce<string[]>((acc, { crmAccountId }) => {
    crmAccountId && acc.push(crmAccountId);
    return acc;
  }, []);
}

type Props = IFieldComponentProps<
  Attendee[],
  {
    variant: ComposableVariant,
    meetingORM?: MeetingORM,
    status?: MeetingAttendeeStatus
    customFields?: CustomFieldDefinition[],
  }
>

export interface PartialNewAttendee {
  name: string,
  crmAccountId?: string,
  crmAddressId?: string,
}

const AttendeeInput: React.FC<Props> = (props) => {
  const defaultValue = []
  const { field, value, onChange, status, isReadOnly } = props;
  const isPrimary = field.id === 'primaryAttendee';
  const isVirtual = props.variant === ComposableVariant.VIRTUAL;
  const attendees = useMemo(() => orderArray<Attendee>(value
    ?.filter(p => p.status !== MeetingAttendeeStatus.REMOVED && p.id !== SUPPORTED_ZVODS.BUSINESS_ACCOUNT) ??
      defaultValue, ['name']), [value]);
  const primaryAttendee = attendees.find((attendee) => attendee.attendeeType === AttendeeType.PRIMARY);

  const handleOnChange = (updatedAttendee: Attendee): void => {
    if (!value) {
      onChange([updatedAttendee])
      return;
    }

    onChange(value?.map((attendee) => attendee.id === updatedAttendee.id ? updatedAttendee : attendee));
  }

  const handleOnDelete = (id: string) => (): void => {
    if (!value) {
      onChange([])
      return
    }

    onChange(value.filter((attendee) => attendee.id !== id));
  }

  const onAddNewAttendee = (partialAttendee: PartialNewAttendee, existingAttendeeId?: string): void => {
    const attendee: Attendee = {
      id: uuid(),
      name: partialAttendee.name.trim(),
      status: MeetingAttendeeStatus.ACTIVE,
      crmAccountId: partialAttendee.crmAccountId,
      crmAddressId: partialAttendee.crmAddressId,
      updatedAt: new Date().toISOString(),
      attendeeType: isPrimary ? AttendeeType.PRIMARY : AttendeeType.SECONDARY,
    };

    if (existingAttendeeId) {
      onChange(value?.reduce<Attendee[]>((acc, att) => {
        if (att.id === existingAttendeeId) {
          acc.push({
            ...attendee,
            id: existingAttendeeId,
          });
        } else {
          acc.push(att);
        }
        return acc;
      }, []));
    } else {
      onChange([attendee, ...(value ?? defaultValue)]);
    }
  }

  const handleOnUpdateAddress = (address: CRMAddressORM, attendee: Attendee): void => {
    handleOnChange({
      ...attendee,
      crmAddressId: address.model.id,
    });
  }

  const displaySearchInput = (!isPrimary || (isPrimary && attendees.length === 0)) && !isReadOnly;

  return (
    <DNABox appearance="col" spacing="md">
      <Iffy is={displaySearchInput}>
        <SearchEnterContactInput
          status={status}
          isPrimary={isPrimary}
          customFields={props.customFields}
          onNewAttendee={onAddNewAttendee}
        />
      </Iffy>
      <Iffy is={attendees.length}>
        <DNABox
          appearance="col"
          style={[styles.rowsWrapper, {
            borderColor: isVirtual ? colors['color-gray-500'] : colors['color-gray-80'],
          }]}
        >
          {attendees.map((attendee, idx) => (
            <DNABox appearance="col" key={attendee.id}>
              <AttendeeRow
                variant={props.variant}
                attendee={attendee}
                onDelete={handleOnDelete}
                onUpdate={handleOnChange}
                onAddNewAttendee={onAddNewAttendee}
                meetingORM={props.meetingORM}
                isReadOnly={isReadOnly}
                isPrimary={isPrimary}
              />
              <Iffy is={idx + 1 < attendees.length}>
                <DNADivider variant={isVirtual ? 'virtual' : 'default'} />
              </Iffy>
            </DNABox>
          ),
          )}
        </DNABox>
      </Iffy>
      <Iffy is={!attendees.length && isReadOnly}>
        <DNABox appearance="col" spacing="md">
          <DNAText
            style={{
              color: colors['color-gray-300'],
              textAlign: 'center',
            }}
          >
            {isPrimary ? 'No primary attendee' : 'No additional attendees'}
          </DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={isPrimary && primaryAttendee}>
        <DNABox appearance="col" spacing="md">
          <SelectAddressComponent
            attendee={primaryAttendee!}
            isReadOnly={isReadOnly}
            onChange={(address) => handleOnUpdateAddress(address, primaryAttendee!)}
            isVirtual={isVirtual}
          />
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

interface AttendeeRowProps {
  attendee: Attendee,
  onDelete: (id: string) => () => void
  onAddNewAttendee: (partialAttendee: PartialNewAttendee, existingAttendeeId?: string) => void,
  isReadOnly?: boolean,
  meetingORM?: MeetingORM,
  onUpdate: (attendee: Attendee) => void
  variant?: ComposableVariant,
  isPrimary: boolean,
}

type CRMAttendeeAddressProps = {
  accountId: string
}

export function CRMAttendeeAddress(props: CRMAttendeeAddressProps) {
  const { accountId } = props;
  const { getAccountById } = useCRMAccountSearch();
  const [account, setAccount] = useState<CRMAccountORM | undefined>(undefined);
  const addresses = account?.relations.addresses?.map((address) => address.meta.formattedName).join(' | ');

  useEffect(() => {
    if (accountId) {
      getAccountById(accountId).then((account) => setAccount(account));
    }
  }, [accountId])

  return (
    <DNAText numberOfLines={1} c1 status="subtle" >
      {addresses}
    </DNAText>
  )
}

function AttendeeRow(props: AttendeeRowProps) {
  const { onDelete, attendee, variant = ComposableVariant.DEFAULT, onUpdate, isPrimary, isReadOnly } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { crmIntegrationType } = useCRMStatus();
  const isCRMUser = !!attendee.crmAccountId;
  const isVirtual = variant === ComposableVariant.VIRTUAL;
  const isCRMDeleteDisabled = isPrimary && isCRMUser &&
    props.meetingORM?.model.crmRecord?.crmSyncStatus === CrmSyncStatus.SYNCED &&
    crmIntegrationType === CrmIntegrationType.VEEVA;

  const onToggleEditing = (): void => {
    setIsEditing((editing) => !editing);
  };

  const handleOnUpdate = (attendee: Attendee): void => {
    onUpdate(attendee);
    setIsEditing(false);
  };

  return (
    <DNABox
      style={[styles.attendeeRow, {
        backgroundColor: isVirtual ? colors['color-gray-500'] : colors['color-text-white'],
      }]}
    >
      <Iffy is={!isEditing}>
        <DNABox alignY="center" fill>
          <DNABox alignY="center" fill>
            <DNABox appearance="col" spacing="sm" fill>
              <DNAText bold status={isVirtual ? 'basic' : 'dark'} numberOfLines={1}>
                {attendee.name}
              </DNAText>
              {
              attendee.email &&
                <DNAText status={isVirtual ? 'basic' : 'flatAlt'}>
                  {attendee.email}
                </DNAText>
            }
              <Iffy is={isCRMUser && isPrimary}>
                <CRMAttendeeAddress accountId={attendee.crmAccountId!} />
              </Iffy>
            </DNABox>
            <DNABox spacing="sm" alignY="center">
              <Iffy is={isCRMUser}>
                <CRMAccountBadge crmAccountId={attendee.crmAccountId!} isMeetingForm />
              </Iffy>
              <Iffy is={!isCRMUser && !isReadOnly}>
                <DNAButton
                  onPress={onToggleEditing}
                  appearance="ghost"
                  status="gray"
                  iconLeft="pencil"
                  size="md"
                  padding="xs"
                />
              </Iffy>
              <Iffy is={!isReadOnly}>
                <DNAPopover disablePopover={!isCRMDeleteDisabled}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      disabled={isCRMDeleteDisabled}
                      onPress={onDelete(attendee.id)}
                      appearance="ghost"
                      status="gray"
                      iconLeft="trash-can-outline"
                      size="md"
                      padding="xs"
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">
                      Primary attendee cannot be removed after meeting has been saved to CRM
                    </DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </Iffy>
            </DNABox>
          </DNABox>
        </DNABox>
      </Iffy>
      <Iffy is={isEditing && !isReadOnly}>
        <EditAttendee
          onAddNewAttendee={props.onAddNewAttendee}
          attendee={attendee}
          onUpdate={handleOnUpdate}
          setIsEditing={setIsEditing}
        />
      </Iffy>
    </DNABox>
  );
}

interface CRMAccountBadgeProps {
  crmAccountId: string;
  isMeetingForm?: boolean;
}

export const CRMAccountBadge = (props: CRMAccountBadgeProps) => {
  const { crmAccountId, isMeetingForm } = props;
  const tenant = useUserTenant();

  const openAccountInCRM = (): void => {
    const instanceUrl = tenant?.config.crmIntegration?.instanceUrl;
    if (instanceUrl && crmAccountId) {
      analytics?.track('CRM_ACCOUNT_OPEN', {
        category: isMeetingForm ? 'MEETING' : 'STANDALONE',
        crmAccountId,
      });
      window.open(`${instanceUrl}/${crmAccountId}`, '_blank');
    } else {
      console.error('Instance URL not defined');
    }
  }

  return (
    <DNAPopover>
      <DNAPopover.Anchor>
        <Pressable onPress={openAccountInCRM}>
          <DNAChip
            appearance="subtle"
            status="basic"
            size="sm"
          >CRM</DNAChip>
        </Pressable>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="flatAlt" style={styles.tooltipText}>
          Open account in CRM
        </DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );
};

interface EditAttendeeProps {
  attendee: Attendee,
  onAddNewAttendee: (partialAttendee: PartialNewAttendee, existingAttendeeId?: string) => void,
  onUpdate: (attendee: Attendee) => void,
  setIsEditing: (isEditing: boolean) => void,
}

function EditAttendee(props: EditAttendeeProps) {
  const { attendee, onAddNewAttendee, onUpdate, setIsEditing } = props;
  const { syncStatus } = useCRMStatus();
  const showCRMAccountSearch = syncStatus === CRMSyncStatus.SYNCED;
  const [name, setName] = useState<string>(attendee.name);
  const [email, setEmail] = useState<string>(attendee.email || '');
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const clearName = (): void => {
    setName('');
  }

  const handleUpdateName = (newName: string): void => {
    setName(newName);
  }

  const handleUpdateEmail = (newEmail: string): void => {
    setEmail(newEmail);
    if (invalidEmail && validEmail.test(newEmail)) {
      setInvalidEmail(false);
    }
  }

  const handleSubmit = (): void => {
    if (email && !validEmail.test(email)) {
      setInvalidEmail(true);
    } else {
      const noChangeMade = (name === attendee.name && (email === attendee.email ||
        (!attendee.email && email === '')));

      onUpdate({
        ...attendee,
        email: email.trim(),
        name: name.trim(),
        updatedAt: noChangeMade ? attendee.updatedAt : new Date().toISOString(),
      });
    }
  }

  const handleAddNewAttendee = (partialAttendee: PartialNewAttendee) => {
    if (!partialAttendee.crmAccountId) {
      const noChangeMade = (partialAttendee.name === attendee.name && (email === attendee.email ||
        (!attendee.email && email === '')));

      onUpdate({
        ...attendee,
        email: email.trim(),
        name: partialAttendee.name.trim(),
        updatedAt: noChangeMade ? attendee.updatedAt : new Date().toISOString(),
      });
    } else {
      onAddNewAttendee(partialAttendee, attendee.id);
      setIsEditing(false);
    }
  };

  return (
    <DNABox spacing="sm" appearance="col" fill>
      { /* FOR CRM TENANTS, CAN REPLACE NON-CRM ATTENDEE WITH A CRM USER */ }
      <Iffy is={showCRMAccountSearch}>
        <SearchEnterContactInput
          isSimplified
          defaultSearchText={name}
          onNewAttendee={handleAddNewAttendee}
        />
      </Iffy>
      { /* NON-CRM: REGULAR INPUT  */ }
      <Iffy is={!showCRMAccountSearch}>
        <TextField.Kitten
          placeholder="Name"
          onChangeText={handleUpdateName}
          value={name}
          multiline={false}
          numberOfLines={1}
          accessoryRight={() => (
            name.length > 0
              ? <DNAButton
                  onPress={clearName}
                  appearance="ghost"
                  status="gray"
                  iconLeft="close-circle"
                  size="md"
                  padding="xs"
              /> : <></>
          )}
        />
      </Iffy>
      <TextField.Kitten
        placeholder="Email"
        onChangeText={handleUpdateEmail}
        value={email}
        multiline={false}
        numberOfLines={1}
      />
      <DNABox spacing="between">
        <DNABox>
          <Iffy is={invalidEmail}>
            <DNAText status="danger">
              Invalid email format.
            </DNAText>
          </Iffy>
        </DNABox>
        <DNAButton
          size="md"
          appearance="filled"
          disabled={!name}
          onPress={handleSubmit}
        >
          Done
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

interface SelectAddressProps {
  attendee: Attendee,
  isReadOnly?: boolean,
  onChange?: (address: CRMAddressORM) => void
  isVirtual?: boolean
}

function SelectAddressComponent(props: SelectAddressProps) {
  const { attendee, onChange, isReadOnly, isVirtual } = props;
  const { crmAccountId:accountId } = attendee;
  const [account, setAccount] = useState<CRMAccountORM | null>(null)
  const { getAccountById } = useCRMAccountSearch()

  const addresses = account?.relations.addresses!;

  const idx = addresses?.findIndex((addr) => addr.model.id === attendee.crmAddressId);
  const selectIdx = new IndexPath(idx > -1 ? idx : 0);
  const address = addresses ? addresses[selectIdx.row] : null;
  const hasAddresses = addresses && addresses.length

  useEffect(() => {
    if (accountId) {
      getAccountById(accountId).then((account) => {
        setAccount(account);
      });
    }
  }, [accountId]);

  if (!account) {
    return null
  }

  const addressSelectedHandler = (index : IndexPath | IndexPath[] ) => {
    const idx = index as IndexPath
    const address = addresses[idx.row]
    onChange && onChange(address)
  }

  return (
    <>
      <Iffy is={!hasAddresses}>
        <DNABox appearance="col" fill spacing="between">
          <DNAText bold>Address</DNAText>
          <DNAText
            status={isVirtual ? 'basic' : 'flatAlt'}
            style={{ marginVertical: 6 }}
          >No addresses were found</DNAText>
        </DNABox>
      </Iffy>

      <Iffy is={hasAddresses}>
        <DNABox appearance="col" fill spacing="between">
          <DNAText bold status={isVirtual ? 'basic' : 'dark'}>Address</DNAText>
          <DNASelect
            style={{ marginVertical: 6 }}
            disabled={isReadOnly}
            onSelect={addressSelectedHandler}
            selectedIndex={selectIdx}
            value={address ? address.meta.formattedName : 'Select Address'}
          >
            {addresses?.map((address) => (
              <DNASelect.Item
                key={address.model.id}
                title={address.meta.formattedName}
              />
            ))}
          </DNASelect>
        </DNABox>
      </Iffy>
    </>
  )
}

interface SearchComponentProps {
  isPrimary?: boolean
  customFields?: CustomFieldDefinition[],
  isSimplified?: boolean
  defaultSearchText?: string
  placeholder?: string
  status?: string
  onNewAttendee: (partialAttendee: PartialNewAttendee, existingAttendeeId?: string) => void
}

const searchInputPlaceHolder = 'Enter name to create new attendee';
const searchAttendeePLaceHolder = 'Search by name';
const searchPlaceHolderCRM = `${searchAttendeePLaceHolder} or 
${searchInputPlaceHolder.charAt(0).toLowerCase() + searchInputPlaceHolder.slice(1)}`;

const useSearchEnterContactInput = (props: SearchComponentProps) => {
  const { defaultSearchText, placeholder, isPrimary, isSimplified, status, onNewAttendee } = props;
  const existingAttendees = useGetExistingrCmAttendeeIds();
  const inputTarget = isPrimary ? 'primary' : 'additional';
  const [searchText, setSearchText] = useState<string>(defaultSearchText || '');
  const [areResultsVisible, setAreResultsVisible] = useState<boolean>(false);
  const { accounts, resetAccountsArray, search } = useCRMAccountSearch()
  const { crmIntegrationType } = useCRMStatus();
  const filteredAccounts = accounts.filter(({ model }) => !existingAttendees.includes(model.id));
  const isManualAddDisabled = useMemo(() => {
    const isVeeva = crmIntegrationType === CrmIntegrationType.VEEVA;
    const hasVeevaAttendeeListField =
      !!props.customFields?.find(({ id }) => id === SUPPORTED_ZVODS.ATTENDEES_LIST);
    return isVeeva && (hasVeevaAttendeeListField || isPrimary);
  }, [props.customFields, isPrimary, crmIntegrationType]);

  const { crmMachine } = useAppSettings()
  const [state] = useActor(crmMachine);
  const isCRMDataAvailable = !!state.context.crmSession?.accessToken;
  const searchPlaceholder = isSimplified
    ? undefined : placeholder || (isCRMDataAvailable
      ? isManualAddDisabled ? searchAttendeePLaceHolder : searchPlaceHolderCRM : searchInputPlaceHolder);

  const clearSearch = (): void => {
    setSearchText('');
    resetAccountsArray();
  }

  const hideSearchResult = (): void => {
    setAreResultsVisible(false);
    resetAccountsArray();
  }

  const onChangeSearchText = (text: string): void => {
    setSearchText(text);
    setAreResultsVisible(text.length > 2);
    if (isCRMDataAvailable && text.length > 2) {
      const isVeeva = crmIntegrationType === CrmIntegrationType.VEEVA;

      if (isVeeva && !isPrimary) {
        search(text, (value) => !!value.IsPersonAccount);
      } else {
        search(text);
      }
    } else {
      resetAccountsArray();
    }
  }

  const onEnterPressed = (): void => {
    if (isManualAddDisabled || searchText.length <= 0 ) {
      return;
    }
    addNonCrmUser();
    setTimeout(() => {
      // Note: not the prettiest solution but there seems to be an issue on how the <OverflowMenu /> component works under
      // the hood and handles the anchor component, causing a ref to it (ideal solution) not being handled properly
      // @ts-ignore
      document.querySelector(`[data-testid="search-${inputTarget}-contact-input"]`)?.focus()
    }, 500)
  }

  const addNonCrmUser = (): void => {
    onNewAttendee({ name: searchText });
    clearSearch();
    setAreResultsVisible(false);
  }

  const onPressAccount = (account: CRMAccountORM): void => {
    onNewAttendee({
      name: account.meta.formattedName,
      crmAccountId: account.model.id as string,
      crmAddressId: account.relations.addresses?.[0]?.model.id || '',
    });
    clearSearch();
    setAreResultsVisible(false);
  }

  const renderSearchInput = () => {
    return (
      <DNABox alignY="start" spacing={isSimplified ? 'between' : 'sm'} childFill={0}>
        <TextField.Kitten
          placeholder={searchPlaceholder}
          onChangeText={onChangeSearchText}
          value={searchText}
          status={status}
          testID={`search-${inputTarget}-contact-input`}
          multiline={false}
          numberOfLines={1}
          onSubmitEditing={onEnterPressed}
          accessoryLeft={() => (
            isCRMDataAvailable && !isSimplified
              ? <Icon style={styles.searchIcon} name="magnify" />
              : <></>
          )}
          accessoryRight={() => (
            searchText.length > 0
              ? <DNAButton
                  onPress={clearSearch}
                  appearance="ghost"
                  status="gray"
                  iconLeft="close-circle"
                  size="md"
                  padding="xs"
              /> : <></>
          )}
        />
        <Iffy is={!isManualAddDisabled && !isSimplified}>
          <DNAButton
            size="md"
            appearance="filled"
            disabled={!searchText}
            onPress={addNonCrmUser}
            testID="attendee-add-button"
          >
            Add
          </DNAButton>
        </Iffy>
      </DNABox>
    );
  }

  return {
    areResultsVisible,
    filteredAccounts,
    hideSearchResult,
    isManualAddDisabled,
    onPressAccount,
    renderSearchInput,
    isCRMDataAvailable,
    isSimplified,
    searchPlaceholder,
    onChangeSearchText,
    searchText,
    inputTarget,
    onEnterPressed,
    clearSearch,
    addNonCrmUser,
  }
}

function SearchEnterContactInputDesktop(props: SearchComponentProps) {
  const {
    areResultsVisible,
    isCRMDataAvailable,
    filteredAccounts,
    hideSearchResult,
    isManualAddDisabled,
    onPressAccount,
    renderSearchInput,
  } = useSearchEnterContactInput(props);

  return (
    <OverflowMenu
      onBackdropPress={hideSearchResult}
      anchor={renderSearchInput}
      visible={areResultsVisible && isCRMDataAvailable}
      placement="bottom start"
      fullWidth={true}
    >
      <SearchResultBox
        isManualAddDisabled={isManualAddDisabled}
        accounts={filteredAccounts}
        onPress={onPressAccount}
      />
    </OverflowMenu>
  );
}

function SearchEnterContactInputTablet(props: SearchComponentProps) {
  const {
    areResultsVisible,
    isCRMDataAvailable,
    filteredAccounts,
    hideSearchResult,
    isManualAddDisabled,
    onPressAccount,
    isSimplified,
    searchPlaceholder,
    onChangeSearchText,
    searchText,
    inputTarget,
    onEnterPressed,
    clearSearch,
    addNonCrmUser,
  } = useSearchEnterContactInput(props);

  const inputRef = React.useRef<Input | null>();

  const handleInputFocus = useCallback(() => {
    if (!inputRef.current) return;
    // eslint-disable-next-line dot-notation
    const textInputRef = inputRef.current?.['textInputRef']
    if (textInputRef) {
      textInputRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [inputRef]);

  return (
    <DNAPopover
      interactive
      placement="bottom-start"
      type="menu"
      onBackdropPress={hideSearchResult}
      visible={areResultsVisible}
    >
      <DNAPopover.Anchor style={{ flex: 1 }}>
        <DNABox alignY="start" spacing={isSimplified ? 'between' : 'sm'} childFill={0}>
          <TextField.Kitten
            placeholder={searchPlaceholder}
            onChangeText={onChangeSearchText}
            onFocus={handleInputFocus}
            ref={(ref) => {
              inputRef.current = ref;
            }}
            value={searchText}
            testID={`search-${inputTarget}-contact-input`}
            multiline={false}
            numberOfLines={1}
            onSubmitEditing={onEnterPressed}
            accessoryLeft={() => (
              isCRMDataAvailable && !isSimplified
                ? <Icon style={styles.searchIcon} name="magnify" />
                : <></>
            )}
            accessoryRight={() => (
              searchText.length > 0
                ? <DNAButton
                    onPress={clearSearch}
                    appearance="ghost"
                    status="gray"
                    iconLeft="close-circle"
                    size="md"
                    padding="xs"
                /> : <></>
            )}
          />
          <Iffy is={!isManualAddDisabled && !isSimplified}>
            <DNAButton
              size="md"
              appearance="filled"
              disabled={!searchText}
              onPress={addNonCrmUser}
              testID="attendee-add-button"
            >
              Add
            </DNAButton>
          </Iffy>
        </DNABox>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        {areResultsVisible && isCRMDataAvailable &&
          <DNABox as={ScrollView} style={styles.searchSize} appearance="col">
            <SearchResultBox
              isManualAddDisabled={isManualAddDisabled}
              accounts={filteredAccounts}
              onPress={onPressAccount}
            />
          </DNABox>
        }
      </DNAPopover.Content>
    </DNAPopover>
  );
}

export const SearchEnterContactInput = (props: SearchComponentProps) => {
  const { isPWAStandalone } = useAppSettings();

  return !isPWAStandalone ? SearchEnterContactInputDesktop(props) : SearchEnterContactInputTablet(props);
}

function SearchInputResult(props: {
  account : CRMAccountORM,
  onPress: (account: CRMAccountORM) => void
}) {
  const { account, onPress } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const addresses = !account.relations.addresses?.length
    ? ['No address available']
    : (account.relations.addresses).map(({ meta }) => meta.formattedName).join('| ');

  function toggleHover(): void {
    setIsHovered(currVal => !currVal);
  }

  function onPressAccount(): void {
    onPress(account);
  }

  return (
    <Pressable
      onPress={onPressAccount}
      style={[styles.searchInputResult, isHovered && styles.hoveredRow]}
    >
      <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
        <DNABox
          style={{ padding: 8 }}
          appearance="col"
          spacing="between"
          fill
        >
          <DNAText c1 bold numberOfLines={1}>{account.meta.formattedName}</DNAText>
          <DNAText c2 status="subtle">{addresses}</DNAText>
        </DNABox>
      </Hoverable>
    </Pressable>
  );
}

function SearchResultBox(props : {
  accounts: CRMAccountORM[],
  isManualAddDisabled?: boolean,
  onPress: (account: CRMAccountORM) => void,
}) {
  const { accounts, isManualAddDisabled, onPress } = props
  return (
    <>
      { /*  LIST OF ACCOUNTS */ }
      <Iffy is={accounts.length}>
        <FlatList<CRMAccountORM>
          style={{ maxHeight: 300 }}
          data={accounts}
          keyExtractor={(item) => item.model.id}
          renderItem={(row) =>
            (<SearchInputResult
              account={row.item}
              key={row.item.model.id}
              onPress={onPress}
            />)
            }
        />
      </Iffy>
      <Iffy is={!accounts.length}>
        <DNABox style={styles.searchResultsBox} fill>
          <DNAText status="flat">
            No users found. { `${!isManualAddDisabled ? 'Press “Enter” key to add this user to the list below.' : ''}` }
          </DNAText>
        </DNABox>
      </Iffy>
    </>
  );
}

export default AttendeeInput;
