import { createStore } from 'src/utils/analytics/queueIdb';

// [TODO]: VERIFY THE RETENTION CONFIG
export const retentionConfig = { maxNumber: 250, batchEvictionNumber: 100 };
export const withStore = createStore('analytics', 'accessedRecords', 'key');
export * from 'src/utils/analytics/queueIdb';
export * from '../observables';

export type AnalyticsErrorName =
  | 'UNKNOWN_ERROR'
  | 'USER_NOT_FOUND'

export type ErrorSettings = {
  name: AnalyticsErrorName,
  message: string,
  subMessage?: string,
}

export class AnalyticsError extends Error {
  name: AnalyticsErrorName;
  message: string;
  subMessage?: string;
  cause?: any;

  constructor(err: AnalyticsErrorName, cause?: any)
  constructor(
    err: unknown,
    cause?: any,
  ) {
    super()
    const parsed = this.parseError(err)

    this.name = parsed.name
    this.message = parsed.message
    this.subMessage = parsed.subMessage
    this.cause = cause
  }

  parseError(err: any): ErrorSettings {
    const appSyncError = typeof err === 'string'
      ? { message: err }
      : err?.errors?.at(0)

    switch (appSyncError?.message) {
      case 'NOT_FOUND': {
        return {
          name: 'USER_NOT_FOUND',
          message: 'Cannot find session user',
        }
      }
      default: {
        return {
          name: appSyncError?.message ?? 'UNKNOWN_ERROR',
          message: 'An unknown error has occured',
        }
      }
    }
  }
}
