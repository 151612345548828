/** Check out this flow chart for Analytics machines
 * https://www.figma.com/board/I6r1u7mwjIFyNZdvW6Vgqz/Analytics-State-Machine?node-id=0-1&p=f&t=lFWP7tqNpiCh4w3l-0
 */
import { v4 as uuid } from 'uuid';
import { createMachine, spawn } from 'xstate';
import { assign } from '@xstate/immer';
import { Auth } from '@aws-amplify/auth';
import { MostAccessedRecordsOutput, UserSettings } from '@alucio/aws-beacon-amplify/src/models';
import { graphqlOperation, GraphQLQuery } from '@aws-amplify/api';
import { getMostAccessedRecords } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { GetMostAccessedRecordsQuery } from '@alucio/aws-beacon-amplify/src/API';
import { store } from 'src/state/redux';
import { userSettingsActions } from 'src/state/redux/slice/userSetting';
import ActiveUser from 'src/state/global/ActiveUser';
import * as AnalyticsTypes from './analytics.types';
import {
  AnalyticsError,
  peekAll,
  userSettingsObservable,
  analyticsObservable,
  withStore,
  push,
  retentionConfig,
  shiftAll,
  authActor,
} from './util';
import * as logger from 'src/utils/logger';
import graphqlClient from 'src/utils/graphqlClient';

const INITIAL_ANALYTICS_DATA = {
  personal: {
    mostAccessedDocuments: [],
    recentlyAccessedDocuments: [],
    recentlyAccessedFolders: [],
  },
};

export const analyticsSM = createMachine(
  {
    id: 'AnalyticsSM',
    tsTypes: {} as import('./analytics.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as AnalyticsTypes.SMContext,
      events: {} as AnalyticsTypes.SMEvents,
      services: {} as AnalyticsTypes.SMServices,
    },
    context: {
      currentUserId: undefined,
      localAnalytics: [],
      analyticsToBatchStore: [],
      userSettings: undefined,
      mostAccessedRecordsFromLambda: undefined,
      analyticsData: INITIAL_ANALYTICS_DATA,
      authActor: undefined,
      userSettingsObservableActor: undefined,
      analyticsObservableActor: undefined,
      err: undefined,
    },
    initial: 'preparingMachine',
    states: {
      preparingMachine: {
        after: {
          0: {
            target: 'determine',
            actions: 'spawnAuthActor',
          },
        },
      },
      determine: {
        description: 'Check if user has signed in or not.',
        always: [
          {
            cond: 'isSignedIn',
            target: 'getUserData',
          },
          {
            target: 'idle',
          },
        ],
      },
      idle: {
        description: 'Waiting for user to sign in.',
        on: {
          USER_SIGNED_IN: {
            target: 'getUserData',
          },
        },
      },
      getUserData: {
        invoke: {
          src: 'getUserData',
          onDone: {
            actions: 'setCurrentUserId',
            target: 'ready',
          },
          onError: {
            actions: 'setErr',
            target: 'idle',
          },
        },
      },
      ready: {
        initial: 'processing',
        states: {
          processing: {
            tags: [AnalyticsTypes.StateTags.MACHINE_READY],
            type: 'parallel',
            states: {
              eventListener: {
                after: {
                  0: {
                    actions: [
                      'setInitialRecentlyAccessedData',
                      'spawnUserSettingsObservableActor',
                      'spawnAnalyticsObservableActor',
                    ],
                  },
                },
                on: {
                  UPDATE_CONTEXT_USER_SETTINGS: {
                    description: 'Should be sent by the observable and used to update the UserSettings in context.',
                    actions: ['setContextUserSettings', 'updateContextAnalyticsData'],
                  },
                  ANALYTICS_ACTION_TRIGGERED: {
                    description: 'Processes an analytic action, preparing data and store it in local machine.',
                    cond: 'isValidAnalyticAction',
                    actions: 'updateAnalyticsFeed',
                    target: 'indexedDB.store',
                  },
                  RETRIEVE_ATHENA_RECORD: {
                    description: 'Trigger the lambda call to retrieve the Athena record.',
                    target: 'fetchAthenaRecord.checkingOnlineStatus',
                  },
                },
              },
              indexedDB: {
                initial: 'retrieve',
                states: {
                  idle: {
                    description: 'Waiting for an anction...',
                  },
                  store: {
                    invoke: {
                      description: 'Store events in analyticsToBatchStore to indexedDB.',
                      src: 'storeUserAnalyticsToIndexedDB',
                      onDone: {
                        actions: 'clearAnalyticsToBatchStore',
                        target: 'idle',
                      },
                      onError: {
                        actions: 'setErr',
                        target: 'idle',
                      },
                    },
                  },
                  retrieve: {
                    invoke: {
                      description: 'Sets(overwrite) localAnalytics by checking the data in indexedDB',
                      src: 'getUserAnalyticsFromIndexedDB',
                      onDone: {
                        actions: ['setIndexedDBDataAsLocalAnalytics', 'mergeAthenaRecordWithLocalData'],
                        target: 'idle',
                      },
                      onError: {
                        actions: 'setErr',
                        target: 'idle',
                      },
                    },
                  },
                  clear: {
                    invoke: {
                      description: 'Clear indexedDB local data',
                      src: 'clearAnalyticsIndexedDB',
                      onDone: {
                        target: 'idle',
                      },
                      onError: {
                        actions: 'setErr',
                        target: 'idle',
                      },
                    },
                  },
                },
              },
              fetchAthenaRecord: {
                initial: 'checkingOnlineStatus',
                states: {
                  checkingOnlineStatus: {
                    always: [
                      { cond: 'isOnline', target: 'retrieveAthenaRecord' },
                      { target: 'retryAfterDelay' },
                    ],
                  },
                  retrieveAthenaRecord: {
                    description: 'call lambda function to retrieve Athena data.',
                    invoke: {
                      src: 'retrieveAthenaRecord',
                      onDone: {
                        actions: ['storeAthenaRecordInContext', 'mergeAthenaRecordWithLocalData'],
                        target: 'scheduled',
                      },
                      onError: {
                        actions: 'setErr',
                        target: 'retryAfterDelay',
                      },
                    },
                  },
                  scheduled: {
                    after: {
                      NEXT_UPDATE_DELAY: 'checkingOnlineStatus',
                    },
                  },
                  retryAfterDelay: {
                    after: {
                      RETRY_DELAY: 'checkingOnlineStatus',
                    },
                  },
                },
              },
            },
            on: {
              USER_SIGNING_OUT: {
                target: 'signingOut',
              },
              USER_SIGNED_OUT: {
                target: 'cleanUp',
              },
            },
          },
          signingOut: {
            description: 'Transition state to avoid interacting with indexedDB while signing out.',
            on: {
              USER_SIGNED_OUT: {
                target: 'cleanUp',
              },
            },
          },
          cleanUp: {
            always: {
              description: 'We clear the indexedDB data in the logout function.',
              actions: 'clearOutContextData',
              target: '#AnalyticsSM.idle',
            },
          },
        },
      },
    },
  }, {
    delays: {
      NEXT_UPDATE_DELAY: (ctx) => {
        const now = Date.now();
        const nextAthenaRecordRetrievalTime = ctx.nextAthenaRecordRetrievalTime ?? 1;
        const nextLambdaCall = Math.max(0, nextAthenaRecordRetrievalTime - now);
        logger.analytics.machine.info(`Next lambda call in ${nextLambdaCall / 60000} minutes`);
        return nextLambdaCall || 5 * 60 * 1000;
      },
      RETRY_DELAY: 5 * 60 * 1000, // (5 minutes)
    },
    guards: {
      isSignedIn: () => {
        logger.analytics.machine.info(`Checking if the user is signedIn: ${ActiveUser?.isSet()}`);
        return ActiveUser?.isSet();
      },
      isValidAnalyticAction: (ctx, evt) => {
        const validAnalyticEvents = [
          ...AnalyticsTypes.ACCESSED_DOCUMENT_EVENTS,
          ...AnalyticsTypes.ACCESSED_FOLDER_EVENTS,
        ];
        const eventName = evt.payload[0];
        const eventPayload = evt.payload[1];

        return !!(
          ctx.currentUserId &&
          validAnalyticEvents.includes(eventName) &&
          typeof eventPayload.action === 'string' &&
          (eventPayload.documentId || eventPayload.folderId) &&
          !eventPayload.customDeckId
        )
      },
      isOnline: () => {
        return navigator.onLine
      },
    },
    actions: {
      /** SPAWN ACTORS */
      spawnAuthActor: assign((ctx) => {
        if (!ctx.authActor) {
          logger.analytics.machine.info('Spawning auth actor...');
          ctx.authActor = spawn(authActor, 'analyticsSM.authActor');
        }
      }),
      spawnUserSettingsObservableActor: assign((ctx) => {
        if (!ctx.userSettingsObservableActor) {
          logger.analytics.machine.info('Spawning userSettings observable actor...');
          ctx.userSettingsObservableActor = spawn(
            userSettingsObservable(),
            'userSettingsObservableActor',
          );
        }
      }),
      spawnAnalyticsObservableActor: assign((ctx) => {
        if (!ctx.analyticsObservableActor) {
          logger.analytics.machine.info('Spawning analytics observable actor...');
          ctx.analyticsObservableActor = spawn(
            analyticsObservable(),
            'analyticsObservableActor',
          );
        }
      }),
      /** UPDATE CONTEXT */
      setCurrentUserId: assign((ctx, evt) => {
        logger.analytics.machine.info('Set current userId');
        ctx.currentUserId = evt.data['custom:user_id'];
      }),
      updateAnalyticsFeed: assign((ctx, evt) => {
        logger.analytics.machine.info('Update analytics feed action start...');
        const userSettings = ctx.userSettings;

        if (!ctx.currentUserId) {
          const errorText = 'Cannot find session user in updateAnalyticsFeed';
          logger.analytics.machine.error(errorText);
          ctx.err = new AnalyticsError('USER_NOT_FOUND', errorText);
          return;
        }
        if (!userSettings) {
          const errorText = 'Cannot find userSettings record while trying to dispatch updateAnalyticsFeed';
          logger.analytics.machine.error(errorText);
          ctx.err = new AnalyticsError('UNKNOWN_ERROR', errorText);
          return;
        }

        const now = Date.now();
        const eventPayload = evt.payload[1];
        const entityId = eventPayload.documentId ?? eventPayload.folderId;
        const category = eventPayload.documentId
          ? AnalyticsTypes.ANALYTIC_CATEGORY_ENUM.DOCUMENT
          : eventPayload.folderId
            ? AnalyticsTypes.ANALYTIC_CATEGORY_ENUM.FOLDER
            : undefined;

        if (!entityId || !category) {
          const errorText = 'Bad analytics event';
          logger.analytics.machine.error(errorText, evt);
          ctx.err = new AnalyticsError('UNKNOWN_ERROR', errorText);
          return;
        }

        const dataStorePayloadEvents = [{ entityId, timestamp: new Date(now).toISOString() }];

        const newRecordToBatchStore: AnalyticsTypes.LocalAccessedRecords = {
          userId: ctx.currentUserId,
          category,
          entityId,
          timestamp: `${now}`,
        }

        logger.analytics.machine.info('Dispatch to data store...');
        store.dispatch(userSettingsActions.updateAnalyticsFeed(category, dataStorePayloadEvents));
        logger.analytics.machine.info('Store record to machine context...');
        ctx.analyticsToBatchStore = [...ctx.analyticsToBatchStore, newRecordToBatchStore];
        if (category === AnalyticsTypes.ANALYTIC_CATEGORY_ENUM.DOCUMENT) {
          ctx.localAnalytics = [...ctx.localAnalytics, newRecordToBatchStore];
          logger.analytics.machine.info(`Adds +1 to accessed record [${entityId}]  count...`);
          const documentCountIndex =
            ctx.analyticsData.personal.mostAccessedDocuments.findIndex(({ id }) => id === entityId);
          if (documentCountIndex > -1) {
            ctx.analyticsData.personal.mostAccessedDocuments[documentCountIndex].count += 1;
          } else {
            ctx.analyticsData.personal.mostAccessedDocuments.push({
              id: entityId,
              count: 1,
            });
          }
        }
      }),
      setInitialRecentlyAccessedData: assign((ctx) => {
        logger.analytics.machine.info('Setting initial context analytics data');
        if (ctx.userSettings) {
          const recentlyAccessedDocuments = ctx.userSettings?.analyticsFeed?.documentsViewed?.events ?? [];
          const recentlyAccessedFolders = ctx.userSettings?.analyticsFeed?.foldersViewed?.events ?? [];
          ctx.analyticsData.personal = {
            ...ctx.analyticsData.personal,
            recentlyAccessedDocuments,
            recentlyAccessedFolders,
          }
        }
      }),
      setContextUserSettings: assign((ctx, evt) => {
        logger.analytics.machine.info('Set context userSettings');
        if (evt.payload instanceof UserSettings) {
          ctx.userSettings = evt.payload;
        }
      }),
      updateContextAnalyticsData: assign((ctx, evt) => {
        logger.analytics.machine.info('Update context analytics data');
        switch (evt.type) {
          case 'UPDATE_CONTEXT_USER_SETTINGS': {
            const recentlyAccessedDocuments = ctx.userSettings?.analyticsFeed?.documentsViewed?.events ?? [];
            const recentlyAccessedFolders = ctx.userSettings?.analyticsFeed?.foldersViewed?.events ?? [];
            ctx.analyticsData.personal = {
              ...ctx.analyticsData.personal,
              recentlyAccessedDocuments,
              recentlyAccessedFolders,
            }
          }
        }
      }),
      setIndexedDBDataAsLocalAnalytics: assign((ctx, evt) => {
        logger.analytics.machine.info('Set indexedDB data as local analytics');
        const lambdaDataLastUpdatedTime = ctx.mostAccessedRecordsFromLambda?.dataUpdatedAt
          ? new Date(ctx.mostAccessedRecordsFromLambda?.dataUpdatedAt) : undefined;
        ctx.localAnalytics = evt.data.filter(record => {
          const isRecentData =
            lambdaDataLastUpdatedTime ? new Date(record.timestamp) > lambdaDataLastUpdatedTime : true;
          return isRecentData && record.userId === ctx.currentUserId;
        });
      }),
      storeAthenaRecordInContext: assign((ctx, evt) => {
        logger.analytics.machine.info('Storing Athena record in context');
        ctx.mostAccessedRecordsFromLambda = evt.data;
        ctx.nextAthenaRecordRetrievalTime = new Date(evt.data.nextDataUpdate).getTime();
      }),
      mergeAthenaRecordWithLocalData: assign((ctx) => {
        logger.analytics.machine.info('Merging Athena records with local data');

        const indexedMergedMostAccessedPersonalDocuments: AnalyticsTypes.IndexedRecords = {};

        // ADDS LAMBDA DATA //
        ctx.mostAccessedRecordsFromLambda?.records.documents.personal.forEach((record) => {
          indexedMergedMostAccessedPersonalDocuments[record.entityId] = record.count;
        });
        // ADDS LOCAL DATA //
        ctx.localAnalytics.forEach((record) => {
          if (record.category === AnalyticsTypes.ANALYTIC_CATEGORY_ENUM.DOCUMENT) {
            indexedMergedMostAccessedPersonalDocuments[record.entityId] =
              (indexedMergedMostAccessedPersonalDocuments[record.entityId] || 0) + 1;
          }
        });

        ctx.analyticsData.personal.mostAccessedDocuments =
          Object.keys(indexedMergedMostAccessedPersonalDocuments).map((documentId) => ({
            id: documentId,
            count: indexedMergedMostAccessedPersonalDocuments[documentId],
          }));
      }),
      clearAnalyticsToBatchStore: assign((ctx) => {
        logger.analytics.machine.info('Clearing analytics to batch store');
        ctx.analyticsToBatchStore = [];
      }),
      clearOutContextData: assign((ctx) => {
        logger.analytics.machine.info('Clearing out machine context data');
        ctx.currentUserId = undefined;
        ctx.localAnalytics = [];
        ctx.analyticsToBatchStore = [];
        ctx.userSettings = undefined;
        ctx.mostAccessedRecordsFromLambda = undefined;
        ctx.analyticsData = INITIAL_ANALYTICS_DATA;
        ctx.nextAthenaRecordRetrievalTime = undefined;
        ctx.userSettingsObservableActor?.stop?.();
        ctx.userSettingsObservableActor = undefined;
      }),
      /** OTHER ACTIONS */
      setErr: assign((ctx, evt) => {
        logger.analytics.machine.warn('Analytics machine error', evt.data);
        if (evt.data instanceof AnalyticsError) {
          ctx.err = evt.data
        } else {
          ctx.err = new AnalyticsError('UNKNOWN_ERROR', 'Failed to set in machine')
        }
      }),
    },
    services: {
      getUserData: async (_ctx) => {
        try {
          logger.analytics.machine.info('Retrieving current cognito user...');
          const data = await Auth.currentSession()
          if (!data || !data?.getIdToken) {
            throw new AnalyticsError('UNKNOWN_ERROR', 'Failed to fetch current session')
          }
          return data.getIdToken().payload as AnalyticsTypes.User
        } catch (err: any) {
          throw new AnalyticsError(err)
        }
      },
      storeUserAnalyticsToIndexedDB: async (ctx) => {
        try {
          logger.analytics.machine.info('Storing analytics record to indexedDB...');
          ctx.analyticsToBatchStore.forEach(record => {
            const uniqueKey = `${record.timestamp}_${uuid()}`;
            const itemToStore = {
              key: uniqueKey,
              payload: record,
            }
            push(itemToStore, retentionConfig, withStore);
          })
        } catch (err: any) {
          throw new AnalyticsError(err)
        }
      },
      getUserAnalyticsFromIndexedDB: async () => {
        try {
          logger.analytics.machine.info('Getting analytics record from indexedDB...');
          const accessedRecords = await peekAll<AnalyticsTypes.IndexedDBDataFormat>(withStore);
          return accessedRecords.map(accessedRecord => accessedRecord.payload)
        } catch (e: any) {
          throw new AnalyticsError(e);
        }
      },
      clearAnalyticsIndexedDB: async () => {
        try {
          logger.analytics.machine.info('Clearing analytics record from indexedDB...');
          await shiftAll<AnalyticsTypes.IndexedDBDataFormat>(withStore);
        } catch (e: any) {
          throw new AnalyticsError(e);
        }
      },
      retrieveAthenaRecord: async () => {
        try {
          logger.analytics.machine.info('Start retrieving Athena record...');
          const response = await graphqlClient
            .gracefulQuery<GraphQLQuery<GetMostAccessedRecordsQuery>>(graphqlOperation(getMostAccessedRecords));

          if (!response.data) {
            throw new AnalyticsError('UNKNOWN_ERROR', 'Error getting the most accessed records');
          }
          return response.data.getMostAccessedRecords as MostAccessedRecordsOutput;
        } catch (e: any) {
          throw new AnalyticsError(e);
        }
      },
    },
  });

export default analyticsSM
