import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import {
  DNABox,
  DNAButton,
  DNAContextMenu,
  DNAText,
  Iffy,
  TextField,
} from '@alucio/lux-ui/src';
import { Input } from '@ui-kitten/components';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { useHubRHFromState } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';
import { HubORM } from 'src/types/orms';
import TenantLogo from 'src/components/DNA/Header/TenantLogo';
import NetworkConnectionBanner from 'src/components/NetworkConnection/NetworkConnectionBanner';

type HeaderProps = {
  hubORM: HubORM
}

const styles = StyleSheet.create({
  breadcrumbHeader: {
    height: 48,
    backgroundColor: colors['color-text-white'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    paddingHorizontal: 24,
  },
  chevronIcon: {
    height: 18,
    color: colors['color-text-tertiary'],
    marginHorizontal: 15,
    top: 5,
  },
  hubTitleHeader: {
    height: 64,
    backgroundColor: colors['color-text-white'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    paddingHorizontal: 24,
  },
  hubTitle: {
    marginHorizontal: 30,
  },
  editHubTitleInputBox: {
    width: 500,
  },
  contextMenuItems: {
    minWidth: 160,
    right: 66,
  },
});

const Header: React.FC<HeaderProps> = ({ hubORM }) => {
  const {
    handleCancelEdit,
    handleSaveNCallback,
    handleDeleteHub,
    errorMessages,
    handleFormInvalid,
    openFormInvalidWarningModal,
    openPreviewWindow,
  } = useHubsState()
  const { rhForm, isSaving, setIsSaving } = useHubRHFromState()
  const { isOnline } = useAppSettings()

  const [editNameMode, setEditNameMode] = useState<boolean>(false)
  const [hubName, setHubName] = useState<string>(hubORM.model.name || '')
  const [previousHubName, setPreviousHubName] = useState<string>(hubORM.model.name || '')
  const formattedFormErrors = useMemo(() => [...new Set(errorMessages)].join('\n'), [errorMessages])
  const nameRef = useRef<Input>(null)

  useEffect(() => {
    if (editNameMode) nameRef.current?.focus()
  }, [editNameMode])

  useEffect(() => {
    if (isSaving) {
      const widgetFormValue = getValues('name')
      setEditNameMode(false)
      setHubName(widgetFormValue)
      setPreviousHubName(widgetFormValue)
    }
  }, [isSaving])
  
  const {
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { isDirty, dirtyFields },
  } = rhForm

  const cancelHubNameChange = useCallback(() => {
    setEditNameMode(false)
    setHubName(previousHubName)
    setValue('name', previousHubName)
    trigger(`name`)
  }, [setValue, trigger, previousHubName])

  const onSubmitHubName = useCallback(() => {
    const widgetFormValue = getValues('name')
    if (!widgetFormValue) cancelHubNameChange()
    else {
      setHubName(widgetFormValue)
      setPreviousHubName(widgetFormValue)
      setEditNameMode(false)
    }
  }, [getValues, cancelHubNameChange])

  const cancelEditHub = useCallback(() => {
    handleCancelEdit(!!Object.keys(dirtyFields).length && isDirty)
  }, [rhForm, isDirty, dirtyFields])

  const onSaveNPreview = useCallback((data: any) => {
    setIsSaving(true)

    const previewCallback = () => {
      openPreviewWindow(hubORM?.model.id)
      rhForm.reset({}, { keepValues: true })
    }

    handleSaveNCallback(data, previewCallback)
    setTimeout(() => {
      setIsSaving(false)
    }, 1000)
  }, [rhForm])

  const handleSaveWithDelay = (data: any) => {
    setIsSaving(true)
    setTimeout(() => {
      handleSaveNCallback(data)
      setIsSaving(false)
    }, 500)
  };

  return (
    <DNABox appearance="col">
      <DNABox childFill={1} style={ styles.hubTitleHeader }>
        {/* Tenant LOGO */}
        <TenantLogo disableNav={true} />
        {/* Hub Title */}
        <DNABox childFill={1} fill alignY="center" alignX="center" spacing="sm" style={ styles.hubTitle }>
          {/* Edit Hub Name Mode - Input & Buttons */}
          <DNABox
            fill
            alignY="center"
            alignX="center"
            spacing="sm"
            style={!editNameMode && { display: 'none' }}
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: 'NAME is required', maxLength: 150 }}
              render={() => (
                <TextField.Kitten
                  testID="hub-title-text-edit"
                  ref={nameRef}
                  style={ styles.editHubTitleInputBox }
                  value={hubName}
                  maxLength={150}
                  onChangeText={(text) => {
                    setHubName(text.trimStart())
                    rhForm.setValue(`name`, text.trimStart())
                  }}
                  onKeyPress={(e) => {
                    if (e.nativeEvent.key === 'Enter') onSubmitHubName()
                  }}
                  accessoryRight={() => (
                    hubName.length > 0
                      ? <DNAButton
                          onPress={() => setHubName('')}
                          appearance="ghostLink"
                          status="gray"
                          iconLeft="close-circle"
                          size="md"
                          padding="none"
                      /> : <></>
                  )}
                />
              )}
            />
            <DNAButton
              testID="hub-title-cancel-btn"
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={() => cancelHubNameChange()}
              children="Cancel"
            />
            <DNAButton
              testID="hub-title-done-btn"
              padding="sm"
              onPress={() => onSubmitHubName()}
              children="Done"
            />
          </DNABox>
          {/* Hub Name Read Only Mode - Text & Edit Button */}
          <Iffy is={!editNameMode}>
            <DNABox alignY="center" alignX="center">
              <DNAText
                testID='hub-header-text'
                h4
                numberOfLines={1}
              >
                {hubName}
              </DNAText>
              <DNAButton
                testID="hub-title-text-edit"
                appearance="ghostLink"
                status="gray"
                iconLeft="pencil"
                padding="sm"
                size="md"
                onPress={() => setEditNameMode(true)}
              />
            </DNABox>
          </Iffy>
        </DNABox>
        {/* Action Buttons */}
        <Iffy is={!editNameMode}>
          <DNABox alignX="end" alignY="center" spacing="lg">
            <DNABox alignX="end" alignY="center" spacing="sm">
              <DNAContextMenu style={ styles.contextMenuItems }>
                <DNAContextMenu.Anchor>
                  <DNAButton
                    iconRight="dots-vertical"
                    appearance="outline"
                    status="tertiary"
                    padding="sm"
                  />
                </DNAContextMenu.Anchor>
                <DNAContextMenu.Items>
                  <DNAContextMenu.Item
                    collapseOnPress
                    delay={100} // Workaround because sometimes the item deletes too fast before the ContextMenu can hide itself
                    title="Delete"
                    status="danger"
                    iconStatus="danger"
                    icon="trash-can-outline"
                    onPress={() => handleDeleteHub(hubORM.model, true)}
                  />
                </DNAContextMenu.Items>
              </DNAContextMenu>
              <Iffy is={isOnline}>
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  padding="sm"
                  onPress={handleSubmit(onSaveNPreview, openFormInvalidWarningModal)}
                  disabled={isSaving}
                  testID="edit-hub-preview-btn"
                >
                  Preview
                </DNAButton>
              </Iffy>
            </DNABox>
            <DNABox alignX="end" alignY="center" spacing="sm">
              <DNAButton
                appearance="outline"
                status="tertiary"
                padding="sm"
                onPress={cancelEditHub}
                disabled={isSaving}
                testID="hub-cancel-edit-button"
              >
                Cancel
              </DNAButton>
              <DNAButton
                testID='hub-save-edit-button'
                padding="sm"
                onPress={handleSubmit(handleSaveWithDelay, handleFormInvalid)}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </DNAButton>
            </DNABox>
          </DNABox>
        </Iffy>
      </DNABox>
      {/* Offline Banner */}
      <Iffy is={!isOnline}>
        <NetworkConnectionBanner />
      </Iffy>
      {/* Error Message */}
      <Iffy is={errorMessages.length}>
        <DNABox
          style={{
            padding: 15,
            backgroundColor: colors['color-danger-400'],
          }}
        >
          <DNAText status="basic">
            {formattedFormErrors}
          </DNAText>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

export default Header
