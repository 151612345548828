import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FolderORM } from 'src/types/orms';
import { useAnalyticsState } from 'src/state/machines/analytics/analyticsProvider';
import { useRecentlyAccessedFolders } from 'src/state/redux/selector/folder';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import WidgetContainer from '../WidgetContainer';
import folderQuery from 'src/state/redux/folder/query';

export enum FoldersWidgetVariant {
  RECENTLY_VIEWED = 'Recently Accessed Folders',
}

interface FoldersProps {
  variant: FoldersWidgetVariant,
}

const Folders: React.FC<FoldersProps> = (props) => {
  const history = useHistory();
  const { personal: { recentlyAccessedFolders } } = useAnalyticsState();
  const folderIds = [...new Set(recentlyAccessedFolders.map(({ entityId }) => entityId))];
  const queryOpts = useMemo(() =>
    folderQuery.merge(
      folderQuery.filters.active,
      { filter: { model: { id: folderIds } } }), [folderIds]);

  const items = useRecentlyAccessedFolders(recentlyAccessedFolders, queryOpts);

  const onPress = (folderORM: FolderORM): void => {
    history.push(`/folders/${folderORM.model.id}`)
  };

  return (
    <WidgetContainer title={props.variant}>
      <DNAFlatList
        items={items}
        variant="dashboard"
        onPress={onPress}
        emptyVariant={EmptyVariant.FolderDashBoardEmpty}
      />
    </WidgetContainer>
  );
};

export default Folders;
