import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNADivider, DNASpinner, DNAText, Iffy } from '@alucio/lux-ui';
import { DocumentORM } from 'src/types/orms';
import { useAnalyticsState } from 'src/state/machines/analytics/analyticsProvider';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';
import WidgetContainer from '../WidgetContainer';
import docQuery from 'src/state/redux/document/query';
import { useAccessedDocuments } from 'src/state/redux/selector/document';

export enum DocumentWidgetVariant {
  MOST_VIEWED = 'Most Viewed Documents',
  RECENTLY_VIEWED = 'Recently Viewed Documents',
}

interface Props {
  variant: DocumentWidgetVariant
}

const style = StyleSheet.create({
  loadingWrapper: {
    height: 320,
  },
});

const Documents: React.FC<Props> = ({ variant }) => {
  const documentActions = useDNADocumentActions();
  const {
    hasTheInitialLambdaCallFinished,
    personal: {
      recentlyAccessedDocuments: recentlyAccessedDocumentEvents,
      mostAccessedDocuments: mostAccessedDocumentsCount,
    },
  } = useAnalyticsState();

  const isRecentlyAccessed = variant === DocumentWidgetVariant.RECENTLY_VIEWED;
  const displayLoadingIndicator = !isRecentlyAccessed && !hasTheInitialLambdaCallFinished;
  const documentIds = useMemo(() => {
    const mostAccessedIds = mostAccessedDocumentsCount.map((record) => record.id);
    const recentlyAccessedIds = recentlyAccessedDocumentEvents.map((event) => event.entityId);
    return [...new Set([...mostAccessedIds, ...recentlyAccessedIds])];
  }, [mostAccessedDocumentsCount, recentlyAccessedDocumentEvents]);

  const queryOpts = useMemo(() =>
    docQuery.merge(
      docQuery.filters.published,
      { filter: { model: { id: documentIds } } }), [documentIds]);

  const allDocuments =
    useAccessedDocuments(queryOpts, isRecentlyAccessed, recentlyAccessedDocumentEvents, mostAccessedDocumentsCount);
  const [isViewMoreActive, setIsViewMoreActive] = useState<boolean>(false);
  const displayedItems = useMemo(() => (isViewMoreActive ? allDocuments : allDocuments.slice(0, 5)), [
    isViewMoreActive,
    allDocuments,
  ]);

  const toggleViewMore = () => setIsViewMoreActive((prev) => !prev);

  const handlePressDocument = (documentORM: DocumentORM) => documentActions.preview(documentORM)();

  return (
    <WidgetContainer title={variant}>
      { /* LOADING INDICATOR */ }
      <Iffy is={displayLoadingIndicator}>
        <DNABox
          style={style.loadingWrapper}
          alignX="center"
          alignY="center"
          spacing="lg"
          appearance="col"
          fill
        >
          <DNASpinner />
          <DNAText bold status="flatAlt">Loading...</DNAText>
        </DNABox>
      </Iffy>
      { /* CONTENT */ }
      <Iffy is={!displayLoadingIndicator}>
        <DNABox appearance="col" fill>
          <DNAFlatList
            items={displayedItems}
            variant="dashboard"
            onPress={handlePressDocument}
            emptyVariant={EmptyVariant.DocumentDashboardEmpty}
          />
          <Iffy is={allDocuments.length > 5}>
            <DNABox appearance="col" spacing="md">
              <DNADivider />
              <DNAButton
                appearance="ghost"
                onPress={toggleViewMore}
                padding="xs"
                size="xs"
              >
                { isViewMoreActive ? 'View less' : 'View more' }
              </DNAButton>
            </DNABox>
          </Iffy>
        </DNABox>
      </Iffy>
    </WidgetContainer>
  );
};

export default Documents;
