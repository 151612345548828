import { useSelector } from 'react-redux';
import { createSelector, Selector } from '@reduxjs/toolkit';
import {
  ContentShare,
  ShareType,
} from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'
import { ORMTypes, ContentShareORM } from 'src/types/orms';
import { ExistingShareLink } from 'src/utils/shareLink/shareLink.web';
import config from '../../../config/app.json';
import compareDesc from 'date-fns/compareDesc';

export const selContentShares = (state: RootState): ContentShare[] => state.contentShare.records

// not sure if we need a ContentShareORM?
const toContentShareORM = (
  contentShare: ContentShare,
): ContentShareORM => {
  return {
    model: contentShare,
    type: ORMTypes.CONTENT_SHARE,
  }
}

const toExistingLinkFormat = (contentShare: ContentShare, title: string): ExistingShareLink => {
  // [TODO] - Refactor - Feels like this should be handled at a different level, not in-line
  const shareType: Record<Exclude<ShareType, 'RECORDING'>, string> = {
    [ShareType.HUB]: 'hubs',
    [ShareType.DOCUMENT_VERSION]: 'share',
    [ShareType.ATTACHED_FILE]: 'share',
    [ShareType.CUSTOM_DECK]: 'share',
  }

  return {
    expiresAt: contentShare.expiresAt,
    id: contentShare.id,
    link: `${config.contentURL}/${shareType[contentShare.type]}/${contentShare.token}`,
    title,
  }
}

const contentShareList: Selector<RootState, ContentShareORM[]> = createSelector(
  selContentShares,
  (contentShares): ContentShareORM[] => contentShares.map(contentShare => toContentShareORM(contentShare)),
)

const contentShareListByContentId: Selector<RootState, ContentShareORM[]> = createSelector(
  contentShareList,
  (_: RootState, contentId: string) => contentId,
  (contentShareListORM: ContentShareORM[], contentId): ContentShareORM[] =>
    contentShareListORM.filter((contentShare: ContentShareORM) => contentShare.model.contentId === contentId),
)

const contentShareListByContentIdSortedByCreatedAt: Selector<RootState, ContentShareORM[]> = createSelector(
  contentShareList,
  (_: RootState, contentId: string) => contentId,
  (contentShareListORM: ContentShareORM[], contentId): ContentShareORM[] =>
    contentShareListORM.filter((contentShare: ContentShareORM) => contentShare.model.contentId === contentId)
      .sort((a, b) => compareDesc(new Date(a.model.createdAt), new Date(b.model.createdAt))),
)

const existingContentShareLink: Selector<RootState, ExistingShareLink> = createSelector(
  contentShareList,
  (_: RootState, contentShareId: string) => contentShareId,
  (contentShareListORM, contentShareId): ExistingShareLink => {
    const existingContentShareORM = contentShareListORM
      .find((contentShare: ContentShareORM) => contentShare.model.id === contentShareId)

    if (!existingContentShareORM) {
      throw new Error(`ContentShare with id ${contentShareId} not found`)
    }

    return toExistingLinkFormat(existingContentShareORM.model, '')
  },
)

export const useContentShareList = (): ReturnType<typeof contentShareList> =>
  useSelector((state: RootState) => contentShareList(state))

export const useContentShareListByContentId = (contentId: string): ReturnType<typeof contentShareListByContentId> =>
  useSelector((state: RootState) => contentShareListByContentId(state, contentId))

export const useContentShareListByContentIdSortedByCreatedAt = (contentId: string): ReturnType<
  typeof contentShareListByContentIdSortedByCreatedAt> =>
  useSelector((state: RootState) => contentShareListByContentIdSortedByCreatedAt(state, contentId))

export const useExistingContentShareLink =
  (contentShareId: string, title: string): ReturnType<typeof existingContentShareLink> =>
    useSelector((state: RootState) => existingContentShareLink(state, contentShareId, title))
