import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import { DNACheckbox, DNADivider } from '@alucio/lux-ui/src';
import { DebuggableMachines, useAppSettings } from 'src/state/context/AppSettings';
import loggers from 'src/utils/logger';
import { S } from '..'

const DebugTool: React.FC = () => {
  const { debugStateMachines, dispatch } = useAppSettings();
  return (
    <DNABox appearance="col" spacing="sm">
      <DNAText h2>Debug Tool</DNAText>
      <DNAText h3>Enable xstate machine inpect tool</DNAText>
      <DNAText bold>Toggling the checkbox below will refresh the page.</DNAText>
      {Object.values(DebuggableMachines).map((id) => (
        <DNABox key={id} spacing="sm">
          <DNAText>{id}</DNAText>
          <DNACheckbox
            checked={!!debugStateMachines[id]}
            onChange={() => { dispatch({ type: 'toggleDebugStateMachine', payload: id }) }}
          />
        </DNABox>
      ))}
      <DNADivider/>
    </DNABox>
  )
}

const Logging: React.FC = () => {
  const [loggingStats, setLoggingStats] = useState<string>()

  const crashTheApp = () => {
    loggers.loggerMachine.error(
      'Crash the app button pressed',
      'App has been crashed and email notification has been sent',
      { details: 'Crash the app button details, you should not see me in email notification' },
    )
    throw new Error('Crash the app button pressed')
  }

  const getLoggingStats = useCallback(
    () => {
      const loggerStats = {}

      // [TODO] - Get individual nested logger stats
      Object
        .values(loggers)
        .forEach((logger) => {
          // @ts-expect-error - Not all loggers are Keyed types just yet
          if (logger.stats) {
            // @ts-expect-error - Not all loggers are Keyed types just yet
            loggerStats[logger.name] = logger?.stats?.getStats()
          }
        })

      if (Object.keys(loggerStats)) {
        setLoggingStats(JSON.stringify(loggerStats, null, 2))
      }
    },
    [],
  )

  return (
    <DNABox appearance="col" spacing="sm">
      <DNAText h2>Logging</DNAText>
      {/* BUTTONS */}
      <DNAButton disabled={true}>
        Generate Arbitrary Logs (TODO: Generate N logs of random sizes)
      </DNAButton>
      <DNAButton onPress={getLoggingStats}>
        Get Session Stats
      </DNAButton>

      <DNAButton status="danger" onPress={crashTheApp}>
        Crash the app
      </DNAButton>

      {/* INFO */}
      <DNAText bold>Logging Machine Status (TODO)</DNAText>
      <DNAText bold>Logging Stats:&nbsp;<DNAText>{loggingStats}</DNAText></DNAText>
    </DNABox>
  )
}

const DebugToolNLogging: React.FC = () => {
  return (
    <DNABox fill appearance="col" style={S.tabContainer}>
      <DNABox as={ScrollView} appearance="col" spacing="md">
        <DebugTool/>
        <Logging/>
      </DNABox>
    </DNABox>
  )
}

export default DebugToolNLogging
