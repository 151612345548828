import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import throttle from 'lodash/throttle';
import deepEqual from 'fast-deep-equal';
import { DebouncedFuncLeading } from 'lodash';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import DNAError from 'src/components/DNA/Error';
import DNASchedulePublishDocument from 'src/components/DNA/Modal/DNASchedulePublishDocument';
import { useInterpret } from '@xstate/react';
import { useDispatch } from 'src/state/redux';
import useLazyRef from 'src/hooks/useLazyRef';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import useContentPageData, { ContentPageData, Recommendations } from 'src/hooks/useContentPageData/useContentPageData';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { MAX_OFFLINE_FILE_SIZE_BYTES } from 'src/worker/machines/sync/syncEntry';
import {
  AssociatedFile,
  AttachedFile,
  ConversionStatus,
  ConversionWarningCode,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models';
import { DocumentORM, DocumentVersionORM, ORMTypes } from 'src/types/types';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { UploadStatus } from 'src/components/DNA/FileUpload/FileUpload';
import { multiSliceActions } from 'src/state/redux/slice/multiSlice';
import { publisherVersioningSM } from 'src/state/machines/publisherVersioning/publisherVersioning.machine';
import {
  PublisherVersioningService,
  Versioning_Tab,
} from 'src/state/machines/publisherVersioning/publisherVersioning.types';
import * as VersioningModals from 'src/components/DNA/Modal/DNAVersioningModals';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { DocumentInfoFormRef } from 'src/screens/Publishers/Versioning/DocumentInfo';
import { DocumentPublishFormRef } from 'src/screens/Publishers/Versioning/DocumentPublish';
import util, {
  filterRecommendations,
  calculateContentPageDataAndMatchSlideStates,
  dedupePageDataMapping,
} from './util';
import { useStateMachineLogger } from '../util';
import logger from 'src/utils/logger';

export type MatchSlideStatesType = Record<string, {
  status: MATCH_SLIDE_STATUS,
  duplicateSlides?: string[],
}>

export interface PublisherVersioningStateType {
  service: PublisherVersioningService,
  documentORM: DocumentORM,
  currentDocumentVersionORM: DocumentVersionORM,
  latestPublishedDocumentVersionORM?: DocumentVersionORM,
  previousVersionORM?: DocumentVersionORM,
  latestContentPageData?: ContentPageData[],
  setLatestContentPageData: React.Dispatch<React.SetStateAction<ContentPageData[]>>
  latestPublishedContentPageData?: ContentPageData[],
  matchSlideStates: MatchSlideStatesType,
  setMatchSlideStates: React.Dispatch<React.SetStateAction<MatchSlideStatesType>>,
  documentInfoRef: React.RefObject<DocumentInfoFormRef>,
  documentPublishRef: React.RefObject<DocumentPublishFormRef>,
  isCriticalError: boolean;
  isSlideTextInsertionEnabled: boolean;
  exceedsMaxOfflineSize: boolean;
  isWebDoc: boolean;
  isVideo: boolean;
  isHTMLDoc: boolean;
  isNonDocumentFile: boolean;
  isPasswordProtected: boolean;
  mappedValues: MappedValues;

  // Functions
  toggleSlider: (onInvisCb?: () => void) => void,
  navigateToPreviousTab: () => void,
  navigateToNextTab: () => void,
  setSelectedIndex: DebouncedFuncLeading<(e: any) => void>,
  onVersionHistorySelect: (docVerId: string) => void,
  handleCreateFromExistingVersion: () => void,
  handleUploadNewVersion: (files: FileList | null) => void,
  handleExitButton: () => void,
  handleSaveDraft: () => void,
  handleDeleteDraft: () => void,
  handlePublish: () => void,
  handleSchedulePublish: () => void,
  handleCancelSchedulePublish: () => void,
  onSelectAssociatedFile: (parentDoc: DocumentVersionORM, linkedDoc: DocumentORM) => void,
  handleUploadAssociatedFileStatusChange: (status: UploadStatus) => void,
  handleFinishedUploadAssociatedFile: (attachedFile: AttachedFile) => void,
  handleUpdateAssociatedFile: (associatedFile: AssociatedFile) => void,
  handleDeleteAssociatedFile: (entityORM: DocumentVersionORM, item: DocumentORM | AttachedFile) => void,
}

export const PublisherVersioningStateContext = createContext<PublisherVersioningStateType | null>(null!);
PublisherVersioningStateContext.displayName = 'PublisherVersioningContext';

export interface MappingType {
  mapping: string | null
  recommendations: Recommendations[]
  rawRecommendations: Recommendations[]
}

export type MappedValues = Record<string, MappingType>

export enum MATCH_SLIDE_STATUS {
  MATCHED = 'MATCHED',
  NO_MATCH = 'NO_MATCH',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
}

const PublisherVersioningStateProvider: React.FC<PropsWithChildren<{
  documentORM: DocumentORM,
  toggleSlider: (onInvisCb?: () => void) => void,
  initialDocumentId?: string,
}>> = (props) => {
  const { toggleSlider, documentORM, children } = props;
  const { latestDocumentVersionORM } = documentORM.relations.version;
  const dispatch = useDispatch();
  const documentInfoRef = useRef<DocumentInfoFormRef>(null);
  const documentPublishRef = useRef<DocumentPublishFormRef>(null);
  const featureFlags =
    useFeatureFlags('BEAC_4251_slide_version_tracking', 'BEAC_6389_slide_text_insertion');
  const isFirstVersion = documentORM.relations.documentVersions.length === 1;
  const enableFirstMatchSlideDisplay = useFeatureFlags('BEAC_6763_first_match_slide_display');

  /** STATE MACHINE */
  const machineInstance = useLazyRef(() => publisherVersioningSM.withContext({
    enableMatchSlides: featureFlags.BEAC_4251_slide_version_tracking,
    availabletabs: util.getAvailabletabs(featureFlags.BEAC_4251_slide_version_tracking && !isFirstVersion),
    cancelUpload: false,
    documentVersionId: latestDocumentVersionORM.model.id,
    documentInfoIsDirty: false,
    documentSettingsIsDirty: false,
    documentSlidesDataIsDirty: false,
    documentPublishIsDirty: false,
    errors: {},
    getDocumentORM: util.getDocumentORMFactory(documentORM.model.id),
    hasOptimizedFinishedThisSession: false,
    selectedTabIndex: 0,
    versionActor: undefined,
    versionForm: util.omitInternalFields(latestDocumentVersionORM.model),
    slideSettingsActor: undefined,
    hasNeedReviewSlide: false,
    hasEmptyMatchSlide: false,
  }));

  const service = useInterpret(
    machineInstance.current!,
  );

  useStateMachineLogger(service, logger.versioning)

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.availabletabs,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.isNavBlocked,
      publisherVersioningSelector.documentSlidesDataIsDirty,
      publisherVersioningSelector.isDraftDirty,
      publisherVersioningSelector.documentVersionId,
    ),
  )

  // [NOTE] - if currentDocumentVersionORM is undefined, we will throw an error at VersioningPanel
  const currentDocumentVersionORM = useMemo(() => {
    const targetVersionORM = documentORM
      .relations
      .documentVersions
      .find(docVerORM => docVerORM.model.id === cond.documentVersionId)

    // [TODO-2126] - (BUG) There's a good chance that a delete will affect other publishers (concurrently)
    //             - The other windows record will auotmagically be removed from Redux causing another window
    //               to crash
    //             - Think it's okay to let it crash as is while we figure out a better fix

    return (
      targetVersionORM ??
      documentORM.relations.version.latestPublishedDocumentVersionORM
    )
  }, [documentORM, cond.documentVersionId])

  const previousVersionORM = useMemo(() => {
    if (!currentDocumentVersionORM) {
      return undefined;
    }
    const targetVersionORM = documentORM.relations.documentVersions
      .filter(version => version.model.status !== 'DELETED' &&
        version.model.versionNumber < currentDocumentVersionORM.model.versionNumber)
      .sort((a, b) => b.model.versionNumber - a.model.versionNumber)
      .find(() => true);
    return targetVersionORM ?? currentDocumentVersionORM;
  }, [documentORM, currentDocumentVersionORM]);

  const isCriticalError = currentDocumentVersionORM?.model.conversionStatus === ConversionStatus.ERROR;
  const presentable = getPresentable(
    documentORM
      .relations
      .documentVersions
      .find(docVerORM => docVerORM.model.id === cond.documentVersionId) ??
    documentORM.relations.version.latestPublishedDocumentVersionORM,
  );
  const { contentPageData } = useContentPageData(
    presentable,
    latestDocumentVersionORM?.model.status !== 'PUBLISHED',
    true,
  );
  const [latestContentPageData, setLatestContentPageData] = useState<ContentPageData[]>([])
  const [matchSlideStates, setMatchSlideStates] = useState<MatchSlideStatesType>({})

  const mappedValues = useMemo(() => {
    return latestContentPageData?.reduce<MappedValues>((acc, contentPageData) => {
      acc[contentPageData.pageId] = {
        mapping: contentPageData.mapping,
        recommendations: filterRecommendations(
          contentPageData.recommendations,
          enableFirstMatchSlideDisplay,
        ),
        rawRecommendations: contentPageData.recommendations,
      }
      return { ...acc }
    }, {}) ?? {}
  }, [latestContentPageData])

  useEffect(() => {
    // We only want to reassign latestContentPageData when:
    // * the length is different (Initially it will be an emtpy array)
    // * the pageId changed (meaning the current version number had changed)
    const differentLength = contentPageData.length !== latestContentPageData.length;
    const differentVersion = contentPageData?.[0]?.pageId !== latestContentPageData?.[0]?.pageId;
    if (differentLength || differentVersion) {
      // We need to update contentPageData mapping with the initial value
      // only assign mapping for the one that is above threshold
      const {
        updatedContentPageData,
        updatedMatchSlideStates,
      } = calculateContentPageDataAndMatchSlideStates(
        contentPageData,
        enableFirstMatchSlideDisplay,
      )
      setLatestContentPageData(updatedContentPageData)
      setMatchSlideStates(updatedMatchSlideStates)
    }
  }, [contentPageData, latestContentPageData])

  const latestPublishedDocumentVersionORM = cond.isInDraftingState
    ? documentORM.relations.version.latestPublishedDocumentVersionORM
    : documentORM.relations.documentVersions[1]
  const latestPublishedVersionPresentable = getPresentable(latestPublishedDocumentVersionORM);
  const { contentPageData: latestPublishedContentPageData } = useContentPageData(
    latestPublishedVersionPresentable,
  );
  const exceedsMaxOfflineSize = !!currentDocumentVersionORM?.model.convertedArchiveSize &&
    currentDocumentVersionORM?.model.convertedArchiveSize > MAX_OFFLINE_FILE_SIZE_BYTES
  const isWebDoc = currentDocumentVersionORM?.model.type === FileType.WEB
  const isVideo = currentDocumentVersionORM?.model.type === FileType.MP4
  const isHTMLDoc = currentDocumentVersionORM?.model.type === FileType.HTML
  const isNonDocumentFile = isWebDoc || isVideo || isHTMLDoc
  const isPasswordProtected = currentDocumentVersionORM?.model.conversionWarningCode ===
    ConversionWarningCode.PASSWORD_PROTECTED

  /** STATE MACHINE FUNCTIONS START */

  const navigateToPreviousTab = useCallback(() => {
    service.send('NAV_TAB_PREV')
  }, [service])

  const navigateToNextTab = useCallback(() => {
    service.send({
      type: 'NAV_TAB_NEXT',
      payload: { versionForm: documentInfoRef.current?.getDocumentInfoFormValues() },
    })
  }, [service])

  const setSelectedIndex = useCallback(throttle(e => {
    if (cond.isNavBlocked) return;
    const prefix = 'NAV_TO_';
    const targetTabEvent = cond.availabletabs[e]
      ? prefix + cond.availabletabs[e] as Versioning_Tab
      : undefined;
    const targetTab = targetTabEvent || 'NAV_TO_DOCUMENT_PUBLISH_TAB'

    const payload = targetTab === 'NAV_TO_DOCUMENT_PUBLISH_TAB'
      ? { versionForm: documentInfoRef.current?.getDocumentInfoFormValues() }
      : undefined
    service.send({ type: targetTab, payload })
  }, 750), [service, cond.isNavBlocked, cond.availabletabs]);

  const onVersionHistorySelect = useCallback((docVerId: string) => {
    service.send({
      type: 'SWITCH_DOCUMENT_VERSION',
      payload: { documentVersionId: docVerId },
    })
  }, [service])

  const handleCreateFromExistingVersion = useCallback(() => {
    service.send({ type: 'CREATE_FROM_EXISTING' })
  }, [service])

  const handleUploadNewVersion = useCallback((files: FileList | null) => {
    if (files) {
      const [file] = Array.from(files)

      if (!file) return;
      service.send({ type: 'CREATE_FROM_UPLOAD', payload: { file } })
    }
  }, [service])

  const handleExitButton = useCallback(() => {
    if (!cond.isDraftDirty) {
      toggleSlider()
      return;
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.DiscardChangesModal onConfirm={toggleSlider}/>,
    }))
  }, [cond.isDraftDirty, dispatch])

  // [NOTE] - Drafts can be saved as partial DocumentVersions without form validation
  const handleSaveDraft = useCallback(() => {
    const documentInfoFormData = documentInfoRef.current?.submitDraftValues() ?? null;

    if (!documentInfoFormData) return;

    const documentPublishFormData = documentPublishRef.current?.handleSaveDraftForDocumentPublish() ?? {};

    const shouldUpdatePageData = cond.documentSlidesDataIsDirty || !deepEqual(
      contentPageData,
      latestContentPageData,
    )
    service.send({
      type: 'SAVE_DRAFT',
      payload: {
        versionForm: {
          ...documentInfoFormData,
          ...documentPublishFormData,
        },
        contentPageData: shouldUpdatePageData ? latestContentPageData : undefined,
      },
    })
  }, [service, cond.documentSlidesDataIsDirty, contentPageData, latestContentPageData])

  const handleDeleteDraft = useCallback(() => {
    const confirmDelete = () => {
      if (!currentDocumentVersionORM) return;
      const isFirstVersion = !currentDocumentVersionORM
        .relations
        .documentORM
        .relations
        .version
        .latestPublishedDocumentVersionORM

      // [NOTE] - In order to ensure the slick slider animation, we delete out-of-band for the machine
      //          While we could delete through the machine, the panel would go blank as the slider closes
      //          -or- the animation may not be smooth due to panel re-rendering performance
      isFirstVersion
        ? toggleSlider(() => dispatch(multiSliceActions.deleteDocumentDraft(currentDocumentVersionORM)))
        : service.send({ type: 'DELETE_DRAFT' })
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.DeleteDraftModal onConfirm={confirmDelete}/>,
    }))
  }, [service, currentDocumentVersionORM, dispatch])

  const validateFormData = useCallback(async () => {
    logger.versioning.debug('validating form data...');
    const documentInfoFormData = await documentInfoRef.current?.submitPublishValues() ?? null;
    const documentPublishFormData = await documentPublishRef.current?.handlePublishDocumentInfo() ?? null;

    if (!documentInfoFormData) {
      logger.versioning.debug('Missing form data from Info tab, navigate to info tab.');
      service.send({ type: 'NAV_TO_DOCUMENT_INFO_TAB' });
    }
    else if (!documentPublishFormData) {
      logger.versioning.debug('Missing form data from publish tab, navigate to publish tab.');
      service.send({ type: 'NAV_TO_DOCUMENT_PUBLISH_TAB' });
    }
    else {
      logger.versioning.debug('All form data passed validation.');
      return {
        ...documentInfoFormData,
        ...documentPublishFormData,
      }
    }
  }, [service])

  const handlePublish = useCallback(() => {
    const asyncWrapper = async () => {
      const formData = await validateFormData()
      if (!formData) return;

      const documentORM = currentDocumentVersionORM?.relations.documentORM;
      const dedupePageData = dedupePageDataMapping(
        latestContentPageData,
        matchSlideStates,
      )
      const shouldUpdatePageData = cond.documentSlidesDataIsDirty || !deepEqual(
        contentPageData,
        dedupePageData,
      )
      const sendPublishEvent = () => {
        service.send({
          type: 'PUBLISH_VERSION',
          payload: {
            versionForm: { ...formData },
            contentPageData: shouldUpdatePageData ? dedupePageData : undefined,
          },
        })
      }

      const isFirstVersion = documentORM?.relations.documentVersions.length === 1;
      const componentType = isFirstVersion
        ? () => <VersioningModals.PublishDocumentModal onConfirm={sendPublishEvent}/>
        : () => <VersioningModals.PublishVersionModal onConfirm={sendPublishEvent}/>

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: componentType,
      }))
      if (shouldUpdatePageData) setLatestContentPageData(dedupePageData)
    }

    asyncWrapper()
  }, [
    service,
    dispatch,
    validateFormData,
    cond.documentSlidesDataIsDirty,
    currentDocumentVersionORM,
    latestContentPageData,
    setLatestContentPageData,
    matchSlideStates,
  ])

  const handleSchedulePublish = useCallback(() => {
    const asyncWrapper = async () => {
      const formData = await validateFormData()
      if (!formData) return;

      const dedupePageData = dedupePageDataMapping(
        latestContentPageData,
        matchSlideStates,
      )

      const shouldUpdatePageData = cond.documentSlidesDataIsDirty || !deepEqual(
        contentPageData,
        dedupePageData,
      )
      const sendScheduleEvent = (scheduledPublish: Date) => {
        service.send({
          type: 'SCHEDULE_PUBLISH_VERSION',
          payload: {
            versionForm: {
              ...formData,
              scheduledPublish: scheduledPublish.toISOString(),
            },
            contentPageData: shouldUpdatePageData ? dedupePageData : undefined,
          },
        })
      }

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) =>
          (<DNASchedulePublishDocument
            { ...props }
            send={sendScheduleEvent}
          />),
      }))
      if (shouldUpdatePageData) setLatestContentPageData(dedupePageData)
    }
    asyncWrapper()
  }, [
    service,
    dispatch,
    validateFormData,
    cond.documentSlidesDataIsDirty,
    currentDocumentVersionORM,
    latestContentPageData,
    matchSlideStates,
  ])

  const handleCancelSchedulePublish = useCallback(() => {
    const sendCancelScheduleEvent = () => {
      service.send({ type: 'CANCEL_SCHEDULE_VERSION' })
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.CancelSchedulePublishModal onConfirm={sendCancelScheduleEvent}/>,
    }))
  }, [service, dispatch])

  const onSelectAssociatedFile = useCallback((_, linkedDocument: DocumentORM) => {
    analytics?.track('SEARCH_AF', {
      action: 'INPUT',
      category: 'SEARCH',
    });
    service.send({
      type: 'ASSOCIATED_DOCUMENT_LINK',
      payload: { documentId: linkedDocument.model.id },
    })
  }, [service])

  const handleUploadAssociatedFileStatusChange = useCallback((status: UploadStatus) => {
    service.send({
      type: 'ATTACHED_FILE_UPLOAD_STATUS_CHANGE',
      payload: { status },
    })
  }, [service])

  const handleFinishedUploadAssociatedFile = useCallback((attachedFile: AttachedFile) => {
    service.send({
      type: 'ATTACHED_FILE_UPLOADED',
      payload: { attachedFile: attachedFile },
    })
  }, [service])

  const handleUpdateAssociatedFile = useCallback((associatedFile: AssociatedFile) => {
    service.send({
      type: 'ASSOCIATED_FILE_UPDATED',
      payload: { associatedFile },
    })
  }, [service])

  const handleDeleteAssociatedFile = useCallback((_, item: DocumentORM | AttachedFile) => {
    service.send({
      type: 'ASSOCIATED_FILE_DELETE',
      payload: {
        attachmentId: item.type === ORMTypes.DOCUMENT
          ? item.model.id
          : item.id,
      },
    })
  }, [service])

  /** STATE MACHINE FUNCTIONS END */

  if (!currentDocumentVersionORM) {
    const errorText = `Could not open Document for versioning: ${documentORM.model.id}`;
    logger.versioning.error(errorText);
    return (
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        style={{ backgroundColor: 'white' }}
      >
        <DNAError
          promptLogout={false}
          message="Could not open Document for versioning!"
        >
          <DNAText>{documentORM.model.id}</DNAText>
          <DNAButton
            appearance="ghost"
            onPress={() => toggleSlider()}
          >
            Go back
          </DNAButton>
        </DNAError>
      </DNABox>
    )
  }

  const contextValue: PublisherVersioningStateType = {
    service,
    documentORM,
    currentDocumentVersionORM,
    isSlideTextInsertionEnabled: featureFlags.BEAC_6389_slide_text_insertion,
    latestPublishedDocumentVersionORM,
    previousVersionORM,
    latestContentPageData,
    setLatestContentPageData,
    latestPublishedContentPageData,
    matchSlideStates,
    setMatchSlideStates,
    documentInfoRef,
    documentPublishRef,
    isCriticalError,
    exceedsMaxOfflineSize,
    isWebDoc,
    isVideo,
    isHTMLDoc,
    isNonDocumentFile,
    isPasswordProtected,
    mappedValues,

    // Functions
    toggleSlider,
    navigateToPreviousTab,
    navigateToNextTab,
    setSelectedIndex,
    onVersionHistorySelect,
    handleCreateFromExistingVersion,
    handleUploadNewVersion,
    handleExitButton,
    handleSaveDraft,
    handleDeleteDraft,
    handlePublish,
    handleSchedulePublish,
    handleCancelSchedulePublish,
    onSelectAssociatedFile,
    handleUploadAssociatedFileStatusChange,
    handleFinishedUploadAssociatedFile,
    handleUpdateAssociatedFile,
    handleDeleteAssociatedFile,
  };

  return (
    <PublisherVersioningStateContext.Provider value={contextValue}>
      {children}
    </PublisherVersioningStateContext.Provider>
  );
};

PublisherVersioningStateProvider.displayName = 'PublisherVersioningStateProvider';

export const usePublisherVersioningState = () => {
  const context = useContext(PublisherVersioningStateContext);
  if (!context) {
    throw new Error('usePublisherVersioningState must be used within the PublisherVersioningStateProvider');
  }
  return context;
};

export default PublisherVersioningStateProvider;
