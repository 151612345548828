import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import type { WritableDraft } from 'immer/dist/internal';
import { DNABox, Stack, Iffy, DNASlider, InformationMessage } from '@alucio/lux-ui';
import { DNALoaderEvents } from '../DNA/Loader/DNALoader';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { Modal } from '@ui-kitten/components';
import { RootState } from 'src/state/redux';
import { FolderORM, ORMTypes } from 'src/types/types';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import ContentProvider, { LoadedPresentation, useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { AUTO_UPDATE_DEFAULT_DATE } from 'src/state/redux/slice/customDeck';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper';
import MeetingPresentedMetaProvider, { useMeetingPresentedMeta } from './State/MeetingPresentedMetaProvider';
import { SharedFilesProvider } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider.proxy';
import { SharedFilesContextType } from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesProvider';
import { getNotations } from 'src/state/machines/notation/notationUtils';
import ContentPreviewModalStateProvider,
{ useContentPreviewModal } from './State/ContentPreviewModalStateProvider';
import { useAddDocAndPageToUrl } from './Util/useCPMDedicatedUrl';
import EmptyStateScreen from './EmptyStateScreen';

// COMPONENT
import TextSearchPanel from '../TextSearchPanel/TextSearchPanel';
import ActionBar from './ActionBar';
import Header from './Header/Header';
import PreviewSlideRollConnector from './PreviewSlideRoll';
import Sidebar from './SideBar/SideBar';
import { useDNACustomDeckActions } from '../DNA/Document/DNACustomDeck.actions';
import { getRootFolderORM, useCustomDeckORMById } from 'src/state/redux/selector/folder';
import { isSharedEditableFolder, isSharedViewOnlyFolder } from 'src/utils/foldersHelpers';
import debounce from 'lodash/debounce';

export const styles = StyleSheet.create({
  container: {},
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    flex: 1,
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    backgroundColor: colors['color-text-white'],
    width: '100vw',
    height: '100vh',
  },
  viewContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  slideRoll: {
    display: 'none',
    maxWidth: 250,
    marginRight: 40,
  },
  playerWrapperContainer: {
    padding: 15,
  },
  webDocActionbar: {
    padding: 14,
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
    backgroundColor: colors['color-text-white'],
  },
  loaderImage: {
    width: 200,
    height: 200,
  },
  InformationMessageContainer: {
    backgroundColor: colors['color-text-white'],
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
  },
});

type DeviceModeVariants = Record<DeviceMode, React.ElementType>
const deviceModeVariants: DeviceModeVariants = {
  desktop: Modal,
  tablet: View,
}

type CustomDeckReviewBannerProps = {
  activePresentation: LoadedPresentation | undefined
}

const CustomDeckReviewBanner: React.FC<CustomDeckReviewBannerProps> = (props) => {
  const { activePresentation } = props
  const { onClose } = useContentPreviewModal()
  const DNACustomDeckActions = useDNACustomDeckActions()
  const [isDismissed, setIsDimissed] = useState<boolean>(false)

  const folderItemId = isFolderItemORM(activePresentation?.presentable.orm) &&
    activePresentation?.presentable.orm.relations.itemORM.model.id
  // Note: the reason we're getting the orm here and not from the one from the activePresentation is because the one from the active presentation is a stale version of the orm (to avoid changes while presenting). But here, we need the latest version of the CustomDeckORM so the banner disappears if the user fixes the deck while presenting
  const customDeckORM = useCustomDeckORMById(folderItemId || '');
  if (!customDeckORM) return null

  const folderItem = isFolderItemORM(activePresentation?.presentable.orm)
    ? activePresentation?.presentable.orm
    : undefined

  let hasEditPermission = true

  if (folderItem) {
    const rootFolder = folderItem.relations.parentORM
      ? getRootFolderORM(folderItem.relations.parentORM)
      : folderItem.relations.parentORM

    if (rootFolder) {
      hasEditPermission = isSharedEditableFolder(rootFolder) ||
        !rootFolder.meta.isSharedFolder
    }
  }

  const withinGracePeriod = customDeckORM.meta.version.withinGracePeriod;

  const onReview = () => {
    // Open Deck Editor
    DNACustomDeckActions.edit(
      customDeckORM,
      isFolderItemORM(activePresentation?.presentable.orm) ? activePresentation?.presentable.orm : undefined,
      'REVIEW',
    )()
    // Close CPM
    onClose()
  }
  const handleCloseBanner = () => setIsDimissed(true)
  const message = `This file needs to be reviewed${hasEditPermission ? '' : ' by the owner'}.`;

  return (
    <Iffy is={!isDismissed && withinGracePeriod}>
      <DNABox style={styles.InformationMessageContainer}>
        <InformationMessage
          text={message}
          variance="warning"
          secondayVariance="warning"
          actionButtonFunction={handleCloseBanner}
          actionButtonText="Dismiss"
          secondaryActionButtonFunction={onReview}
          secondayActionButtonText={ hasEditPermission ? 'Review' : undefined}
          customWrapperStyle={{ margin: 0 }}
        />
      </DNABox>
    </Iffy>
  )
}

/**
 * Custom hook to handle slide change events and send metrics.
 *
 * @param {LoadedPresentation | undefined} activePresentation - The current active presentation.
 * @returns {Object} - An object containing the current slide and a function to set the current slide.
 */
const useSlideChangeEvent = (activePresentation: LoadedPresentation | undefined) => {
  const [lastSentSlide, setLastSentSlide] = useState<number | null>(null);

  /**
   * Sends a slide change event if the current slide number has changed.
   */
  const sendSlideChangeEvent = useCallback(debounce((presentation, lastSlide) => {
    const pageId = presentation?.currentPresentablePage.page.pageId;
    const pageNumber = presentation?.currentPresentablePage.presentationPageNumber;

    if (!presentation) return;
    if (pageId === undefined || pageNumber === undefined) return;

    if (pageNumber === lastSlide) return;

    const event = {
      type: 'SLIDE_CHANGED',
      action: 'VIEWED',
      category: 'SLIDE',
      context: 'IN_PERSON',
      documentId: presentation?.currentPresentablePage.documentVersionORM.model.documentId || 'unknown',
      documentVersionId: presentation?.currentPresentablePage.documentVersionORM.model.id || 'unknown',
      pageId: pageId || 'unknown',
      pageNumber: pageNumber || 'unknown',
      // device mode is also required, but is injected by the global monkey patch
    };
    analytics?.track('SLIDE_VIEWED', event);
    setLastSentSlide(pageNumber);
  }, 3000), []);

  useEffect(() => {
    if (activePresentation?.currentPresentablePage.page.pageId !== undefined &&
        activePresentation?.currentPresentablePage.presentationPageNumber !== undefined) {
      sendSlideChangeEvent(activePresentation, lastSentSlide);
    }

    return () => {
      sendSlideChangeEvent.cancel();
    }
  }, [activePresentation, lastSentSlide, sendSlideChangeEvent]);
};

const WithContentProvider: React.FC = () => {
  const {
    addPresentation,
    activePresentation,
  } = useContent()
  const { isPresentedSlideEmpty } = useMeetingPresentedMeta()
  useSlideChangeEvent(activePresentation);

  /**
   * This hook updates the URL with the current document and page information when the active presentation changes.
   * It relies on side effects to update the URL and remove any empty parameters.
  */
  useAddDocAndPageToUrl()

  const {
    textSearchVisible, setTextSearchVisible,
    slideRollVisible, setSlideRollVisible,
    acknowledgeUpdate, isAcknowledgeClicked,
  } = useContentPreviewModal()
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  let orm;
  let isFolderItem: boolean | undefined;
  let rootFolder: FolderORM | undefined;
  if (isDocumentVersionORM(activePresentation?.presentable.orm)) {
    orm = activePresentation?.presentable.orm
  } else if (isFolderItemORM(activePresentation?.presentable.orm)) {
    isFolderItem = true
    orm = activePresentation?.presentable.orm.relations.itemORM
    rootFolder = activePresentation?.presentable.orm.relations.parentORM
  }
  const isWebDoc = orm?.model.type === FileType.WEB
  const isVideoDoc = orm?.model.type === FileType.MP4
  const isHTMLDoc = orm?.model.type === FileType.HTML
  const isCustomDeck = isFolderItemORM(activePresentation?.presentable.orm)
    ? activePresentation?.presentable.orm.model.type === ORMTypes.CUSTOM_DECK : false

  // ONLY SHOW ACTION BAR WHEN PRESENTED SLIDE IS NOT EMPTY AND
  // IF IT IS MEETING HISTORY, OR IT IS A CUSTOMDECK, OR IT IS NOT WEB, VIDEO, HTML DOCS
  const showActionBar = !isPresentedSlideEmpty &&
    (modalState.meetingId || isCustomDeck || !(isWebDoc || isVideoDoc || isHTMLDoc))
  const hideSearch = isWebDoc || isVideoDoc || isHTMLDoc

  useEffect(() => {
    // IF THE FILE IS FROM A MEETING, THE MEETINGPRESENTEDMETA
    // PROVIDER WILL HANDLE INITIALIZATION
    if (modalState.content && !modalState.meetingId) {
      // Attach notation to presentable if there is hubShareFileContext
      if (modalState.hubShareFileContext) {
        const sharedFiles = modalState.hubShareFileContext.rhForm.getValues('sharedFilesWidget')?.sharedFiles
        const notations = getNotations(sharedFiles, modalState.content.model.id)
        addPresentation(modalState.content, modalState.pageNumber, true, notations)
      } else addPresentation(modalState.content, modalState.pageNumber)
    }
  }, [modalState.content],
  )

  const handleAcknowledge = () => acknowledgeUpdate()
  const isSharedViewOnlyPermission = !!(isFolderItem && rootFolder && isSharedViewOnlyFolder(rootFolder));
  // This is needed for when the acknowledge is clicked in that case the ORM is not being updated
  const isCustomAcknowledged = isCustomDeck
    ? (orm?.model.autoUpdateAcknowledgedAt ?? AUTO_UPDATE_DEFAULT_DATE) !== AUTO_UPDATE_DEFAULT_DATE : false
  const isFolderItemAcknowledged = isFolderItem && !!orm?.model.updateAcknowledgedAt
  const hideAutoUpdateMessage = isCustomAcknowledged || isFolderItemAcknowledged || isSharedViewOnlyPermission;
  const showAutoUpdateBanner =
    !hideAutoUpdateMessage && isFolderItemORM(activePresentation?.presentable.orm) &&
    activePresentation?.presentable.orm?.meta?.hasAutoUpdatedItem

  return (
    <DNABox fill appearance="col">
      {/* HEADER */}
      <Header />
      {/* BANNERS */}
      <Iffy is={showAutoUpdateBanner && !isAcknowledgeClicked}>
        <DNABox style={styles.InformationMessageContainer}>
          <InformationMessage
            text="This file was auto-updated to a new version"
            variance="warning"
            actionButtonFunction={handleAcknowledge}
            actionButtonText="Okay"
            customWrapperStyle={{ margin: 0 }}
          />
        </DNABox>
      </Iffy>
      <CustomDeckReviewBanner activePresentation={activePresentation} />
      {/* CONTENT */}
      <Stack anchor="bottom" style={styles.mainWrapper}>
        <Stack.Layer style={styles.mainWrapper}>
          <DNABox fill appearance="row">
            {textSearchVisible && <TextSearchPanel onClose={() => setTextSearchVisible(false)} />}
            <DNABox fill style={[styles.slideRoll, slideRollVisible && { display: 'flex' }]}>
              <PreviewSlideRollConnector closeButtonOnPress={() => setSlideRollVisible(false)} />
            </DNABox>
            <DNABox fill style={{ padding: 15 }}>
              <Iffy is={isPresentedSlideEmpty}>
                <EmptyStateScreen />
              </Iffy>
              <Iffy is={!isPresentedSlideEmpty}>
                <PlayerWrapper
                  isDarkMode={true}
                  isFullWindow={false}
                  lockAspectRatio={false}
                  meetingId={modalState.presentationId!}
                  mode="INTERACTIVE"
                  isCustomDeck={isCustomDeck}
                  isExternalResourcesLoading={!!(modalState.isLoading && modalState.meetingId) || !activePresentation}
                  analyticsEventType={DNALoaderEvents.CONTENT_PREVIEW_MODAL}
                />
              </Iffy>
            </DNABox>
            <Sidebar/>
          </DNABox>
        </Stack.Layer>
      </Stack >
      {/* ACTION BAR */}
      <Iffy is={showActionBar}>
        <ActionBar hideSearch={hideSearch}/>
      </Iffy>
    </DNABox >
  )
}

interface DesktopDeviceModeProps {
  style?: ViewStyle,
  visible: boolean,
  backdropStyle: ViewStyle,
  onBackdropPress: () => void,
}
interface TabletDeviceModeProps {
  style?: ViewStyle,
}

type DeviceModeVariantProps = Record<DeviceMode, DesktopDeviceModeProps | TabletDeviceModeProps>

const ContentPreviewModalV2: React.FC = () => {
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  const dispatch = useDispatch()
  const { deviceMode } = useAppSettings();

  const handleBackdropPress = () => {
    dispatch(contentPreviewModalActions.setModalVisibility({ isOpen: false, content: undefined }))
  }

  const deviceModeVariantProps: DeviceModeVariantProps = {
    desktop: {
      style: styles.modalContainer,
      visible: !!modalState.isOpen,
      backdropStyle: styles.backdrop,
      onBackdropPress: handleBackdropPress,
    },
    tablet: {
      style: styles.viewContainer,
    },
  }

  if (!modalState.isOpen) {
    return null;
  }

  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode]
  const currentDeviceModeVariantProps = deviceModeVariantProps[deviceMode]
  const ModalComponent = (
    <CurrentDeviceModeVariant {...currentDeviceModeVariantProps}>
      <ContentProvider meetingId={modalState.presentationId}>
        <MeetingPresentedMetaProvider>
          <SharedFilesProviderWrapper hubShareFileContext={modalState.hubShareFileContext}>
            <ContentPreviewModalStateProvider>
              <WithContentProvider />
            </ContentPreviewModalStateProvider>
          </SharedFilesProviderWrapper>
        </MeetingPresentedMetaProvider>
      </ContentProvider>
    </CurrentDeviceModeVariant>
  );

  if (deviceMode === DeviceMode.tablet) {
    return (
      <DNASlider visible setVisible={() => {}}>
        {ModalComponent}
      </DNASlider>
    );
  }

  return ModalComponent;
}

type SharedFilesProviderWrapperProp = {
  hubShareFileContext?: WritableDraft<SharedFilesContextType>
}

const SharedFilesProviderWrapper: React.FC<PropsWithChildren<SharedFilesProviderWrapperProp>> = ({
  children,
  hubShareFileContext,
}) => {
  if (!hubShareFileContext) return children as React.ReactElement
  return (
    <SharedFilesProvider value={hubShareFileContext as SharedFilesContextType}>
      {children}
    </SharedFilesProvider>
  )
}

export default ContentPreviewModalV2
