import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { DNAText } from '@alucio/lux-ui';
import DNABox from '@alucio/lux-ui/src/components/layout/DNABox/DNABox';
import { useAppSettings } from 'src/state/context/AppSettings';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';
import MyUpdates from './Widgets/MyUpdates/MyUpdates';
import RecommendedActions from './Widgets/RecommendedActions/RecommendedActions';
import Documents, { DocumentWidgetVariant } from './Widgets/Documents/Documents';
import Folders, { FoldersWidgetVariant } from './Widgets/Folders/Folders';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 32,
    paddingVertical: 20,
  },
  contentWrapper: {
    columnGap: 24,
    minWidth: 400,
    rowGap: 24,
    flexWrap: 'wrap-reverse',
  },
  leftSideContainer: {
    flex: 2,
    minWidth: 400,
    rowGap: 24,
  },
  rightSideContainer: {
    flex: 1,
    minWidth: 320,
    rowGap: 24,
  },
});

const Home: React.FC = () => {
  const { deviceMode } = useAppSettings();
  const { goTo } = useScreenNav();

  useEffect(() => {
    if (deviceMode === 'tablet') {
      goTo.FOLDERS();
    }
  }, [deviceMode]);

  return (
    <DNABox appearance="col" style={styles.container} spacing="lg">
      {/* TITLE */}
      <DNABox alignX="start">
        <DNAText status="secondary" h5>
          Scientific Exchange Dashboard
        </DNAText>
      </DNABox>
      {/* CONTENT */}
      <DNABox style={styles.contentWrapper}>
        {/* LEFT SIDE */}
        <DNABox appearance="col" style={styles.leftSideContainer}>
          <Documents variant={DocumentWidgetVariant.MOST_VIEWED} />
          <Documents variant={DocumentWidgetVariant.RECENTLY_VIEWED} />
        </DNABox>
        {/* RIGHT SIDE */}
        <DNABox appearance="col" style={styles.rightSideContainer}>
          <MyUpdates />
          <RecommendedActions />
          <Folders variant={FoldersWidgetVariant.RECENTLY_VIEWED} />
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

export default Home;
