import React, { useEffect, useState } from 'react';
import { DNABox, DNASelect } from '@alucio/lux-ui';
import { IndexPath } from '@ui-kitten/components';

import PublisherSearchResults from 'src/screens/PublisherSearchResults/PublisherSearchResults';

export const STORAGE_KEY = 'overrideLexicalAdapterName';

const SEARCH_OPTIONS = {
  CURRENT: 'Current Search',
  HYBRID_V2_0: 'Hybrid Search v2.0',
  HYBRID_V2_1: 'Hybrid Search v2.1',
} as const;

export const SearchAdapterSelector = ({ onChange }: { onChange?: (value: string) => void }) => {
  const [selectedValue, setSelectedValue] = useState<string>(SEARCH_OPTIONS.CURRENT);

  useEffect(() => {
    // Initialize from localStorage
    const storedValue = localStorage.getItem(STORAGE_KEY);
    if (storedValue) {
      if (storedValue === 'Hybrid_Search_v2_0') {
        setSelectedValue(SEARCH_OPTIONS.HYBRID_V2_0);
      } else if (storedValue === 'Hybrid_Search_v2_1') {
        setSelectedValue(SEARCH_OPTIONS.HYBRID_V2_1);
      }
    }

    // Cleanup function to remove the storage key when component unmounts
    return () => {
      localStorage.removeItem(STORAGE_KEY);
    };
  }, []);

  const handleSelect = async (index: IndexPath | IndexPath[]) => {
    if (Array.isArray(index)) return;

    const selectedOption = Object.values(SEARCH_OPTIONS)[index.row];
    setSelectedValue(selectedOption);

    // Update localStorage based on selection
    switch (selectedOption) {
      case SEARCH_OPTIONS.CURRENT:
        localStorage.removeItem(STORAGE_KEY);
        break;
      case SEARCH_OPTIONS.HYBRID_V2_0:
        localStorage.setItem(STORAGE_KEY, 'Hybrid_Search_v2_0');
        break;
      case SEARCH_OPTIONS.HYBRID_V2_1:
        localStorage.setItem(STORAGE_KEY, 'Hybrid_Search_v2_1');
        break;
    }

    onChange?.(selectedOption);
  };

  return (
    <DNASelect
      style={{ width: 200 }}
      value={selectedValue}
      onSelect={handleSelect}
      testID="search-adapter-selector"
    >
      {Object.values(SEARCH_OPTIONS).map((option) => (
        <DNASelect.Item
          key={option}
          title={option}
          testID={`search-adapter-${option.toLowerCase().replace(/\s+/g, '-')}`}
        />
      ))}
    </DNASelect>
  );
};

const Search = () => {
  return (
    <DNABox>
      <PublisherSearchResults initUserDefaultFilters={false}/>
    </DNABox>
  );
}

export default Search;
