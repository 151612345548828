/* eslint-disable no-bitwise */
/** MODULES */
import { createMachine, matchesState, spawn, StateValue } from 'xstate'
import { assign } from '@xstate/immer'
import { current } from 'immer'
import * as logger from 'src/utils/logger'
import { reloadApp } from 'src/utils'

/** TYPES */
import * as Types from './crmMachineTypes'
import {
  CRM_AUTH_INFORMATION,
  CRM_STORAGE_UPDATE,
  INVALID_SESSION_ID,
  CRMUtil,
  CRM_LAST_SYNC_AT,
  errors,
  CRM_CONNECTION_CLOSED,
  CRM_LAST_SYNC_STATUS,
} from './util'
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB'
import { Config, TableEnum } from 'src/classes/CRM/CRMIndexedDBTypes'
import configSyncMachine, { ERR_SYNCING_CONFIG_FORM } from './configSyncMachine'
import { observableAutoSync, observableLogOutMessage, observableTenant } from './observables'
import { ERR_INVALID_SESSION_ID } from 'src/classes/CRM/Syncers/SalesforceSyncer'

export const ERROR_MESSAGES = {
  TOKEN_EXPIRED: 'Your session has expired. Please log in again.',
  SESSION_EXPIRED: 'Session expired or invalid',
  EXPIRED_ACCESS_REFRESH_TOKEN: 'expired access/refresh token',
  CANNOT_REFRESH_TOKEN: 'This token cannot be refreshed.',
  MALFORMED_QUERY: 'The query is malformed, please contact with support',
};

export const ERRORS_ARRAY = [
  ERROR_MESSAGES.TOKEN_EXPIRED,
  ERROR_MESSAGES.EXPIRED_ACCESS_REFRESH_TOKEN,
  ERROR_MESSAGES.CANNOT_REFRESH_TOKEN,
]

const crmUtil = new CRMUtil();

const CRMDB = IndexDbCrm;

const crmMachine = () => createMachine<
  Types.crmContext,
  Types.crmEvents,
  Types.crmState
>(
  {
    predictableActionArguments: false,
    id: 'crmMachine',
    strict: false,
    context: {
      crmSession: undefined,
      configItem: undefined,
      crmConfig: undefined,
      crmSyncStatus: Types.CRMSyncStatus.IDLE,
      isAuthenticating: false,
      crmFormConfigActor: undefined,
      connectionStatus: Types.CRMConnectionStatus.DISCONNECTED,
      crmAvailabilityStatus: Types.CRMAvailabilityStatus.DISABLED,
      autoSyncActor: undefined,
      lastSyncedAt: undefined,
      externalTabActor: undefined,
      numberOfRetries: 0,
    },
    initial: 'idle',
    entry: assign((context) => {
      if (!context.crmConfig) {
        spawn(observableTenant(), 'tenant')
        // we need to spawn the observable to handle the broad message
        spawn(observableLogOutMessage())
      }
      if (!context.autoSyncActor) {
        context.autoSyncActor = spawn(observableAutoSync(), 'crmAutoSync')
      }
      // used when user has multiple tabs open
      if (!context.externalTabActor) {
        context.externalTabActor = spawn(crmUtil.externalTabHandler)
      }
    }),
    states: {
      idle: {
        // The 'idle' state is the initial state of the CRM machine. It handles the initial loading of authentication
        // data from local storage, last synchronization time, and setup of various actors (like tenant, autoSync, externalTab).
        // It responds to various events like loading session data, refreshing data, logging in or out, and setting configuration.
        description: 'The initial state of oAuthTokenHandler',
        entry: ['loadAuthFromLocalStorage', 'loadLastSyncAt', 'loadActors'],
        initial: 'idle',
        on: {
          // Event to handle loading session data. It performs actions to read session data and synchronize form configuration.
          // It then transitions to the 'refreshingData.initializeIndexedDB' state to start data preparation.
          LOAD_SESSION_DATA: {
            actions: ['readSessionData', 'syncFormConfig'],
            // a soon the user is logged in, we can refresh the data
            target: '#crmMachine.refreshingData.initializeIndexedDB',
          },
          // Event to trigger data refresh. It transitions to the 'refreshingData.initializeIndexedDB' state to begin the data refreshing process.
          REFRESH_DATA: {
            target: 'refreshingData.initializeIndexedDB',
          },
          // Event for CRM login. It transitions to the 'login' state and sets up the login configuration.
          LOGIN_CRM: {
            target: 'login',
            actions: 'setLoginConfig',
          },
          // Event to handle CRM logout. It transitions to the 'logout' state and performs necessary logout side effects.
          LOGOUT_CRM: {
            target: 'logout',
            actions: 'logoutSideEffects',
          },
          // Event triggered when a logout occurs from another browser tab. It transitions to the 'logoutFromOtherTab' state.
          LOGOUT_CRM_FROM_OTHER_TAB: {
            target: 'logoutFromOtherTab',
          },
          // Event for handling a login from a different browser tab. It transitions to the 'handleTabBasedLogin' state if the CRM is disconnected.
          LOGIN_CRM_FROM_OTHER_TAB: {
            target: 'handleTabBasedLogin',
            cond: 'isCRMOffline',
          },
          // Event to set CRM configuration. It transitions to the 'setConfig' state.
          SET_CONFIG: {
            target: 'setConfig',
          },
          // Event for setting a new access token. It performs an action to update the access token and then returns to the 'idle' state.
          SET_ACCESS_TOKEN: {
            actions: 'setAccessToken',
            target: '#crmMachine.idle',
          },
        },
        states: {
          idle: {},
        },
      },
      login: {
        // The 'login' state manages the CRM login process. It clears previous errors, sets the authentication state,
        // and invokes the 'authenticateCRM' service for handling the authentication. On successful login, it transitions to
        // the 'refreshingData' state to synchronize CRM data.
        entry: [
          'clearErrors',
          assign((context) => {
            context.isAuthenticating = true
            context.crmSyncStatus = Types.CRMSyncStatus.SYNCING
          }),
        ],
        exit: assign((context) => {
          context.isAuthenticating = false
        }),
        invoke: {
          id: 'authenticateCRM',
          src: 'authenticateCRM',
          onError: { actions: ['clearSession', 'logError'], target: '#crmMachine.showError' },
        },
        on: {
          // This event is triggered when session data needs to be loaded during the login process.
          // It performs actions to read the session data and synchronize the form configuration,
          // then transitions to the 'refreshingData.initializeIndexedDB' state for initiating data preparation and syncing.
          LOAD_SESSION_DATA: {
            actions: ['readSessionData', 'syncFormConfig'],
            // a soon the user is logged in, we can refresh the data
            target: '#crmMachine.refreshingData.initializeIndexedDB',
          },
          // This event handles the scenario where the state machine needs to revert back to the 'idle' state from 'login'.
          // It clears the current session and transitions back to the 'idle' state.
          BACK_TO_IDLE: {
            target: '#crmMachine.idle',
            actions: ['clearSession'],
          },
          // Event to handle the loading of new session data upon a successful token refresh during the login process.
          // It updates the session data, sets the syncing status, updates the last synced time,
          // handles login from other tabs if necessary, clears session errors, and then returns to the 'idle' state.
          LOAD_REFRESH_TOKEN_DATA: {
            actions: [
              'readSessionData', 'setSyncingDone', 'setLatsSyncedAt', 'handleTabBasedLogin', 'clearSessionError',
            ],
            target: '#crmMachine.idle',
          },
        },
      },
      refreshingData: {
        // In the 'refreshingData' state, the CRM data is synchronized. It first prepares the IndexedDB ('initializeIndexedDB')
        // and then syncs CRM data ('syncData'). It handles data syncing errors and invalid session errors, possibly
        // triggering a token refresh if needed.
        description: 'Refresh the data from the crm',
        tags: ['REFRESH_DATA'],
        states: {
          // The 'initializeIndexedDB' sub-state is responsible for preparing the IndexedDB for data synchronization.
          // It invokes the 'initializeIndexedDB' service to ensure that the database is ready for incoming data.
          // On successful completion, it transitions to the 'syncData' sub-state. If there's an error,
          // it moves to the 'showError' state to handle the error.
          initializeIndexedDB: {
            invoke: {
              id: 'initializeIndexedDB',
              src: 'initializeIndexedDB',
              onDone: { target: '#crmMachine.refreshingData.syncData' },
              onError: {
                target: '#crmMachine.showError',
                actions: 'logError',
              },
            },
          },
          // In 'syncData', the actual data synchronization with the CRM occurs.
          // It invokes the 'synchronizeCRMData' service to fetch and update CRM data.
          // Upon successful data sync, it transitions back to the 'idle' state and performs several actions like
          // updating sync status, setting the last synced time, and potentially starting form configuration synchronization.
          // In case of errors, it handles invalid sessions by potentially refreshing the token, or moves to 'showError' state for other errors.
          syncData: {
            invoke: {
              id: 'synchronizeCRMData',
              src: 'synchronizeCRMData',
              tags: ['REFRESH_DATA'],
              onDone: {
                target: '#crmMachine.idle',
                actions: ['syncData', 'setSyncingDone', 'setLatsSyncedAt', 'handleTabBasedLogin', 'startConfigSync'],
              },
              onError: [
                {
                  cond: 'isSessionInvalid',
                  target: '#crmMachine.refreshToken',
                  actions: ['increaseRetryAttempts', 'validateNumberOfAttempt'],
                },
                {
                  target: '#crmMachine.showError',
                  actions: 'logError',
                },
              ],
            },
          },
        },
        on: {
          // Event to handle logout during the data refreshing process. It transitions to the 'logout' state.
          LOGOUT_CRM: {
            target: '#crmMachine.logout',
          },
        },
      },
      refreshToken: {
        // The 'refreshToken' state is responsible for refreshing the CRM session token.
        // It clears any previous errors and invokes the 'refreshToken' service. Based on the outcome,
        // it either retries data refreshing or proceeds to the 'logout' state in case of persistent token issues.
        entry: ['clearErrors'],
        tags: ['REFRESH_TOKEN'],
        invoke: {
          id: 'refreshToken',
          src: 'refreshToken',
          onDone: { target: '#crmMachine.refreshingData.initializeIndexedDB' },
          onError: [
            {
              target: '#crmMachine.logout',
              cond: 'isRefreshTokenError',
              actions: ['logError', 'analyticsRefreshTokenError'],
            },
            {
              target: '#crmMachine.showError',
              actions: ['logError', 'analyticsRefreshTokenError'],
            }],
        },
      },
      showError: {
        // The 'showError' state is entered upon encountering an error. It logs the error and sets it in the storage.
        // It provides options to retry login if the error is related to authentication.
        entry: ['logError', 'setStorageError'],
        on: {
          LOGIN_CRM: [
            {
              target: '#crmMachine.refreshToken',
              cond: 'isRequiredRefreshTokenAction',
            },
            {
              target: '#crmMachine.login',
              cond: 'isRequiredAuthAction',
            }, {
              actions: ['syncFormConfig'],
              target: '#crmMachine.refreshingData.initializeIndexedDB',
            }],
        },
      },
      setConfig: {
        // 'setConfig' state is for setting up CRM configuration. It loads sync time, actors, and then checks if
        // the last sync status was an error or if it can proceed to auto-sync.
        entry: ['setConfig', 'loadLastSyncAt', 'loadActors'],
        after: {
          0: [
            {
              target: '#crmMachine.showError',
              cond: 'hasLastSyncFailed',
              actions: 'checkLastSyncStatus',
            },
            {
              target: '#crmMachine.refreshingData.initializeIndexedDB',
              cond: 'isEligibleForAutoSync',
            }, {
              target: '#crmMachine.idle',
              cond: 'isSyncStatusOk',
              actions: ['checkSyncStatus'],
            }],
        },
      },
      setLoginConfig: {
        // In the 'setLoginConfig' state, the CRM configuration is set up specifically for the login process.
        // It performs actions to set the config, load the last sync time, load actors, and sync form configuration.
        // After these actions, it transitions to the 'refreshingData' state or falls back to 'idle'.
        entry: ['setConfig', 'loadLastSyncAt', 'loadActors', 'syncFormConfig'],
        after: {
          0: [{ // The '0' here indicates an immediate transition after the entry actions are complete.
            target: '#crmMachine.refreshingData.initializeIndexedDB',
          }, {
            target: '#crmMachine.idle',
          }],
        },
      },
      logout: {
        // The 'logout' state handles the user logging out. It performs necessary cleanups and disconnections.
        entry: ['clearErrors', 'disconnecting', 'logoutFromOtherTab'],
        invoke: {
          id: 'logOut',
          src: 'logOut',
          onDone: { target: '#crmMachine.idle', actions: ['clearSession', 'disconnected'] },
          onError: { target: '#crmMachine.showError', actions: ['clearSession', 'disconnected'] },
        },
        on: {
          LOAD_SESSION_DATA: {
            actions: 'readSessionData',
          },
        },
      },
      logoutFromOtherTab: {
        // The 'logoutFromOtherTab' state handles the scenario where the user logs out from another browser tab.
        // It clears the session and updates the connection status to 'DISCONNECTED', then transitions back to the 'idle' state.
        entry: ['clearSession', 'disconnected'],
        on: {
          '': {
            target: '#crmMachine.idle',
          },
        },
      },
      // This state manages the case where the user logs in from a different browser tab.
      // It invokes the 'handleTabBasedLogin' service to handle this specific login scenario.
      handleTabBasedLogin: {
        invoke: {
          id: 'handleTabBasedLogin',
          src: 'handleTabBasedLogin',
        },
      },
    },
    on: {
      // Event to handle CRM logout
      LOGOUT_MESSAGE: {
        actions: 'handleLogoutMessage',
      },
      // Event to set the form synchronization status. It cleans any error status related to form synchronization and updates the sync status.
      // It transitions back to the 'idle' state if synchronization is finished.
      SET_FORM_SYNC_STATUS: [{
        actions: ['clearErrorFormStatus', 'setFormSyncStatus'],
        target: '#crmMachine.idle',
        cond: 'hasSyncCompleted',
      },
      // If synchronization is not finished, this event updates the form sync status.
      {
        actions: ['setFormSyncStatus'],
      }],
      // Event for handling errors in form synchronization. It sets the error status and loads actors, then transitions to the 'showError' state.
      SET_FORM_SYNC_ERROR_STATUS:
        {
          actions: ['setFormSyncErrorStatus', 'loadActors'],
          target: '#crmMachine.showError',
        },
    },
  },
  {
    // [Side Effects]
    actions: {
      // This action logs an analytics event specifically for token refresh errors.
      // It tracks the event 'CRM_TOKEN_INVALID' with relevant CRM integration details.
      analyticsRefreshTokenError: assign((context) => {
        analytics.track('CRM_TOKEN_INVALID', {
          category: 'CRM',
          integration: context.crmConfig?.crmIntegrationType,
          date: new Date().toISOString(),
        })
      }),
      // Clears the CRM session information from the context and updates the connection status to 'DISCONNECTED'.
      // It also resets the CRM synchronization status to 'IDLE'.
      clearSession: assign((context) => {
        context.crmSession = undefined
        context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED
        context.crmSyncStatus = Types.CRMSyncStatus.IDLE
      }),
      // Updates the context with the latest CRM data (like accounts and config items) received from the synchronization process.
      syncData: assign((context, event) => {
        const evt = event as Types.SYNC_DATA_EVENT
        context.configItem = evt.data.configItem
      }),
      // Clears any form-related error statuses if the form sync status indicates synchronization has been completed.
      // It also resets the error and session error in the context and updates the local storage.
      clearErrorFormStatus: assign((context, event) => {
        const evt = event as Types.SET_FORM_SYNC_STATUS
        const { status } = evt.data || {}
        const validErrors = [ERR_SYNCING_CONFIG_FORM, ERROR_MESSAGES.TOKEN_EXPIRED]
        // we need to check if the error is related to the form config
        if (validErrors.includes(context?.error || '') && status === Types.CRMSyncStatus.SYNCED) {
          context.error = undefined
          context.sessionError = undefined
          context.connectionStatus = Types.CRMConnectionStatus.CONNECTED
          context.crmSession = crmUtil.loadAuthInformation()
        }

        localStorage.removeItem(CRM_LAST_SYNC_STATUS)
      }),
      // Clears any existing errors from the context and removes the last synchronization status from local storage.
      clearErrors: assign((context) => {
        context.error = undefined
        localStorage.removeItem(CRM_LAST_SYNC_STATUS)
      }),
      // Specifically clears session-related errors from the context.
      clearSessionError: assign((context) => {
        context.sessionError = undefined
      }),
      // Checks the last synchronization status from local storage and updates the context accordingly,
      // particularly useful for handling scenarios where the last sync resulted in an error.
      checkLastSyncStatus: assign((context) => {
        const lastSyncStatus = localStorage.getItem(CRM_LAST_SYNC_STATUS)

        // if the last sync status is not present or is not an error
        if (!lastSyncStatus || lastSyncStatus === null || !ERRORS_ARRAY.includes(lastSyncStatus)) {
          return
        }

        // if the refresh token is expired, we need also to remove the auth information from the local storage
        if (lastSyncStatus === ERROR_MESSAGES.EXPIRED_ACCESS_REFRESH_TOKEN) {
          localStorage.removeItem(CRM_AUTH_INFORMATION)
        }

        context.error = lastSyncStatus
        context.sessionError = lastSyncStatus
        context.crmSyncStatus = Types.CRMSyncStatus.ERROR
        context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED
      }),
      // When logging-out, if the CRM is in a “SYNCING” state, clear out CRM rests given that Beacon never finished syncing
      handleLogoutMessage: assign((context) => {
        if (context.crmSyncStatus === Types.CRMSyncStatus.SYNCING) {
          context.crmSyncStatus = Types.CRMSyncStatus.IDLE
          context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED
          context.crmSession = undefined
          crmUtil.logOutCRM()
        }
      }),
      // Loads authentication information from local storage and updates the context's CRM session and connection status accordingly.
      loadAuthFromLocalStorage: assign((context) => {
        context.crmSession = crmUtil.loadAuthInformation()

        context.connectionStatus = context.crmSession?.accessToken
          ? Types.CRMConnectionStatus.CONNECTED
          : Types.CRMConnectionStatus.DISCONNECTED
      }),
      // Initializes various actors (like tenant, crmFormConfigActor) required for CRM functionalities based on the current CRM configuration.
      loadActors: assign((context) => {
        if (!context.crmConfig?.meetingSetting ||
          !context.crmConfig?.instanceUrl ||
          context.crmFormConfigActor ||
          context.connectionStatus !== Types.CRMConnectionStatus.CONNECTED
        ) {
          return;
        }
        const config = current(context.crmConfig);
        context.crmFormConfigActor = spawn(
          configSyncMachine(config),
          'crmFormConfigActor',
        )

        // intercept the message from the form config actor
        context.crmFormConfigActor?.subscribe((state) => {
          logger.CRMMachine.debug('form config actor state', state);
        })

        if (!islastSyncLessThan24Hours(context.lastSyncedAt) && context.crmSyncStatus !== Types.CRMSyncStatus.ERROR) {
          context.crmFormConfigActor.send('START')
        }
      }),
      // Loads the last synchronization time from local storage and updates the context.
      loadLastSyncAt: assign((context) => {
        const lastSyncAt = localStorage.getItem(CRM_LAST_SYNC_AT)

        if (lastSyncAt === null) {
          return
        }

        context.lastSyncedAt = new Date(lastSyncAt)
      }),
      // Updates the context with CRM session data from the event payload, typically used when new session data is loaded.
      readSessionData: assign((context, event) => {
        const evt = event as Types.LOAD_SESSION_DATA
        context.crmSession = evt.payload.crmSession
      }),
      // Logs an error into the context, updates the CRM sync status to 'ERROR', and handles specific error scenarios like token refresh and malformed queries.
      logError: assign((context, event) => {
        context.crmSyncStatus = Types.CRMSyncStatus.ERROR;
        if (typeof event === 'object') {
          const evt = event as Types.CRM_ERROR;
          const error = evt.data?.message || evt.data?.errorCode || evt.data?.errors?.[0]?.message;

          // Call the corresponding error handler based on the event type
          const handler = errorHandlers[evt.type] || errorHandlers.default;
          handler(context, error, evt.data);

          if (error) {
            logger.CRMMachine.debug('error', error);
          }
        }
      }),
      // Updates the CRM configuration in the context with the payload received from the event.
      // Also, determines the CRM availability status based on the meeting settings in the configuration.
      setConfig: assign((context, event) => {
        const evt = event as Types.SET_CONFIG
        context.crmConfig = evt.payload

        // TODO: Implement retry functionality for when the authentication token becomes invalid.
        context.crmAvailabilityStatus = context.crmConfig?.meetingSetting
          ? Types.CRMAvailabilityStatus.ENABLED
          : Types.CRMAvailabilityStatus.DISABLED
      }),
      // Updates the CRM synchronization status for forms based on the event data.
      setFormSyncStatus: assign((context, event) => {
        const evt = event as Types.SET_FORM_SYNC_STATUS
        const { status } = evt.data || {}

        if (status) {
          context.crmSyncStatus = status
        }
      }),
      // Sets the last synchronization time both in the context and local storage.
      // Also, resets the retry attempt count and logs a CRM sync analytics event.
      setLatsSyncedAt: assign((context) => {
        localStorage.setItem(CRM_LAST_SYNC_AT, new Date().toISOString())
        context.lastSyncedAt = new Date()
        context.numberOfRetries = 0

        analytics.track('CRM_SYNC', {
          category: 'CRM',
          integration: context.crmConfig?.crmIntegrationType,
          lastSyncedAt: context.lastSyncedAt,
        })
      }),
      // Specifically handles form synchronization error status.
      // Updates the context with the error status, error message and stops the form config actor if necessary.
      setFormSyncErrorStatus: assign((context, event) => {
        const evt = event as Types.SET_FORM_SYNC_ERROR_STATUS
        context.crmSyncStatus = evt.payload?.status || Types.CRMSyncStatus.ERROR
        context.error = evt.payload?.error || ERR_SYNCING_CONFIG_FORM
        context.sessionError = evt.payload?.error || ERR_SYNCING_CONFIG_FORM
        // as the config reach to final state we need to clean the actor from the context
        context.crmFormConfigActor?.stop?.()
        context.crmFormConfigActor = undefined
      }),
      // Updates the connection status in the context to 'DISCONNECTING' during the logout process.
      disconnecting: assign((context) => {
        context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTING
      }),
      // Sets the connection status to 'DISCONNECTED' in the context once the logout process is complete.
      disconnected: assign((context) => {
        context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED
      }),
      // Updates the access token in the CRM session context with the new token received from the event payload.
      setAccessToken: assign((context, event) => {
        const evt = event as Types.SET_ACCESS_TOKEN
        if (!context.crmSession) {
          return
        }
        context.crmSession.accessToken = evt.data.accessToken
      },
      ),
      // Updates the CRM synchronization status to 'SYNCED' in the context, indicating that syncing is complete.
      setSyncingDone: assign((context) => {
        context.crmSyncStatus = Types.CRMSyncStatus.SYNCED
      }),
      // Checks and updates the CRM synchronization status based on the last sync time and current connection status.
      checkSyncStatus: assign((context) => {
        const lastSyncAt = localStorage.getItem(CRM_LAST_SYNC_AT)
        if (context.connectionStatus === Types.CRMConnectionStatus.CONNECTED && lastSyncAt) {
          context.crmSyncStatus = Types.CRMSyncStatus.SYNCED
        }
      }),
      // Increments the number of retry attempts in the context, used in scenarios like token refresh.
      increaseRetryAttempts: assign((context) => {
        context.numberOfRetries += 1
      }),
      // Triggers the start of form configuration synchronization if the respective actor is available in the context.
      startConfigSync: assign((context) => {
        if (!context.crmFormConfigActor) {
          return;
        }

        context.crmFormConfigActor.send('START')
      }),
      // Initializes or triggers the form configuration synchronization process based on the current CRM configuration.
      syncFormConfig: assign((context) => {
        const config = current(context.crmConfig);
        if (!context.crmFormConfigActor && config) {
          context.crmFormConfigActor = spawn(
            configSyncMachine(config),
            'crmFormConfigActor',
          )
        }
      }),
      // Sends a logout event to the external tab actor, handling the scenario of logging out from another browser tab.
      logoutFromOtherTab: assign((context) => {
        context.externalTabActor?.send({ type: 'LOGOUT_CRM_FROM_OTHER_TAB' })
      }),
      // Sends a login event to the external tab actor, managing the situation where the user logs in from a different browser tab.
      handleTabBasedLogin: assign((context) => {
        context.externalTabActor?.send({ type: 'LOGIN_CRM_FROM_OTHER_TAB' })
      }),
      // Performs additional side effects during logout, such as setting a session error if the logout was due to a token refresh error.
      logoutSideEffects: assign((context, event) => {
        const evt = event as Types.LOGOUT_CRM;
        if (evt.data?.reason === Types.LOGOUT_DUE_TO.ERROR_REFRESHING_TOKEN) {
          context.sessionError = ERROR_MESSAGES.TOKEN_EXPIRED
        }
      }),
      // Stores the current error in local storage for future reference, especially useful in tracking synchronization errors.
      setStorageError: assign((context) => {
        if (context.error) {
          localStorage.setItem(CRM_LAST_SYNC_STATUS, context.error)
        }
      }),
      validateNumberOfAttempt: assign((context) => {
        if (context.numberOfRetries >= 3) {
          context.error = ERROR_MESSAGES.TOKEN_EXPIRED
          context.sessionError = ERROR_MESSAGES.TOKEN_EXPIRED
          context.crmSession = undefined
          // remove session information
          localStorage.removeItem(CRM_AUTH_INFORMATION)
          context.crmSyncStatus = Types.CRMSyncStatus.IDLE
        }
      }),
    },
    services: {
      // 'authenticateCRM' service handles CRM authentication. It removes previous authentication information, triggers
      // authentication processes, and listens for updates in authentication status via storage events.
      authenticateCRM: (ctx) => async (callback) => {
        try {
          // if the user is logged in, we need to remove the auth information
          localStorage.removeItem(CRM_AUTH_INFORMATION)
          // trigger the popup
          await crmUtil.getCRMAuthUrl(true)
        } catch (e: any) {
          if (e.message === errors.not_allowed_pop_up) {
            throw new Error(errors.not_allowed_pop_up)
          }

          // should navigate go to error state
          throw new Error(JSON.stringify(e))
        }

        // we need to listen to the storage event
        // in order to know if the auth information has been updated
        const handleStorageChange = (e) => {
          if (e.key === CRM_AUTH_INFORMATION || e.type === CRM_STORAGE_UPDATE) {
            const SESSION_ERRORS = [
              ERROR_MESSAGES.TOKEN_EXPIRED,
              ERROR_MESSAGES.SESSION_EXPIRED,
            ]
            const isSessionError = SESSION_ERRORS.includes(ctx.sessionError || '')
            const event: Types.LOAD_SESSION_DATA | Types.LOAD_REFRESH_TOKEN_DATA = isSessionError ? {
              type: 'LOAD_REFRESH_TOKEN_DATA',
              payload: {
                crmSession: crmUtil.loadAuthInformation(),
              },
            } : {
              type: 'LOAD_SESSION_DATA',
              payload: {
                crmSession: crmUtil.loadAuthInformation(),
              },
            }
            if (event.payload.crmSession?.accessToken) {
              callback(event)

              analytics.track('CRM_CONNECT', {
                category: 'CRM',
                integration: ctx.crmConfig?.crmIntegrationType,
              })
            }
          }
          else if (e.type === CRM_CONNECTION_CLOSED) {
            const event: Types.EVT_BACK_TO_IDLE = {
              type: 'BACK_TO_IDLE',
            }
            callback(event)
          }
        }

        // TODO BEAC-3746: try to handle this using the broad cast channel
        // listen for the auth information is persisted in the local storage
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener(CRM_STORAGE_UPDATE, handleStorageChange);
        window.addEventListener(CRM_CONNECTION_CLOSED, handleStorageChange);
        return () => {
          window.removeEventListener('storage', handleStorageChange);
          // clean the events
          window.removeEventListener(CRM_STORAGE_UPDATE, handleStorageChange);
          window.removeEventListener(CRM_CONNECTION_CLOSED, handleStorageChange);
        }
      },
      // 'logOut' service manages the process of logging out from the CRM. It clears CRM session data and the IndexedDB.
      logOut: async (context) => {
        await crmUtil.logOutCRM(context.crmSession?.accessToken)
        const event: Types.LOAD_SESSION_DATA = {
          type: 'LOAD_SESSION_DATA',
          payload: {
            crmSession: crmUtil.loadAuthInformation(),
          },
        }

        await CRMDB.clearDB()
        return event
      },
      // 'initializeIndexedDB' service prepares the IndexedDB for data synchronization, checking user session and CRM configuration.
      initializeIndexedDB: async (context) => {
        if (!context.crmSession) {
          // should navigate to the error state
          throw new Error('No session to refresh data')
        }

        if (!context.crmSession.authInformation.userInfo.id) {
          throw new Error('No user id to refresh data')
        }

        if (!context.crmConfig) {
          throw new Error('No config present please reach your administrator')
        }

        const userId = context.crmSession.authInformation.userInfo.id.toString()
        const configItem = await CRMDB.getById<Config>(TableEnum.CONFIG_OBJECT, userId)

        const configObj: Config = {
          id: userId,
          name: context.crmSession.authInformation.userInfo.displayName,
          configBase64: base64Encode(JSON.stringify(context.crmConfig)),
        }

        // if the config object is not present, we need to create it
        // and we can assume it is the first time the user is logged in
        if (!configItem) {
          return await CRMDB.upsert(TableEnum.CONFIG_OBJECT, configObj)
        }

        // if the config is present, we need to check if the user has changed
        // if the hash is different, we need to clear the database and update the config
        if (
          configItem.id !== configObj.id ||
          configItem.configBase64 !== configObj.configBase64
        ) {
          analytics.track('CRM_CONFIG_CHANGE', {
            category: 'CRM',
            integration: context.crmConfig?.crmIntegrationType,
          })

          await CRMDB.clearDB()
          return await CRMDB.upsert(TableEnum.CONFIG_OBJECT, configObj)
        }
        else {
          return { type: 'DONE' }
        }
      },
      // 'synchronizeCRMData' service handles the synchronization of CRM data. It fetches and updates CRM-related data like accounts.
      synchronizeCRMData: async (context) => {
        if (!context.crmSession) {
          // should navigate to the error state
          throw new Error('No session to refresh data')
        }

        if (!context.crmConfig) {
          throw new Error('No config present please reach your administrator')
        }

        // init console.log timer
        logger.CRMMachine.debug('start syncing config object');
        const userId = context.crmSession.authInformation.userInfo.id.toString()
        const configItem = await CRMDB.getById<Config>(TableEnum.CONFIG_OBJECT, userId)
        logger.CRMMachine.debug('end syncing config object');

        // https://trailhead.salesforce.com/trailblazer-community/feed/0D54S00000A8KBjSAN
        // On regards og how to check if a token is still valid
        // get accounts from the crm
        logger.CRMMachine.debug('start syncing account object');
        const accountObj = await crmUtil.getAccountData(context.crmConfig, configItem.lastSyncTime)
        logger.CRMMachine.debug('end syncing account object');

        if (!accountObj) {
          throw new Error('No accounts found')
        }

        const updatedConfigObject = { ...configItem, lastSyncTime: accountObj.lastSyncTime }

        if (accountObj.records.length === 0) {
          logger.CRMMachine.warn('No accounts to sync')

          logger.CRMMachine.debug('start upsert account');
          await CRMDB.upsert(TableEnum.CONFIG_OBJECT, updatedConfigObject)
          logger.CRMMachine.debug('end upsert account');

          if (context.crmConfig.meetingSetting) {
            context.crmFormConfigActor?.send('START')
          }

          return {
            configItem: updatedConfigObject,
          }
        }

        logger.CRMMachine.debug('start upsert account');
        await CRMDB.upsert(TableEnum.ACCOUNT, accountObj.records)
        logger.CRMMachine.debug('end upsert account');
        logger.CRMMachine.debug('start upsert config');
        await CRMDB.upsert(TableEnum.CONFIG_OBJECT, updatedConfigObject)
        logger.CRMMachine.debug('end upsert config');

        if (context.crmConfig.meetingSetting) {
          context.crmFormConfigActor?.send('START')
        }

        return {
          configItem: updatedConfigObject,
        }
      },
      // 'refreshToken' service attempts to refresh the CRM session token if the current token is invalid or expired.
      refreshToken: async (context) => {
        if (!context.crmSession) {
          // should navigate to the error state
          throw new Error('No session to refresh data')
        }

        await crmUtil.refreshTokenCRM(context.crmSession.accessToken, context.crmSession.refreshToken)
        // TODO: move this to an action, we cant moved at this point due the typing
        // the typing is payload.crmSession but as this is an async function it is returning this form data.payload.crmSession
        context.crmSession = crmUtil.loadAuthInformation()
      },
      // 'handleTabBasedLogin' service handles the login process from other tabs. It refreshes the CRM session token
      handleTabBasedLogin: async () => {
        // TODO: find a better tokens without the risk of having two syncs at the same time and get issues on idexeddb
        reloadApp()
      },
    },
    // [Conditional Checks]
    guards: {
      // Guard to check if the error is related to token refresh. Returns true if token refresh fails.
      isRefreshTokenError: (_, event) => {
        const evt = event as Types.CRM_ERROR
        const isTokenRefreshError = evt.data.errors?.some(
          (error) => error.message === ERROR_MESSAGES.CANNOT_REFRESH_TOKEN,
        )
        return !!isTokenRefreshError && evt.type === 'error.platform.refreshToken'
      },
      // Guard to check if the last synchronization status was an error. Returns true if the last sync status was an error.
      hasLastSyncFailed: () => {
        const lastSyncStatus = localStorage.getItem(CRM_LAST_SYNC_STATUS)
        return !!lastSyncStatus && lastSyncStatus !== null
      },
      // Guard to check if the current state is not an error. Returns true if the current sync status is not an error.
      isSyncStatusOk: (context) => {
        return context.crmSyncStatus !== Types.CRMSyncStatus.ERROR
      },
      // Guard to check for an invalid session based on error code. Returns true for specific invalid session error codes.
      isSessionInvalid: (ctx, event) => {
        const evt = event as Types.CRM_ERROR
        return (evt.data.errorCode === INVALID_SESSION_ID || evt.data.errorCode === INVALID_SESSION_ID ) &&
          ctx.numberOfRetries < 3
      },
      // Guard to check if the process entry is invalid. Returns true for specific error types related to sync errors.
      isSyncErrorStatus: (_, event) => {
        const evt = event as Types.SYNC_ERROR_STATUS
        return evt.type === 'SYNC_ERROR_STATUS' || evt.type === 'SET_FORM_SYNC_ERROR_STATUS'
      },
      // Guard to check if the CRM is enabled and connected. Returns true if CRM is connected.
      isCRMConnected: (context) => {
        return context.connectionStatus === Types.CRMConnectionStatus.CONNECTED
      },
      // Guard to check if auto-syncing is possible. Returns true if CRM is connected and last sync was more than 24 hours ago.
      isEligibleForAutoSync: (context) => {
        const isConnected = context.connectionStatus === Types.CRMConnectionStatus.CONNECTED

        const lastSyncTimeWithin24Hours = islastSyncLessThan24Hours(context.lastSyncedAt)

        return isConnected && !lastSyncTimeWithin24Hours
      },
      // Guard to determine if the CRM is disconnected. Returns true if the CRM connection status is disconnected.
      isCRMOffline: (context) => {
        return context.connectionStatus === Types.CRMConnectionStatus.DISCONNECTED
      },
      // Guard to check if authentication should run. Returns true if there are specific authentication-related errors.
      isRequiredAuthAction: (context) => {
        const errors = [
          ERROR_MESSAGES.TOKEN_EXPIRED,
          ERROR_MESSAGES.EXPIRED_ACCESS_REFRESH_TOKEN,
          ERROR_MESSAGES.CANNOT_REFRESH_TOKEN,
          ERR_INVALID_SESSION_ID,
        ]
        return !!context.error && errors.includes(context.error)
      },
      // Guard to check if the refresh token action is required. Returns true if there are specific refresh token-related errors.
      isRequiredRefreshTokenAction: (context) => {
        const errors = [
          ERROR_MESSAGES.SESSION_EXPIRED,
        ]

        return !!context.error && errors.includes(context.error)
      },
      // Guard to check if the synchronization process has finished. Returns true if the sync status is 'SYNCED'.
      hasSyncCompleted: (context, event) => {
        const evt = event as Types.SET_FORM_SYNC_STATUS
        const { status } = evt.data || {}

        return status === Types.CRMSyncStatus.SYNCED
      },
    },
  },
)

// [Error Handlers]
export const ERROR_TYPES = {
  REFRESH_TOKEN: 'error.platform.refreshToken',
  SYNC_CRM_DATA: 'error.platform.synchronizeCRMData',
  DEFAULT: 'default',
  MALFORMED_QUERY: 'MALFORMED_QUERY',
  NO_SESSION: 'No session to refresh data',
};

// [Error Messages]
const errorHandlers = {
  [ERROR_TYPES.REFRESH_TOKEN]: (context, error) => {
    context.sessionError = error === ERROR_MESSAGES.EXPIRED_ACCESS_REFRESH_TOKEN
      ? ERROR_MESSAGES.EXPIRED_ACCESS_REFRESH_TOKEN
      : ERROR_MESSAGES.TOKEN_EXPIRED;
    context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED;
    context.error = error;
  },
  [ERROR_TYPES.SYNC_CRM_DATA]: (context, errorCode, eventData) => {
    const erroCodes = [errorCode, eventData?.errorCode || '']
    if (erroCodes.some((code) => code === ERROR_MESSAGES.MALFORMED_QUERY || code === ERROR_TYPES.MALFORMED_QUERY)) {
      context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED;
      context.sessionError = ERROR_MESSAGES.MALFORMED_QUERY;
    }
    context.error = errorCode;
  },
  [ERROR_TYPES.DEFAULT]: (context, error) => {
    if (error === ERROR_TYPES.NO_SESSION) {
      context.sessionError = ERROR_MESSAGES.TOKEN_EXPIRED;
      context.error = ERROR_MESSAGES.TOKEN_EXPIRED;
      context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED;
    } else if (error) {
      context.error = error;
    }
  },
};

// [Helper Functions]

/* *
  * Checks if the last sync time is less than 24 hours ago.
  * @param {Date} lastSyncTime The last sync time to check.
  * @returns {boolean} Returns true if the last sync time is less than 24 hours ago.
  *
* */
export const islastSyncLessThan24Hours = (lastSyncTime) => {
  if (!lastSyncTime || lastSyncTime === null) {
    return true
  }

  const now = new Date()
  const diff = now.getTime() - lastSyncTime.getTime()
  const hours = Math.floor(diff / (1000 * 60 * 60))

  return hours < 24
}

// base 64 encode
/* *
  * Encodes a string to base64.
  * @param {string} data The string to encode.
  * @returns {string} Returns the encoded string.
* */
const base64Encode = (data: string) => {
  const buff = Buffer.from(data, 'utf-8')
  return buff.toString('base64')
}

export type CRMStatus = Pick<Types.CRMStateValue, 'value'> & {
  matches: (value: StateValue) => ReturnType<typeof matchesState>
}

export type crmMachineType = ReturnType<typeof crmMachine>

export default crmMachine
