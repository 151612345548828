import React, { useCallback, useMemo } from 'react'
import { FlatList, FlatListProps, StyleSheet, useWindowDimensions, View } from 'react-native'
import {
  GridList,
  DNABox,
  DNACard,
  DNADivider,
  DNACheckbox,
  Iffy,
  luxColors,
  util,
  DNAText,
  DNAButton,
  DNAChip,
} from '@alucio/lux-ui'
import { RecyclerListView, RecyclerListViewProps, DataProvider, LayoutProvider } from 'recyclerlistview'
import { Variant, DocumentORM } from 'src/types/types'
import { SelectedDocuments } from 'src/screens/MyLibrary/DocumentSelectManager'
import { useSort } from 'src/components/DNA/hooks/useSort'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { DEFAULT_PAGE_SIZE, usePagination } from 'src/components/DNA/Pagination/DNAPagination'

import { useAllDocumentsLength } from 'src/state/redux/selector/document'
import { useUserTenant } from 'src/state/redux/selector/user'
import { BindDocumentContextActions, useDNADocumentActions } from '../Document/DNADocument.actions'
import { useAppSettings } from 'src/state/context/AppSettings'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DocumentListRow, FieldHeader, getFieldConfigsForTenant, ListFieldConfig, ScheduleDocumentIcon } from './common'
import { useLocation } from 'react-router'
import ROUTES from 'src/router/routeDef'
import DNADocumentThumbnail from '../Document/DNADocumentThumbnail'
import { useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2'
import { useDocumentSearchV2Context as useSearchContextProxy } from 'src/hooks/useDocumentSearchV2.proxy'
import DNADocumentContextMenu from '../Document/DNADocumentContextMenu/DNADocumentContextMenu'
import useCurrentPage from '../hooks/useCurrentPage'
import { CustomLabelDisplay } from '../FlatList/Rows/default/CustomLabelDisplay'
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models'
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText'
import DNADocumentChip from '../Document/DNADocumentChip'
import DNAPopover from '../Popover/DNAPopover'

export interface DNADocumentListProps {
  documents: DocumentORM[],
  emptyVariant?: EmptyVariant,
  selectEnabled?: boolean,
  selectedDocuments?: SelectedDocuments,
  onSelectDocuments?: (documents: DocumentORM[]) => void,
  isSearch?: boolean,
  filtersVisible?: boolean,
  pageNumber?: number,
}
export interface DNADocumentListComponent extends React.FC<DNADocumentListProps> {
  Paginated: typeof DNADocumentListPaginated
}

type ExtendedState = {
  documentActions: BindDocumentContextActions,
  selectedDocuments?: SelectedDocuments,
  selectEnabled?: boolean,
  fieldConfigs: ListFieldConfig[],
  isOnline: boolean,
  isDesktop: boolean,
  onThumbnailPress: (documentORM) => void,
  onSelectDocument: (documentORM) => void | undefined,
}

const S = StyleSheet.create({
  documentThumbnailBorder: {
    borderWidth: 1,
    borderColor: luxColors.border.primary,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  HeaderCol: {
    paddingVertical: 12,
  },
  DesktopContainer: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    overflow: 'hidden',
  },
  MobileContainer: {
    backgroundColor: luxColors.info.primary,
  },
  MobileHeader: {
    backgroundColor: colors['color-gray-10'],
    overflow: 'hidden',
    paddingHorizontal: 16,
    height: 49,
    maxHeight: 49,
  },
  MobileRow: {
    paddingHorizontal: 16,
  },
  SelectedFilters: {
    backgroundColor: colors['color-brand2-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
  DataCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  searchContentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  unPublishedVersionStatus: {
    borderWidth: 2,
    borderColor: luxColors.warning.quaternary,
  },
  excerptContent: {
    overflowWrap: 'break-word' as const,
    whiteSpace: 'pre-wrap' as const,
    maxWidth: '100%',
  },
  flex: {
    flex: 1,
  },
  selectAllCheckbox: {
    margin: 6,
  },
  searchCheckbox: {
    marginLeft: 8,
    marginRight: 24,
  },
  searchBookmarkButton: {
    width: 48,
    height: 48,
  },
})

export const RenderExcerpt: React.FC<{
  pageNumber?: number;
  textProps: DNATextProps;
  text: string | null | undefined;
}> = (props) => {
  const { pageNumber, textProps, text } = props;

  // Function to parse the HTML and safely render both text and bold elements
  // Created to prevent XSS attacks by rendering only specific tags
  const renderParsedHTMLWithBoldTags = useCallback((htmlString: string): JSX.Element[] => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      // Helper function to process nodes recursively
      const processNode = (node: Node, keyPrefix: string): JSX.Element | string | JSX.Element[] => {
        if (node.nodeType === Node.TEXT_NODE) {
          const textContent = node.textContent || '';
          const parts = textContent.split('\n');
          return parts.map((part, partIndex) => (
            <>
              <span key={`${keyPrefix}-${partIndex}`}>{part}</span>
              {partIndex < parts.length - 1 && <br key={`${keyPrefix}-${partIndex}-br`} />}
            </>
          ));
        }

        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          const tagName = element.tagName.toLowerCase();

          // Process all child nodes recursively
          const children = Array.from(element.childNodes).map((child, idx) =>
            processNode(child, `${keyPrefix}-${idx}`),
          );

          switch (tagName) {
            case 'b':
            case 'strong':
              return <strong key={keyPrefix}>{children}</strong>;
            case 'i':
            case 'em':
              return <i key={keyPrefix}>{children}</i>;
            case 'u':
              return <u key={keyPrefix}>{children}</u>;
            case 'br':
              return <br key={keyPrefix} />;
            default:
              // Wrap children in a fragment to return a single element
              return <>{children}</>;
          }
        }

        return <span key={keyPrefix} />;
      };

      // Wrap the mapped nodes in fragments to ensure we always return Elements
      return Array.from(doc.body.childNodes).map((node, index) => (
        <React.Fragment key={`root-${index}`}>
          {processNode(node, `root-${index}`)}
        </React.Fragment>
      ));
    } catch (error) {
      console.error('Failed to parse HTML:', error);
      return [<span key="error">Error parsing content</span>];
    }
  }, []);

  // Function to clean the text and add page number if available
  const renderContent = useCallback(
    (text: string | null | undefined, pageNumber?: number): JSX.Element => {
      const cleanedText = (text || '').trim().replace(/\n\s+/g, ' ');
      const contentWithPageNumber = cleanedText && pageNumber
        ? `Slide ${pageNumber} - ${cleanedText}`
        : cleanedText;

      return (
        <div style={S.excerptContent}>
          {renderParsedHTMLWithBoldTags(contentWithPageNumber)}
        </div>
      );
    },
    [renderParsedHTMLWithBoldTags], // Dependencies are correctly set
  );

  return (
    <DNABox appearance="row">
      <DNAText {...textProps} numberOfLines={textProps.numberOfLines} testID={textProps.testID}>
        {renderContent(text, pageNumber)}
      </DNAText>
    </DNABox>
  );
};

const DNADocumentListRow = React.memo(DocumentListRow);

interface RowProps {
  documentORM: DocumentORM
  selectEnabled?: boolean,
  onCheck?: (() => void) | undefined,
  checked?: boolean,
  isDropdown?: boolean,
  onPress?: ((pageNumber?: number) => void) | undefined,
}

const currentPageQuery = { exact: false }

const SearchRow: React.FC<RowProps> = (
  { documentORM, selectEnabled, checked, onCheck, isDropdown, onPress }) => {
  const { isOnline, deviceMode } = useAppSettings();
  const unavailableContent =
    !isOnline &&
    (!documentORM.relations.version.cachedDocumentVersionORM?.meta.assets.isContentCached ||
      documentORM.model.type === FileType.WEB)

  const { documentIdSnippetMap } = useDocumentSearchV2Context();
  const searchProxy = useSearchContextProxy()

  const snippetMap = searchProxy?.documentIdSnippetMap || documentIdSnippetMap;
  const snippet = snippetMap.get(documentORM.model.id);
  const route = useCurrentPage(currentPageQuery)
  const isPublisherRoute = !!route?.configOptions?.modules?.includes('publisher')
  const variant = isPublisherRoute ? Variant.publisher : Variant.MSL;

  const documentActions = useDNADocumentActions();

  const showSnippet = !!snippet?.documentExcerpt;
  const isDesktop = deviceMode === 'desktop';
  const showBookmarkButton = !unavailableContent && !isPublisherRoute && !isDropdown;
  const showContextMenu = !isDropdown;

  const handleRowClick = () => {
    onPress?.(snippet?.pageNumber);
  }

  const handleThumbnailClick = () => {
    documentActions.preview(documentORM, snippet?.pageNumber)();
  }

  const customThumbnail = (documentORM: DocumentORM, pageNumber?: number) => {
    if (!pageNumber) return documentORM.meta.assets.thumbnailKey;
    return documentORM.meta.assets.thumbnailKey?.replace(/(\d+)_small_thumb\.png$/, `${pageNumber}_small_thumb.png`);
  }

  const titleProps: DNATextProps = {
    b1: true,
    numberOfLines: 1,
    testID: 'drop-down-results-title',
  }

  const snippetProps: DNATextProps = {
    numberOfLines: isDropdown ? 3 : 0,
    status: 'flatAlt',
    testID: 'drop-down-results-snippet',
  }

  const customThumbnailKey = customThumbnail(documentORM, snippet?.pageNumber);
  const documentVersion = documentORM.relations.version.latestDocumentVersionORM
  const scheduledPublish = documentVersion.meta.schedule.publish.scheduledAt

  return (
    <DNACard
      appearance="flat"
      interactive={'pressable'}
      style={[
        { padding: 16 },
        unavailableContent && { opacity: 0.4 },
        checked && { backgroundColor: colors['color-brand2-5'] },
      ]}
      onPress={handleRowClick}
      disabled={unavailableContent}
    >
      <View style={S.searchContentWrapper}>
        <DNABox alignY="center">
          <Iffy is={selectEnabled}>
            <DNACheckbox
              context="altBg"
              status="primary"
              style={S.searchCheckbox}
              checked={checked}
              onChange={onCheck}
            />
          </Iffy>
          <Iffy is={showBookmarkButton}>
            <DNAButton
              appearance="ghost"
              status="gray"
              padding={isDesktop ? 'sm' : 'md'}
              rounded="md"
              onPress={documentActions.bookmark(documentORM)}
              iconLeft={documentORM.meta?.bookmark?.isBookmarked
                ? 'bookmark'
                : 'bookmark-outline'
              }
              size={isDesktop ? 'md' : 'lg'}
              style={isDesktop ? null : S.searchBookmarkButton}
            />
          </Iffy>
          <Iffy is={showContextMenu}>
            <DNADocumentContextMenu
              documentORM={documentORM}
              variant={variant}
              pageNumber={snippet?.pageNumber}
            >
              <DNAButton
                appearance="ghost"
                status="gray"
                padding={isDesktop ? 'sm' : 'md'}
                rounded="md"
                iconLeft="dots-vertical"
                size={isDesktop ? 'md' : 'lg'}
                style={[isDesktop ? null : S.searchBookmarkButton, { marginRight: 8 }]}
              />
            </DNADocumentContextMenu>
          </Iffy>
          <DNADocumentThumbnail
            documentVersionORM={
              (!isOnline && documentORM.relations.version.cachedDocumentVersionORM) ||
              documentORM.relations.version.latestPublishedDocumentVersionORM ||
              documentORM.relations.version.latestDocumentVersionORM
            }
            width={160}
            height={87}
            unavailableContent={unavailableContent}
            onPress={isDropdown ? undefined : handleThumbnailClick}
            showProcessing
            customThumbnailKey={customThumbnailKey}
          />
        </DNABox>
        <DNABox appearance="col" fill spacing="sm" style={{ marginLeft: 16 }}>
          {snippet?.title
            ? <RenderExcerpt
                textProps={titleProps}
                text={snippet.title.text}
            />
            : <DNAText b1 testID="drop-down-results-title">
              {documentORM.relations.version.latestUsableDocumentVersionORM.model.title}
            </DNAText>}
          {showSnippet &&
            <RenderExcerpt
              pageNumber={snippet.pageNumber}
              textProps={snippetProps}
              text={snippet.documentExcerpt.text}
            />
          }
          <DNABox>
            <DNABox spacing="sm" style={{ marginRight: 8 }}>
              <CustomFieldBadgeList documentVersionORM={documentORM.relations.version.latestUsableDocumentVersionORM} />
              <Iffy is={isPublisherRoute}>
                {documentORM.meta.hasUnpublishedVersion ? (
                  <DNABox spacing="xs" key={documentORM.model.id}>
                    <DNAPopover>
                      <DNAPopover.Anchor>
                        <DNABox
                          style={util.mergeStyles(
                            undefined,
                            [
                              S.unPublishedVersionStatus,
                              (
                                documentORM.meta.hasUnpublishedVersion &&
                                documentORM.model.status === DocumentStatus.PUBLISHED
                              ),
                            ],
                          )}
                        >
                          <DNADocumentChip
                            item={documentORM.relations.version.latestUsableDocumentVersionORM}
                            variant="status"
                          />
                        </DNABox>
                      </DNAPopover.Anchor>
                      <DNAPopover.Content offset={-60}>
                        <DNAText
                          numberOfLines={1}
                          style={{ color: colors['color-text-white'] }}
                        >
                          New version in progress
                        </DNAText>
                      </DNAPopover.Content>
                    </DNAPopover>
                    <Iffy is={documentVersion.meta.schedule.publish.isScheduled}>
                      <ScheduleDocumentIcon mode="ICON" scheduledPublish={scheduledPublish} />
                    </Iffy>
                  </DNABox>
                ) : (
                  <DNABox spacing="xs" key={documentORM.model.id}>
                    <DNADocumentChip
                      item={documentORM.relations.version.latestUsableDocumentVersionORM}
                      variant="status"
                    />
                  </DNABox>
                )}
              </Iffy>
              <Iffy is={unavailableContent}>
                <DNAChip
                  appearance="tag"
                  style={{ backgroundColor: luxColors.basicBlack.primary }}
                  testID="content-not-available"
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </Iffy>
            </DNABox>
            <CustomLabelDisplay documentORM={documentORM} />
          </DNABox>
        </DNABox>
      </View>
    </DNACard>
  )
}

const fixedHeaderOffset = { offSet: 200 }

export const SearchResultRow = React.memo(SearchRow);
// [TODO] - Consider cleaning/breaking this up
export const DNADocumentList: DNADocumentListComponent = (props) => {
  const {
    documents,
    emptyVariant,
    selectEnabled,
    selectedDocuments,
    onSelectDocuments,
    isSearch,
    filtersVisible,
  } = props;
  const { sorts, toggleSort } = useSort()
  const allDocumentsLength = useAllDocumentsLength()
  const userTenant = useUserTenant()
  const { deviceMode } = useAppSettings()
  const isDesktop = deviceMode === 'desktop';
  const { pathname } = useLocation();
  const isBookmarkView = (
    pathname.toLowerCase() === ROUTES.BOOKMARKS.PATH ||
    pathname.toLowerCase().startsWith('/bookmarks')
  );
  const variant = isBookmarkView ? Variant.bookmark : Variant.MSL;
  const fieldConfigs = useMemo(
    () => getFieldConfigsForTenant(userTenant, variant, isDesktop),
    [userTenant, isDesktop, variant],
  );
  const documentActions = useDNADocumentActions()
  const { isOnline } = useAppSettings()
  const fixedHeaderWidth = useFixedHeader(fixedHeaderOffset);
  const searchProxy = useSearchContextProxy()
  const { documentIdSnippetMap } = useDocumentSearchV2Context();
  const dimensions = useWindowDimensions();
  const onSelectDocument = useCallback(
    (doc: DocumentORM) => props.onSelectDocuments?.([doc]),
    [props.onSelectDocuments],
  );
  const onThumbnailPress = useCallback(
    (documentORM: DocumentORM) => documentActions.present(documentORM)(),
    [],
  );

  const initialNumToRender = DEFAULT_PAGE_SIZE;

  const deviceModeProps = isDesktop
    ? { as: DNACard, style: S.DesktopContainer }
    : { fill: true, style: S.MobileContainer }

  const DeviceModeContainer = isDesktop
    ? DNACard
    : DNABox

  const hasFilterDocumentResults = allDocumentsLength && documents.length;

  const cols = useMemo(
    () => fieldConfigs
      .reduce(
        (acc, field) => (
          (field.fieldType === 'ACTION_MENU' || hasFilterDocumentResults)
            ? `${acc} ${field.width}px`
            : acc
        ),
        '',
      ),
    [fieldConfigs, hasFilterDocumentResults],
  )

  const allDocumentSelected = useMemo(
    () => {
      if (!selectedDocuments) return
      return documents.every(doc => selectedDocuments[doc.model.id])
    },
    [selectedDocuments, documents],
  )

  const selectAllDocuments = useCallback(
    () => { onSelectDocuments?.(documents) },
    [onSelectDocuments, documents],
  )

  const keyExtractor = useCallback(
    (documentORM, index) => `${documentORM.model.id}-${index}`,
    [],
  )

  const baseExtendedState = useMemo<ExtendedState>(
    () => ({
      documentActions,
      selectedDocuments,
      isOnline,
      isDesktop,
      onThumbnailPress,
      fieldConfigs,
      selectEnabled,
      onSelectDocument,
    }),
    [
      documentActions,
      selectedDocuments,
      isOnline,
      isDesktop,
      onThumbnailPress,
      fieldConfigs,
      selectEnabled,
      onSelectDocument,
    ],
  )

  const renderItemFactory = useCallback(
    (documentORM: DocumentORM, extendedState: ExtendedState) => {
      const onPress = extendedState.selectEnabled
        ? extendedState.onSelectDocument
        : extendedState.documentActions.preview(documentORM)

      return (
        <>
          <DNADivider />
          <DNADocumentListRow
            isOnline={extendedState.isOnline}
            isDesktop={extendedState.isDesktop}
            documentORM={documentORM}
            variant={Variant.MSL}
            onPress={onPress}
            onThumbnailPress={extendedState.onThumbnailPress}
            fields={extendedState.fieldConfigs}
            rowStyle={extendedState.isDesktop ? undefined : S.MobileRow}
            cellStyle={S.DataCell}
            selectEnabled={extendedState.selectEnabled}
            checked={!!extendedState.selectedDocuments?.[documentORM.model.id]}
            onCheck={extendedState.onSelectDocument}
          />
        </>
      )
    },
    [],
  )

  // [NOTE] - extraData in <FlatList> does inject extended data, only refreshes the render
  const flatListRenderItem = useCallback<NonNullable<FlatListProps<DocumentORM>['renderItem']>>(
    (info) => {
      return renderItemFactory(info.item, baseExtendedState)
    },
    [baseExtendedState],
  )

  const recylerListViewRenderItem = useCallback<RecyclerListViewProps['rowRenderer']>(
    (type, item: DocumentORM, idx, extendedState) => {
      return renderItemFactory(item, extendedState as ExtendedState)
    },
    [renderItemFactory],
  )

  const renderSearchItem = useCallback<NonNullable<FlatListProps<DocumentORM>['renderItem']>>(
    ({ item: documentORM }) => {
      const snippetMap = searchProxy?.documentIdSnippetMap || documentIdSnippetMap;
      const snippet = snippetMap.get(documentORM.model.id);
      const checked = !!(
        baseExtendedState.selectedDocuments &&
        baseExtendedState.selectedDocuments[documentORM.model.id]
      );
      const onAction = baseExtendedState.onSelectDocument as () => void
      const onPress = baseExtendedState.selectEnabled
        ? onAction
        : baseExtendedState.documentActions.preview(documentORM, snippet?.pageNumber);

      return (
        <SearchResultRow
          documentORM={documentORM}
          selectEnabled={selectEnabled}
          checked={checked}
          onCheck={() => baseExtendedState.onSelectDocument(documentORM)}
          onPress={onPress}
        />
      )
    },
    [
      documentIdSnippetMap,
      searchProxy,
      baseExtendedState,
    ],
  )

  const dataProvider = useMemo(
    // [NOTE] - Save some resources by only initializing in Tablet mode
    // [NOTE] - There is an alternative optimization path by using refs during item selection
    //          and determining whether or not to selectively render rows in DataProviders should render function
    //          by using the ref to force update, which should be selective
    () => !isDesktop
      ? new DataProvider((prev: DocumentORM, next: DocumentORM) => prev !== next)
        .cloneWithRows(documents)
      : undefined,
    [isDesktop, documents],
  );

  const layoutProvider = useMemo(
    () => new LayoutProvider(
      () => 'DEFAULT',
      (itemType, dim) => {
        dim.width = 2000;
        dim.height = 90;
      },
    ),
    [],
  )

  if (isSearch) {
    return (
      <DNABox fill>
        <DNACard appearance="outline">
          <Iffy is={selectEnabled}>
            <DNABox appearance="col">
              <DNACheckbox
                context="altBg"
                status="primary"
                style={{ paddingLeft: 24, paddingVertical: 16 }}
                checked={allDocumentSelected}
                onChange={onSelectDocuments ? () => onSelectDocuments(documents) : undefined}
              >
                {
                  () => (<DNAText bold style={{ marginLeft: 16 }}>Select All</DNAText>)
                }
              </DNACheckbox>
              <DNADivider />
            </DNABox>
          </Iffy>
          <DNABox style={{ width: fixedHeaderWidth - (filtersVisible ? 400 : 0) }}>
            <FlatList
              data={documents}
              keyExtractor={keyExtractor}
              initialNumToRender={initialNumToRender}
              extraData={baseExtendedState}
              renderItem={renderSearchItem}
              ItemSeparatorComponent={DNADivider}
              ListEmptyComponent={<DNAEmpty emptyVariant={emptyVariant || EmptyVariant.DocumentListEmptySearch} />}
            />
          </DNABox>
        </DNACard>
      </DNABox>
    )
  }

  return (
    <DNABox fill>
      {/* [TODO] - We should standardize around a prop called `emptyWithHeader`  */}
      <Iffy is={!allDocumentsLength}>
        <DNAEmpty emptyVariant={emptyVariant || EmptyVariant.DocumentListEmpty} />
      </Iffy>

      <Iffy is={allDocumentsLength}>
        <DeviceModeContainer {...deviceModeProps}>
          <GridList
            cols={cols}
            gap="12px"
          >
            {/* GridList header (Non-Empty) */}
            <Iffy is={hasFilterDocumentResults}>
              <GridList.Header style={util.mergeStyles(undefined, [S.MobileHeader, !isDesktop])}>
                {
                  fieldConfigs.map(field => {
                    if (field.fieldType === 'ACTION_MENU' && selectEnabled) {
                      return (
                        <GridList.Col key={field.fieldLabel}>
                          <DNABox spacing="sm" alignY="center">
                            <DNACheckbox
                              context="altBg"
                              status="primary"
                              style={S.selectAllCheckbox}
                              checked={allDocumentSelected}
                              onChange={selectAllDocuments}
                            />
                            <DNAText>Select all</DNAText>
                          </DNABox>
                        </GridList.Col>
                      )
                    } else {
                      return (
                        <FieldHeader
                          key={field.fieldName}
                          field={field}
                          style={S.HeaderCol}
                          toggleSort={toggleSort}
                          sortIcon={sorts?.[field.fieldName]?.icon}
                        />
                      )
                    }
                  },
                  )
                }
              </GridList.Header>
            </Iffy>

            {/* Empty Results */}
            <Iffy is={allDocumentsLength && !documents.length}>
              <DNABox>
                <DNAEmpty
                  useContainer={false}
                  emptyVariant={emptyVariant || EmptyVariant.DocumentListEmptySearch}
                />
              </DNABox>
            </Iffy>

            {/* Document Rows */}
            <Iffy is={isDesktop}>
              <FlatList
                data={documents}
                keyExtractor={keyExtractor}
                initialNumToRender={initialNumToRender}
                renderItem={flatListRenderItem}
              />
            </Iffy>
            <Iffy is={!isDesktop}>
              <RecyclerListView
                style={S.flex}
                layoutProvider={layoutProvider}
                dataProvider={dataProvider!}
                rowRenderer={recylerListViewRenderItem}
                renderAheadOffset={dimensions.height}
                extendedState={baseExtendedState}
                forceNonDeterministicRendering
              />
            </Iffy>
          </GridList>
        </DeviceModeContainer>
      </Iffy>
    </DNABox>
  )
}

const DNADocumentListPaginated: React.FC<Omit<DNADocumentListProps, 'documents'>> = (props) => {
  const { pagedRecords } = usePagination()

  return (
    <DNADocumentList
      {...props}
      documents={pagedRecords}
    />
  )
}

DNADocumentList.Paginated = DNADocumentListPaginated
export default DNADocumentList
