import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Platform } from '@alucio/core'
import { useInterpret } from '@xstate/react';
import loggingMachine from 'src/state/machines/logger/logger.machine';
import useLazyRef from 'src/hooks/useLazyRef';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as loggerSelector from 'src/state/machines/logger/logger.selector';
import { DeviceMode } from 'src/state/context/AppSettings';

export interface LoggerContextType {
  sendLogsToCloudWatch: () => void
  canSendLogs: boolean
  sendLogsButtonState: loggerSelector.SendLogsButtonStates
}

export interface ProviderProps {
  children: ReactNode
  deviceMode: DeviceMode
  currentPlatform: Platform
  isLoggingOut: boolean
}

export const LoggerContext = createContext<LoggerContextType>(null!);
LoggerContext.displayName = 'LoggerContext';

const LoggerStateProvider: React.FC<PropsWithChildren<ProviderProps>> = ({
  children,
  deviceMode,
  currentPlatform,
  isLoggingOut,
}) => {
  /* LOGGING STATE MACHINE START */
  const loggingMachineInstance = useLazyRef(() => loggingMachine.withContext({
    deviceMode,
    platform: currentPlatform,
    logs: [],
  }))

  const loggingService = useInterpret(
    loggingMachineInstance.current!,
    // { devTools: true },
  )

  const cond = useMachineSelector(
    loggingService,
    (state) => composite(
      state,
      loggerSelector.canSendLogs,
      loggerSelector.sendLogsButtonState,
    ),
  )

  const sendLogsToCloudWatch = useCallback(() => {
    loggingService.send({ type: 'SEND_LOGS' })
  }, [loggingService])
  /* LOGGING STATE MACHINE END */

  const context: LoggerContextType = {
    sendLogsToCloudWatch,
    canSendLogs: cond.canSendLogs,
    sendLogsButtonState: cond.sendLogsButtonState,
  };

  useEffect(() => {
    if (isLoggingOut) {
      loggingService.send({ type: 'USER_SIGNING_OUT' });
    }
  }, [isLoggingOut]);

  return (
    <LoggerContext.Provider value={context}>
      {children}
    </LoggerContext.Provider>
  );
};

LoggerStateProvider.displayName = 'LoggerStateProvider';

export const useLoggerState = () => {
  const context = useContext(LoggerContext);
  if (!context) {
    throw new Error('useLoggerState must be used within the LoggerStateProvider');
  }
  return context;
};

export default LoggerStateProvider;
