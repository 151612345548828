import { Observable } from 'rxjs';
import { InvokeCallback } from 'xstate';
import { Hub } from '@aws-amplify/core';
import { UserSettings } from '@alucio/aws-beacon-amplify/src/models';
import { observeSlice } from 'src/state/redux';
import { SliceState } from 'src/state/redux/slice/common';
import {
  EVT_ANALYTICS_ACTION_TRIGGERED,
  EVT_UPDATE_CONTEXT_USER_SETTINGS,
  EVT_USER_SIGNED_IN,
  EVT_USER_SIGNED_OUT,
} from './analytics/analytics.types';
import * as logger from 'src/utils/logger';

export type AnalyticEvent = [string, { [fieldName: string]: any } | undefined];

export const authActor: InvokeCallback<
  any,
  EVT_USER_SIGNED_IN | EVT_USER_SIGNED_OUT
> = (send) => {
  const unregister = Hub.listen('auth', (capsule) => {
    switch (capsule.payload.event) {
      case 'signIn': {
        send('USER_SIGNED_IN')
        break;
      }
      case 'signedOut': {
        send('USER_SIGNED_OUT')
        break;
      }
    }
  })

  return unregister
}

export const userSettingsObservable = () => {
  return observeSlice<SliceState<UserSettings[]>>(
    state => state.userSettings.records,
    undefined,
  ).map<EVT_UPDATE_CONTEXT_USER_SETTINGS>(userSettings => {
    return {
      type: 'UPDATE_CONTEXT_USER_SETTINGS',
      payload: userSettings[0],
    }
  })
}

export const analyticsObservable = (): Observable<EVT_ANALYTICS_ACTION_TRIGGERED> => {
  return new Observable((observer) => {
    const originalTrack = window.analytics?.track;

    if (typeof originalTrack !== 'function') {
      logger.analytics.machine.warn('Analytics track function not found.');
      return () => {}; // Cleanup function does nothing
    }

    analyticsEventDispatcher.setListener((event) => {
      observer.next({
        type: 'ANALYTICS_ACTION_TRIGGERED',
        payload: event,
      });
    });

    return () => {
      analyticsEventDispatcher.clearListener();
    };
  });
};

class AnalyticsEventDispatcher {
  private listener: ((event: AnalyticEvent) => void) | null = null;

  public dispatchAnalyticEvent(event: AnalyticEvent): void {
    this.listener?.(event);
  }

  public setListener(newListener: (event: AnalyticEvent) => void): void {
    this.listener = newListener;
  }

  public clearListener(): void {
    this.listener = null;
  }
}

export const analyticsEventDispatcher = new AnalyticsEventDispatcher();

export default {
  authActor,
  userSettingsObservable,
  analyticsObservable,
  analyticsEventDispatcher,
}
