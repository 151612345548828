import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import beaconConfig, { getDefaultValues, getStatusField } from 'src/hooks/useMeetingFormConfig/AddMeetingConfig';
import {
  AddMeetingProvider,
  CRM_SUBMIT_STATE,
  useAddMeeting,
  useIsMeetingLocked,
} from './AddMeetingProvider';
import { DNABox, DNACard, DNAText, Iffy, InformationMessage, Stack } from '@alucio/lux-ui';
import { ComposableForm, ComposableVariant, useComposableForm } from '../CustomFields/ComposableForm';
import AddMeetingFooter from './AddMeetingFooter';
import { ScrollView, StyleSheet } from 'react-native';
import { ComposableDateTimePicker, ComposableField } from '../CustomFields/ComposableField';
import AttendeeInput from '../CustomFields/AttendeeInput';
import { MeetingORM } from 'src/types/orms';
import ContentPresentedList from './ContentPresentedList';
import { ContentPresentedStatus, CustomFieldDefinition, MeetingStatus } from '@alucio/aws-beacon-amplify/src/models';
import { useAppSettings } from 'src/state/context/AppSettings';
import AddMeetingHeader from './AddMeetingHeader';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import ContentSearchBar, { ContentSearchResult } from '../ContentSearchBar/ContentSearchBar';
import { isCustomDeckORM } from 'src/types/typeguards';
import { useFolderItemORMMap } from 'src/state/redux/selector/folder';
import useMeetingFormConfig, { MeetingFormConfig } from 'src/hooks/useMeetingFormConfig/useMeetingFormConfig';
import { LoadingMeeting } from './LoadingMeeting';
import MeetingCRMAuth, { useMeetingCRMCustomFieldVisibility } from './MeetingCRMAuth';
import { ColumnFieldsWrapper } from '../CustomFields/ColumnCustomFieldsWrapper';
import { DNAButton } from '@alucio/lux-ui/src';
import { useLocation } from 'react-router';
import CRMPrimaryAttendeeComponents from '../CRM/CRMPrimaryAttendeeComponents';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { CRMAvailabilityStatus } from 'src/state/machines/CRM/crmMachineTypes';
import CustomFormsWrapper, { useCustomFormsVisibility } from './CustomForms/CustomFormsWrapper';
import MeetingCustomFormRecord from './CustomForms/MeetingCustomFormRecord';

interface AddMeetingProps {
  meetingId?: string,
  toggleDrawer: () => void,
}

const styles = StyleSheet.create({
  meetingEventsContainer: {
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  cardTitle: {
    marginBottom: 26,
  },
  contentCard: {
    borderRadius: 6,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  contentContainer: {
    backgroundColor: colors['color-gray-50'],
  },
  informationMessageContainer: {
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  informationMessage: {
    marginBottom: 0,
  },
  rowsWrapper: {
    borderRadius: 3,
    borderWidth: 1,
    borderColor: colors['color-gray-80'],
    padding: 8,
  },
  stackLayer: {
    flex: 1,
  },
});

interface ContentPresentedFieldProps {
  isEditForm: boolean
  meetingId?: string
  onDeleteContent: (contentPresentedId: string) => void,
}

const ContentPresentedField = (props: ContentPresentedFieldProps) => {
  const { onDeleteContent, isEditForm, meetingId } = props;
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);
  const folderItemORMMap = useFolderItemORMMap();
  const { contentPresented, addContentPresented, isReadOnly } = useAddMeeting()
  const selectedIds = contentPresented?.reduce((acc: string[], current) => {
    if (current.status !== 'DELETED') {
      acc.push(current.contentId)
    }
    return acc;
  }, [])

  const handleSelectItem = (item: ContentSearchResult) => {
    let folderItemId;

    if (isCustomDeckORM(item.orm)) {
      folderItemId = folderItemORMMap.get(item.id)?.model.id;
    }

    addContentPresented({
      contentId: item.id,
      contentType: item.orm.type,
      status: ContentPresentedStatus.ACTIVE,
      title: item.orm.model.title ?? '',
      folderItemId,
      presentedMeta: [],
    });
  }

  const handleShowSearchBar = () => {
    analytics?.track('MEETING_ADD_CONTENT', {
      action: 'ADD_CONTENT',
      category: 'MEETING',
    })
    setIsSearchBarVisible(true);
  }

  return (
    <DNABox appearance="col" spacing="sm">
      <DNABox>
        <Iffy is={selectedIds?.length === 0}>
          <DNAText status="flat">
            No content was presented
          </DNAText>
        </Iffy>
      </DNABox>
      <Iffy is={!isReadOnly}>
        <DNABox appearance="col" spacing="sm">
          <DNAButton
            testID="meeting-add-file-button"
            size="sm"
            appearance="outline"
            status="tertiary"
            onPress={handleShowSearchBar}
          >Add file</DNAButton>
          <ContentSearchBar
            onSelectItem={handleSelectItem}
            selectedIds={selectedIds}
            variant="MEETING_HISTORY"
            isVisible={isSearchBarVisible}
            setIsVisible={setIsSearchBarVisible}
          />
        </DNABox>
      </Iffy>

      <Iffy is={selectedIds?.length}>
        <DNABox appearance="col" style={isReadOnly ? styles.rowsWrapper : undefined} fill>
          <ContentPresentedList
            onDelete={onDeleteContent}
            isEditForm={isEditForm}
            meetingId={meetingId}
            contentPresented={contentPresented || []}
            showDivider={isReadOnly}
          />
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

interface CustomFormProps {
  customFields: CustomFieldDefinition[],
  displaySuccessfulSave?: boolean,
  meetingORM?: MeetingORM,
}

const CustomForm: React.FC<CustomFormProps> = (props) => {
  const { meetingORM, displaySuccessfulSave } = props;
  const { customFields } = useComposableForm();
  const {
    setCustomFormsSettings,
    crmSubmitState,
    onDeleteContent,
    onSave,
    isValid,
    errorMessage,
    isReadOnly,
    meetingStatus,
  } = useAddMeeting()
  const { availabilityStatus, displayCRMConnectButton } = useCRMStatus();
  const isPlannedCRMMeetingEnabled = availabilityStatus === CRMAvailabilityStatus.ENABLED
  const defaultValues = useMemo(() => getDefaultValues(meetingORM, false, isPlannedCRMMeetingEnabled),
    [meetingORM, isPlannedCRMMeetingEnabled]);
  const { deviceMode } = useAppSettings()
  const staticFields = { ...beaconConfig, customValues: defaultValues }
  const errorSubmitCRM = useMemo(() => crmSubmitState === CRM_SUBMIT_STATE.ERROR, [crmSubmitState]);
  const isMeetingLocked = useIsMeetingLocked(meetingORM)
  const { pathname } = useLocation()
  const isHubsRoute = pathname.includes('hubs')
  const customFieldsVisible = useMeetingCRMCustomFieldVisibility()
  const customFormsVisibility = useCustomFormsVisibility(availabilityStatus, meetingORM)

  const renderPrimaryAttendeeInput = useCallback((props: any) => {
    return (
      <AttendeeInput
        variant={ComposableVariant.DEFAULT}
        isReadOnly={isReadOnly}
        meetingORM={meetingORM}
        {...props}
      />
    );
  }, [isReadOnly])

  const renderAdditionalAttendeeInput = useCallback((props: any) => {
    return (
      <AttendeeInput
        variant={ComposableVariant.DEFAULT}
        isReadOnly={isReadOnly}
        customFields={customFields}
        {...props}
      />
    )
  }, [isReadOnly])

  return (
    <DNABox appearance="col" fill>
      <AddMeetingHeader />
      <DNABox fill appearance="col" style={styles.contentContainer}>
        {/* SUCCESSFUL SAVE */}
        <Iffy is={displaySuccessfulSave}>
          <DNABox appearance="col" spacing="lg" style={styles.informationMessageContainer}>
            <InformationMessage
              text={isMeetingLocked
                ? 'This meeting has been submitted in CRM' : 'Draft successfully sent to CRM' }
              variance="success"
            />
          </DNABox>
        </Iffy>
        {/* FORM ERRORS */}
        <Iffy is={(!isValid && errorMessage) || errorSubmitCRM}>
          <DNABox appearance="col" spacing="lg" style={styles.informationMessageContainer}>
            <InformationMessage
              text={errorMessage || 'An error occurred while submitting to CRM'}
              variance="danger"
              customWrapperStyle={styles.informationMessage}
            />
          </DNABox>
        </Iffy>
        <ScrollView contentContainerStyle={{ paddingBottom: 64 }}>
          <DNABox appearance="col" spacing="lg" fill style={styles.meetingEventsContainer}>

            {/* CRM AUTH */}
            <Iffy is={displayCRMConnectButton}>
              <MeetingCRMAuth isValid={isValid} onSaveForm={onSave} />
            </Iffy>

            {/* MEETING STATUS */}
            <Iffy is={isPlannedCRMMeetingEnabled}>
              <ContentCard cardTitle="Meeting Status">
                <ComposableField
                  field={getStatusField(meetingStatus)}
                  disabled={meetingStatus !== MeetingStatus.PLANNED}
                />
              </ContentCard>
            </Iffy>

            {/* MEETING INFORMATION */}
            <ContentCard cardTitle="Meeting Information">
              <DNABox appearance="col" spacing="md">
                {/* MEETING TITLE */}
                <ComposableField variant={ComposableVariant.DEFAULT} field={staticFields.fields[0]} />
                {/* MEETING TIMES */}
                <DNABox appearance="col" spacing="xs">
                  <DNABox appearance="row" fill>
                    <DNABox appearance="col" style={{ marginRight: 10 }} fill>
                      <ComposableField
                        hideError
                        variant={ComposableVariant.DEFAULT}
                        field={staticFields.fields[1]}
                        CustomFormComponent={ComposableDateTimePicker}
                      />
                    </DNABox>
                    <DNABox appearance="col" style={{ marginRight: 10 }} fill>
                      <ComposableField
                        variant={ComposableVariant.DEFAULT}
                        field={staticFields.fields[2]}
                        CustomFormComponent={ComposableDateTimePicker}
                      />
                    </DNABox>
                  </DNABox>
                </DNABox>
              </DNABox>
            </ContentCard>

            {/* PRIMARY ATTENDEE */}
            <ContentCard cardTitle="Primary Attendee">
              <ComposableField
                hideRequiredLabel
                variant={ComposableVariant.DEFAULT}
                field={staticFields.fields[3]}
                CustomFormComponent={renderPrimaryAttendeeInput}
              />
              {/* WILL RENDER SPECIFIC CRM COMPONENTS */}
              <CRMPrimaryAttendeeComponents />
            </ContentCard>

            {/* ADDITIONAL ATTENDEES */}
            <ContentCard cardTitle="Additional Attendees">
              <ComposableField
                variant={ComposableVariant.DEFAULT}
                field={staticFields.fields[4]}
                CustomFormComponent={renderAdditionalAttendeeInput}
              />
            </ContentCard>

            {/* FILES */}
            {
              meetingStatus !== MeetingStatus.PLANNED &&
                <ContentCard cardTitle="Files">
                  <ContentPresentedField
                    onDeleteContent={onDeleteContent}
                    isEditForm={!isReadOnly}
                    meetingId={meetingORM?.model.id}
                  />
                </ContentCard>
            }

            {/* CUSTOM FIELDS */}
            {
              !!customFields.length && customFieldsVisible &&
                <ContentCard cardTitle="Additional Meeting Information">
                  <DNABox>
                    <ColumnFieldsWrapper customFields={customFields} />
                  </DNABox>
                </ContentCard>
            }

            {/* CUSTOM FORMS LIST (FOR NOW, ONLY TIED TO CRM) */}
            {
              customFormsVisibility &&
                <CustomFormsWrapper
                  isReadOnly={!!isReadOnly}
                  parentId={meetingORM!.model.id}
                  setCustomFormSettings={setCustomFormsSettings}
                />
            }

          </DNABox>
        </ScrollView>
        {(deviceMode === 'desktop' && !isHubsRoute) && <AddMeetingFooter />}
      </DNABox>
    </DNABox>
  )
}

interface ContentCardProps {
  cardTitle: string
  variant?: ComposableVariant
}

export const ContentCard: React.FC<PropsWithChildren<ContentCardProps>> = (props) => {
  const { cardTitle, children, variant = ComposableVariant.DEFAULT } = props;
  const isVirtual = variant === ComposableVariant.VIRTUAL;

  return (
    <DNACard
      appearance="flat"
      style={[styles.contentCard, {
        backgroundColor: isVirtual ? colors['color-gray-700'] : colors['color-text-basic'],
      }]}
    >
      <DNAText
        bold
        status={isVirtual ? 'basic' : 'dark'}
        style={styles.cardTitle}
      >
        {cardTitle}
      </DNAText>
      {children}
    </DNACard>
  );
};

const AddMeeting: React.FC<AddMeetingProps> = (props) => {
  const { meetingId } = props
  const formConfig = useMeetingFormConfig(meetingId);

  if (formConfig.isLoading) {
    return <LoadingMeeting />;
  }

  return (
    <ComposableForm {...formConfig} referenceObject={formConfig.meetingORM}>
      <AddMeetingProvider {...props}>
        <ContentWrapper meetingId={meetingId} formConfig={formConfig} />
      </AddMeetingProvider>
    </ComposableForm>
  )
}

interface WrapperProps {
  meetingId?: string;
  formConfig: MeetingFormConfig;
}

const ContentWrapper: React.FC<WrapperProps> = (props) => {
  const { meetingId, formConfig } = props;
  const { crmSubmitState, customFormsSettings } = useAddMeeting();
  const [displaySuccessfulSave, isSubmittingToCRM] = useMemo(() => [
    crmSubmitState === CRM_SUBMIT_STATE.SUCCESS,
    crmSubmitState === CRM_SUBMIT_STATE.SUBMITTING,
  ], [crmSubmitState]);
  const loadingMessage =
    !meetingId ? 'Creating meeting...'
      : isSubmittingToCRM ? 'Saving to CRM...' : undefined;

  return (
    <>
      <Iffy is={meetingId && !isSubmittingToCRM}>
        <Stack>
          <Stack.Layer style={styles.stackLayer}>
            {/* REGULAR MEETING FORM */}
            <CustomForm
              displaySuccessfulSave={displaySuccessfulSave}
              meetingORM={formConfig.meetingORM}
              customFields={formConfig.customFields}
            />
          </Stack.Layer>
          {/* CUSTOM FORM */}
          { customFormsSettings &&
            <Stack.Layer fill style={styles.stackLayer}>
              <MeetingCustomFormRecord />
            </Stack.Layer>
          }
        </Stack>
      </Iffy>
      <Iffy is={!meetingId || isSubmittingToCRM}>
        <LoadingMeeting loadingText={loadingMessage} />
      </Iffy>
    </>
  );
};

export default AddMeeting
