import React, { useEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import { DNABox, DNAButton, DNAText, DNADivider, Iffy, useToast, DNAChip, DNAContextMenu } from '@alucio/lux-ui/src';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { ContentShare } from '@alucio/aws-beacon-amplify/src/models';
import { contentShareByContentID } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { ContentShareORM, HubORM } from 'src/types/orms';
import { useDispatch, store } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { contentShareActions } from 'src/state/redux/slice/contentShare';
import { useContentShareListByContentIdSortedByCreatedAt } from 'src/state/redux/selector/contentShare';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { ShareVariantOptions } from 'src/components/DNA/Modal/ContentShareModal/ContentShareModal';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { useHubRHFromState } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';
import { TabConfig} from 'src/state/context/Hubs/HubsStateProvider';
import { ContentShareModal } from 'src/components/DNA/Modal/ContentShareModal/ContentShareModal';
import * as logger from 'src/utils/logger';

type SharedWithProps = {
  hubORM: HubORM
  tabConfig: TabConfig,
}

const styles = StyleSheet.create({
  sharedWithMeContent: {
    paddingHorizontal: 12,
    backgroundColor: colors['color-text-white'],
  },
  sideBarIcon: {
    height: 35,
    width: 30,
    marginTop: 10,
  },
  sideBarHeaderDesktop: {
    paddingTop: 28,
    paddingBottom: 18,
  },
  sideBarHeaderTablet: {
    paddingTop: 12,
    paddingBottom: 18,
  },
  addRecipientBtn: {
    width: 272,
    height: 40,
    marginBottom: 20,
  },
  shareListContainer: {
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
    borderRadius: 6,
    padding: 12,
    marginBottom: 16,
    width: 272,
  },
  emptyContainer: {
    width: 272,
    paddingHorizontal: 12,
  },
  emptyContainerSubText: {
    textAlign: 'center',
    lineHeight: 24,
  },
  fullWidth: {
    width: '100%',
  },
});

const SharedWith: React.FC<SharedWithProps> = (props) => {
  const { hubORM, tabConfig } = props
  const { deviceMode, isOnline } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  
  // returns a list of contentShare for the current hub by hub id
  const contentShareORMs = useContentShareListByContentIdSortedByCreatedAt(hubORM.model.id)
  const dispatch = useDispatch()
  const { rhForm, setIsSaving } = useHubRHFromState()
  const {
    openFormInvalidWarningModal,
    setErrorMessages,
    handleSaveNCallback,
    toggleRightSidebar,
  } = useHubsState()
  const { handleSubmit } = rhForm
  
  const getExistingContentShareORM = (contentShareId: string) => {
    return contentShareORMs.find(contentShareORM => contentShareORM.model.id === contentShareId)
  }

  const handleOpenShareModalToCopy = (existingContentShareORM: ContentShareORM, hubTitle: string) => {
    if (hubORM) {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <ContentShareModal
            entityShareIds={[hubORM.model.id]}
            {...modalProps}
            variant={ShareVariantOptions.HUB_SHARE}
            existingContentShareORM={existingContentShareORM}
            hubTitle={hubTitle}
          />
        ),
      }))
    }
  }
  
  useEffect(() => {
    const getData = async () => {
      const { data } = await API.graphql(
        graphqlOperation(contentShareByContentID, {
          contentId: hubORM.model.id
        })
      ) as GraphQLResult<{
        contentShareByContentID: {
          items: ContentShare[]
        }
      }>
      if (data?.contentShareByContentID?.items) {
        store.dispatch(contentShareActions.upsert(data.contentShareByContentID.items))
      }
    }
    // TODO better error handling
    getData().catch(err => console.log('error getting data: ', err))
  }, [])

  const handleAddRecipient = () => {
    logger.hub.sharing.debug(`Opening share modal for hub: ${hubORM.model.id}`)
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      backdropVisible: true,
      component: (modalProps) => (
        <ContentShareModal
          entityShareIds={[hubORM.model.id]}
          {...modalProps}
          variant={ShareVariantOptions.HUB_SHARE}
        />
      ),
    }))
  }

  const saveNCallbackWrapper = (data: any, callback: () => void) => {
    setIsSaving(true)
    try {
      handleSaveNCallback(data, callback)
      setTimeout(() => {
        setIsSaving(false)  
      }, 1000)
    } catch (e) {
      setErrorMessages(pre => [...pre, 'An error has occured, please try again. If this issue persists, please contact support'])
      console.error('Hub saving faild', e)
    }
  }

  const getExpirationOrStatusText = (expirationDate: string, revoked?: boolean) => {
    const hasExpired = isAfter(Date.now(), Date.parse(expirationDate))
    if (revoked) return <DNAChip status="danger">REVOKED</DNAChip>
    else if (hasExpired) {
      return (
        <DNAChip status="danger" appearance="subtle">
          {`EXPIRED ON ${format(Date.parse(expirationDate), 'L/d/yy')}`}
        </DNAChip>
      )
    } else {
      return (
        <DNAText status="primary">
          {`Expires on ${format(Date.parse(expirationDate), 'L/d/yy')}`}
        </DNAText>
      )
    }
  }

  const handleRevoke = async (contentShare: ContentShare) => {
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          cancelText="Cancel"
          confirmActionText="Revoke"
          onConfirmAction={() => dispatch(contentShareActions.revokeContentShare({ contentShare }))}
          title="Revoke access to hub?"
          descriptionText={`This will revoke access to this hub for this generated link. \n\n This action cannot be undone.`}
        />),
    }));
  }

  return (
    <DNABox as={ScrollView} style={styles.sharedWithMeContent} fill childFill={2} appearance="col">
      <DNABox
        testID="side-bar-header-text"
        appearance="col"
        spacing="md"
        style={mergeStyles(
          undefined,
          [styles.sideBarHeaderDesktop, !isTablet],
          [styles.sideBarHeaderTablet, isTablet],
        )}
      >
        <DNABox fill appearance="row" spacing="between" alignY="center">
          <DNAText testID="side-bar-text" h4>
            {`${tabConfig.label}` + (isOnline ? ` (${contentShareORMs.length})` : '')}
          </DNAText>
          <Iffy is={isTablet}>
            <DNAButton
              status="gray"
              appearance="ghost"
              iconLeft="close"
              onPress={toggleRightSidebar}
              size="lg"
              padding="sm"
            />
          </Iffy>
        </DNABox>
        <DNADivider />
      </DNABox>
      <DNAButton
        iconLeft="plus"
        onPress={handleSubmit((data) => saveNCallbackWrapper(data, handleAddRecipient), openFormInvalidWarningModal)}
        appearance="outline"
        status="tertiary"
        style={mergeStyles(
          undefined,
          styles.addRecipientBtn,
          [styles.fullWidth, isTablet],
        )}
        disabled={!isOnline}
        testID="edit-hub-add-recipient-btn"
        >
        Add recipient
      </DNAButton>
      <DNABox fill>
        <Iffy is={!isOnline}>
          <DNABox
            appearance="col"
            alignX="center"
            alignY="center"
            spacing="md"
            fill
            style={mergeStyles(
              undefined,
              styles.emptyContainer,
              [styles.fullWidth, isTablet],
            )}
          >
            <DNAText status="subtle" numberOfLines={3} style={styles.emptyContainerSubText}>
              Please connect to the internet to see content
            </DNAText>
          </DNABox>
        </Iffy>
        <Iffy is={!contentShareORMs?.length && isOnline}>
          <DNABox
            appearance="col"
            alignX="center"
            alignY="center"
            spacing="md"
            fill
            style={mergeStyles(
              undefined,
              styles.emptyContainer,
              [styles.fullWidth, isTablet],
            )}
          >
            <DNAText status="subtle" bold>
              Share hub by adding recipients
            </DNAText>
            <DNAText status="subtle" numberOfLines={3} style={styles.emptyContainerSubText}>
              Each recipient will have a unique share link that will allow you to track their activity.
            </DNAText>
          </DNABox>
          </Iffy>
        <Iffy is={contentShareORMs?.length && isOnline}>
          <DNABox fill appearance="col">
          {contentShareORMs?.map(contentShareORM => {
            const handleReshare = () => {
              const existingContentShareORM = getExistingContentShareORM(contentShareORM.model.id)
              if (existingContentShareORM) handleOpenShareModalToCopy(existingContentShareORM, hubORM.model.name || '')
            }
            // We assume all recipient field are config correctly and should be the first field
            const recipientField = contentShareORM.model.customValues[0]
            return (
              <DNABox
                key={contentShareORM.model.id}
                appearance="col"
                style={mergeStyles(
                  undefined,
                  styles.shareListContainer,
                  [styles.fullWidth, isTablet],
                )}
                spacing="sm"
                keepSpacingLastItem
              >
                <DNABox>
                  <DNAText status="dark" b1 bold numberOfLines={1}>
                    {recipientField?.values}
                  </DNAText>
                </DNABox>
                <DNABox fill appearance="row" spacing="between">
                  <DNABox appearance="col" spacing="sm">
                    <DNAText status="flat">Link generated on {format(Date.parse(contentShareORM.model.createdAt), 'L/d/yy')}</DNAText>
                    <DNABox childFill>
                      {getExpirationOrStatusText(contentShareORM.model.expiresAt, contentShareORM.model.revoked)}
                    </DNABox>
                  </DNABox>
                  <Iffy is={!contentShareORM.model.revoked && !isAfter(Date.now(), Date.parse(contentShareORM.model.expiresAt))}>
                    <DNABox alignY="center">
                      <DNAButton
                        iconLeft="share"
                        testID="share-icon"
                        status="gray"
                        appearance="ghostLink"
                        padding="none"
                        size="md"
                        style={styles.sideBarIcon}
                        onPress={handleSubmit((data) => saveNCallbackWrapper(data, handleReshare), openFormInvalidWarningModal)}
                      />
                      <DNAContextMenu>
                        <DNAContextMenu.Anchor>
                          <DNAButton
                            iconRight="dots-vertical"
                            status="gray"
                            appearance="ghostLink"
                            padding="none"
                            size="md"
                            style={styles.sideBarIcon}
                          />
                        </DNAContextMenu.Anchor>
                        <DNAContextMenu.Items>
                          <DNAContextMenu.Item
                            title="Revoke"
                            icon='undo-variant'
                            onPress={() => handleRevoke(contentShareORM.model)}
                          />
                        </DNAContextMenu.Items>
                      </DNAContextMenu>
                    </DNABox>
                  </Iffy>
                </DNABox>
              </DNABox>
            )
          })}
          </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

export default SharedWith
