import React, { useState } from 'react';
import { FolderORM } from 'src/types/types';
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions';
import { DNABox, DNAButton, DNACard, DNAText, Iffy, useTheme, util } from '@alucio/lux-ui';
import Folder from 'src/components/DNA/Folder/DNAFolder';
import PinButton from 'src/components/DNA/Folder/DNAFolderPinButton';
import DNAFolderContextMenu from 'src/components/DNA/Folder/DNAFolderContextMenu';
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types';
import DNADocumentVersionUpdateButton from 'src/components/DNA/Document/DNADocumentVersionUpdateButton';
import { isTablet } from 'react-device-detect';
import { FolderStatus } from '@alucio/aws-beacon-amplify/src/models';

export interface DNAFolderDefaultRowProps {
  onPin?: () => void,
}

export const DNAFolderDefaultRow: DNARowsComponent<FolderORM, DNAFolderDefaultRowProps> = (props) => {
  const {
    ORM: folderORM,
    onPin,
    onPress,
    isDesktop = true,
  } = props;

  const [showPin, setShowPin] = useState<boolean>()
  const folderActions = useDNAFolderActions()
  const theme = useTheme()

  const isPinned = folderORM.model.isPinned
  const hideEllipsis = folderORM.model.status === FolderStatus.ARCHIVED && folderORM.meta.isSharedWithTheUser;
  const allowPin = folderORM.relations.parentFolderORM === null // Only root folders can be pinned

  const allStyle = {
    paddingHorizontal: theme['row-padding-horizontal'],
    paddingVertical: theme['row-padding-vertical'],
  }

  return (
    <DNACard
      appearance="flat"
      /** TODO: Investigate why are we passing in all props here rather than just props.styles? */
      style={util.mergeStyles(props, allStyle)}
      interactive="pressable"
      onPress={() => {
        onPress?.(folderORM);
      }}
      // Ignoring these events in tablet because they will also fire on tap
      onMouseEnter={() => {
        if (isTablet) return;
        setShowPin(true);
      }}
      onMouseLeave={() => {
        if (isTablet) return;
        setShowPin(false);
      }}
    >
      <DNABox
        testID="folder-row"
        childFill={1}
        spacing={isDesktop ? 'between' : 'md'}
        alignY="center"
      >
        <Iffy is={!isDesktop}>
          <DNABox alignY="center" spacing="sm">
            <Iffy is={!folderORM.meta.isSharedWithTheUser}>
              <PinButton
                onPin={() => {
                  folderActions.pin(folderORM)();
                  onPin?.();
                }}
                isHidden={(!showPin && !isPinned) || !allowPin}
              />
            </Iffy>
            <Iffy is={!hideEllipsis}>
              <DNAFolderContextMenu folderORM={folderORM}>
                <DNAButton
                  status="gray"
                  appearance="ghost"
                  padding="md"
                  iconLeft="dots-vertical"
                  size="lg"
                />
              </DNAFolderContextMenu>
            </Iffy>
          </DNABox>
        </Iffy>
        <DNABox spacing="lg" alignY="center" childFill={1} style={{ marginLeft: 7 }}>
          <Folder folder={folderORM} />
          <DNAText
            testID="folder-item-title"
            b1
            style={{ marginLeft: 9 }}
            numberOfLines={2}
          >
            { folderORM.model.name }
          </DNAText>
        </DNABox>
        <Iffy is={isDesktop}>
          <DNABox alignY="center">
            <Iffy is={!folderORM.meta.isSharedWithTheUser}>
              <DNABox alignY="center">
                {/* Update Version Notification */}
                <DNADocumentVersionUpdateButton orm={folderORM} />
                <PinButton
                  onPin={() => {
                    folderActions.pin(folderORM)();
                    onPin?.();
                  }}
                  isHidden={(!showPin && !isPinned) || !allowPin}
                />
              </DNABox>
            </Iffy>
            <Iffy is={!hideEllipsis}>
              <DNAFolderContextMenu folderORM={folderORM}>
                <DNAButton
                  status="gray"
                  size="md"
                  rounded="md"
                  padding="sm"
                  appearance="ghost"
                  iconLeft="dots-vertical"
                />
              </DNAFolderContextMenu>
            </Iffy>
          </DNABox>
        </Iffy>
        <Iffy is={!isDesktop && !folderORM.meta.isSharedWithTheUser}>
          {/* Update Version Notification */}
          <DNADocumentVersionUpdateButton orm={folderORM} />
        </Iffy>
      </DNABox>
    </DNACard>
  );
}

export default DNAFolderDefaultRow
