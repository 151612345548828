import React, { useMemo, useState } from 'react';
import { useDispatch } from 'src/state/redux';

import { DocumentORM, FolderItemORM, FolderORM } from 'src/types/types';
import { DocumentStatus, FolderItemStatus } from '@alucio/aws-beacon-amplify/src/models';

import {
  DNAModal, DNABox, DNAButton, DNAText, GenericToast, ToastOrientations, useDisableSwipe,
  Tabs,
  Iffy,
} from '@alucio/lux-ui';
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal';
import DNAFolderNestedList, {
  DEFAULT_SHARED_FOLDER_FILTER,
  FolderSelectedMap,
} from 'src/components/DNA/Folder/NestedList/DNAFolderNestedList';
import DNAFolderUpsertModal from 'src/components/DNA/Modal/DNAFolderUpsert';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';
import { UpdateType, folderActions } from 'src/state/redux/slice/folder';
import { sharedFolderActions } from 'src/state/redux/slice/sharedFolder';
import * as guards from 'src/types/typeguards';
import DNAConditionalButton from '../ConditionalButton/DNAConditionalButton';
import { isSharedEditableFolder } from 'src/utils/foldersHelpers';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useCurrentUser } from 'src/state/redux/selector/user';
import { useAllFoldersInstance } from 'src/state/redux/selector/folder';

export type DNADocumentAddToFolderItem = {
  itemORM: DocumentORM | FolderItemORM | DocumentORM[]
  toast?: ToastActions
  duplicateUserNotationsCallback?: (newCustomDeckId: string) => void
}

export const TABS_FOLDERS = [
  { title: 'My Folders' },
  { title: 'Shared Folders' },
]

export const getDocumentORMFromItem = (itemORM: DocumentORM | FolderItemORM | undefined) : DocumentORM | undefined => (
  guards.isDocumentORM(itemORM)
    ? itemORM
    : guards.isDocumentVersionORM(itemORM?.relations.itemORM)
      ? itemORM?.relations.itemORM.relations.documentORM
      : undefined
)

const trackDocument = (folder: FolderORM, itemORM: DocumentORM | FolderItemORM, isBulkAdd?: boolean) => {
  if (guards.isFolderItemORM(itemORM) && guards.isCustomDeckORM(itemORM.relations.itemORM)) {
    const customDeck = itemORM.relations.itemORM;
    analytics?.track('FOLDER_ADD_CUSTOM_DECK', {
      action: 'ADD_CUSTOM_DECK',
      category: 'FOLDER',
      folderId: folder.model.id,
      customDecktId: customDeck.model.id,
    });
  }
  else {
    const document = getDocumentORMFromItem(itemORM)
    analytics?.track('FOLDER_ADD_DOCUMENT_VERSION', {
      action: 'ADD_DOCUMENT_VERSION',
      category: 'FOLDER',
      context: isBulkAdd ? 'BULK_ADD' : 'ADD_DOCUMENT',
      folderId: folder.model.id,
      documentId: document!.model.id,
      documentVersionId: document?.relations?.version?.latestPublishedDocumentVersionORM?.model.id,
    });
  }
}

export const DNADocumentAddToFolder: DNAModalVariant<DNADocumentAddToFolderItem> = (props) => {
  const { itemORM, toggleModal, pushModal, closeModal, toast, duplicateUserNotationsCallback } = props
  const bulkItemORM = Array.isArray(itemORM) && itemORM.length > 1 ? itemORM : undefined
  const singleItemORM = Array.isArray(itemORM) ? !bulkItemORM ? itemORM[0] : undefined : itemORM
  const isAnyItemNonPublished: boolean = useMemo(() => {
    if (singleItemORM) {
      if (guards.isFolderItemORM(singleItemORM) && guards.isDocumentVersionORM(singleItemORM.relations.itemORM)) {
        return singleItemORM.relations.itemORM.model.status === DocumentStatus.NOT_PUBLISHED
      } else if (guards.isDocumentORM(singleItemORM)) {
        return singleItemORM.model.status === DocumentStatus.NOT_PUBLISHED
      } else return false
    } else if (bulkItemORM) {
      return bulkItemORM.some(docORM => docORM.model.status === DocumentStatus.NOT_PUBLISHED)
    } else return false
  }, [singleItemORM, bulkItemORM])
  const [selected, setSelected] = useState<FolderSelectedMap>({})
  const enableEditSharedFolder = useFeatureFlags('enableEditSharedFolder');
  const sharedFolders =  useAllFoldersInstance(DEFAULT_SHARED_FOLDER_FILTER, true)
  const isAnyRootFolderShared = sharedFolders.some(folder => !folder.relations.parentFolderORM)
  const showSharedFolderTab = enableEditSharedFolder && isAnyRootFolderShared && !isAnyItemNonPublished
  const tabs = showSharedFolderTab ? TABS_FOLDERS : [TABS_FOLDERS[0]]
  const currentUser = useCurrentUser()

  const [selectedTab, setSelectedTab] = useState(tabs[0].title)

  const dispatch = useDispatch()
  useDisableSwipe()

  const targetFolders = Object
    .values(selected)
    .filter(selection => selection && !selection.locked)
    .map(selection => selection?.folder) as FolderORM[]

  const onAction = () => {
    if (targetFolders.length) {
      targetFolders.forEach(folder => {
        const isSharedEditFolder = isSharedEditableFolder(folder, currentUser.userProfile?.id) &&
          enableEditSharedFolder
        if (bulkItemORM) {
          // Filter out documents that is already in the folder
          const existingDoc = folder.model.items
            .filter(doc => doc.status === FolderItemStatus.ACTIVE)
            .map(doc => doc.itemId)
          const filteredBulkItemORM = bulkItemORM
            .filter(doc => !existingDoc.includes(doc.relations.version.latestUsableDocumentVersionORM.model.id))
          filteredBulkItemORM.forEach(itemORM => trackDocument(folder, itemORM, true))
          dispatch(folderActions.updateItems(
            { folder, folderItems: filteredBulkItemORM, action: UpdateType.ADD_DOCUMENT }))
        }
        else if (singleItemORM) {
          trackDocument(folder, singleItemORM);
          const isDocumentORM = guards.isDocumentORM(singleItemORM)
          if (isDocumentORM) {
            dispatch(
              isSharedEditFolder
                ? sharedFolderActions.updateItems({
                  folder,
                  folderItems: [singleItemORM],
                  action: UpdateType.ADD_DOCUMENT,
                })
                : folderActions.addDocument([singleItemORM], folder),
            )
          }
          else if (guards.isFolderItemORM(singleItemORM)) {
            if (guards.isCustomDeckORM(singleItemORM.relations.itemORM)) {
              dispatch(
                isSharedEditFolder
                  ? sharedFolderActions.createCustomDeckInFolder({
                    targetFolder: folder,
                    selectedSlides: singleItemORM.relations.itemORM.model.groups,
                    title: singleItemORM.model.customTitle || singleItemORM.meta.title,
                    duplicateUserNotationsCallback,
                  })
                  : folderActions.createCustomDeck(
                    folder,
                    singleItemORM.relations.itemORM.model.groups,
                    singleItemORM.model.customTitle || '',
                    duplicateUserNotationsCallback,
                  ),
              )
            } else {
              dispatch(
                isSharedEditFolder
                  ? sharedFolderActions.updateItems({
                    folder,
                    folderItems: [singleItemORM],
                    action: UpdateType.ADD_DOCUMENT,
                  })
                  : folderActions.addFolderItem(singleItemORM, folder),
              );
            }
          }
        }
      })
      toast?.add(
        <GenericToast
          title={ (bulkItemORM ? 'Files' : 'File') + ' added to My Folders.'}
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    }
    toggleModal()
  }

  const onCreateFolder = () => {
    pushModal((props) => (<DNAFolderUpsertModal {...props} toast={toast} />))
  }

  const onSelectTab = (tab: string) => setSelectedTab(tab)

  const showSharedFolders = selectedTab === TABS_FOLDERS[1].title

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNABox spacing="sm" style={{ paddingTop: 12, paddingLeft: 16 }} >
          <DNAText h5>Add to Folders </DNAText>
          <DNAText status="subtle">{targetFolders.length} selected</DNAText>
        </DNABox>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="col" fill spacing="sm">
          <Tabs
            selectedTab={selectedTab}
            selectedTabVariant="primary"
            style={{ paddingRight: 24 }}
            tabs={tabs}
            styleTab={ tabs.length === 1 && { justifyContent: 'flex-start' }}
            onSelectTab={onSelectTab}
            childFill
          />
          <DNAFolderNestedList
            itemORM={itemORM}
            onFolderSelect={setSelected}
            showSharedFolders={showSharedFolders}
          />
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <Iffy is={!showSharedFolders}>
          <DNAButton
            testID="create-new-folder-Button"
            onPress={onCreateFolder}
            appearance="outline"
          >
            Create new folder
          </DNAButton>
        </Iffy>
        <DNAConditionalButton
          onPress={onAction}
          testID="add-to-folder-confirm-button"
        >
          <DNAConditionalButton.Disable condition={!targetFolders.length}>
            <DNAText status="basic">Select destination folder(s)</DNAText>
          </DNAConditionalButton.Disable>
          Done
        </DNAConditionalButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNADocumentAddToFolder
